import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  return {
    walletBox: {
      width: "100px",
      padding: "14px 6px",
      background: "#F1F6FF",
      borderRadius: "12px",
      textAlign: "center",
      "& img": {
        width: "32px",
        height: "32px",
      },
      "& p": {
        paddingTop: "5px",
        fontSize: "14px",
        lineHeight: "16px",
        color: "#000B60",
        fontWeight: 600,
      },
    },
    networkBox: {
      display: "flex",
      gap: "4px",
      height: "26px",
      padding: "0 12px",
      borderRadius: "60px",
      alignItems: "center",
      justifyContent: "center",
      background: "#F1F6FF",
      "& img": {
        width: "14px",
        minWidth: "14px",
        height: "16px",
      },
      "& p": {
        fontSize: "14px",
        lineHeight: "16px",
        color: "#000B60",
      },
    },
    disableNetwork: {
      opacity: "0.5",
    },

    activeNetwork: {
      border: "1.5px solid #A5CCFF",
    },

    walletBoxText: {
      color: "#fff",
      font: "normal normal normal 12px/16px Helvetica!important",
      textAlign: "center",
    },
    "@keyframes spin": {
      "100%": {
        transform: "rotate(360deg)",
      },
      "0%": {
        transform: "rotate(0deg)",
      },
    },
    loadingAnimation: {
      animation: `$spin 1s linear infinite`,
    },
    walletBoxCheck: {
      position: "absolute",
      top: -9,
      right: -30,
      width: "12px !important" as any,
      height: 12,
    },
    [theme.breakpoints.down("xs")]: {
      walletBox: {
        maxWidth: "100%",
        "&:not(:first-child)": {
          marginLeft: 0,
        },
      },
    },
  };
});

export default useStyles;
