import React, { useState, useEffect } from 'react';
import { HashRouter as Router, Route, Switch, withRouter, RouteComponentProps } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useWeb3React } from '@web3-react/core';
import useProviderConnect from './components/Base/HeaderDefaultLayout/hooks/useProviderConnect';
import { settingAppNetwork, NetworkUpdateType } from './store/actions/appNetwork';
import { AppContext } from './AppContext';
import { clearAlert } from './store/actions/alert';
import NotFoundPage from './pages/NotFoundPage';
import ErrorBoundary from './components/Base/ErrorBoundary';
import BuyToken from './pages/BuyToken';
import Dashboard from './pages/Dashboard';
import ConfirmEmail from './pages/ConfirmEmail';
import AppContainer from './AppContainer';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Terms from './pages/Terms';
import AccountV2 from './pages/AccountV2';
import Pools from './pages/Pools';
import StakingPools from './pages/StakingPools';
import Calendar from './pages/Calendar';
import {
    NotificationContainer,
    NotificationManager,
    //@ts-ignore
} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import useWalletSignatureWithRefreshToken from './hooks/useWalletSignatureWithRefreshToken';
import DefaultLayout from './components/Layout/DefaultLayout';
import { DEFAULT_CHAIN_ID } from './constants/network';
import Supply from './pages/Supply';
const Routes: React.FC<RouteComponentProps> = (props: RouteComponentProps) => {
    const dispatch = useDispatch();
    const alert = useSelector((state: any) => state.alert);
    const { history } = props;
    const { appChainID } = useSelector((state: any) => state.appNetwork).data;
    const { connector, account: connectedAccount } = useWeb3React();
    const { refreshing: isRefreshing } = useSelector((state: any) => state.tokensByUser);
    const { signMessage } = useWalletSignatureWithRefreshToken(isRefreshing);
    const [binanceAvailable, setBinanceAvailable] = useState(true);
    const [openConnectWallet, setOpenConnectWallet] = useState<boolean>(false);
    const [openEnterRefCode, setOpenEnterRefCode] = useState<boolean>(false);
    const [currentConnectedWallet, setCurrentConnectedWallet] = useState<any>(undefined);

    useEffect(() => {
        if (!appChainID) {
            dispatch(settingAppNetwork(NetworkUpdateType.App, DEFAULT_CHAIN_ID));
        }
    }, [appChainID]);

    const logout = () => {
        const dataSend = {
            type: 'FROM_REDKITE',
            data: {
                address: connectedAccount,
                login: false,
            },
        };
        window.postMessage(dataSend, '*');
        if (connector?.deactivate) {
            void connector.deactivate();
        } else {
            connector && void connector.resetState();
        }
        dispatch(settingAppNetwork(NetworkUpdateType.Wallet, undefined));
        setCurrentConnectedWallet(undefined);
        handleConnectorDisconnect();
    };

    const { handleProviderChosen, connectWalletLoading, currentConnector, walletName, setWalletName, loginError, appNetworkLoading, handleConnectorDisconnect } = useProviderConnect(setOpenConnectWallet, openConnectWallet, binanceAvailable);

    useEffect(() => {
        if (!isRefreshing) return;
        signMessage();
    }, [isRefreshing, signMessage]);

    useEffect(() => {
        document.onreadystatechange = function () {
            if (document.readyState === 'complete') {
                setBinanceAvailable(true);
            }
        };
    }, []);

    useEffect(() => {
        const { type, message } = alert;
        if (type && message) {
            NotificationManager[type](message);
        }
    }, [alert]);

    useEffect(() => {
        history.listen((location, action) => {
            dispatch(clearAlert());
        });
    }, []);

    return (
        <AppContext.Provider
            value={{
                binanceAvailable,
                handleProviderChosen,
                connectWalletLoading,
                walletName,
                setWalletName,
                loginError,
                appNetworkLoading,
                handleConnectorDisconnect,
                currentConnector,
                logout,
                setCurrentConnectedWallet,
                openConnectWallet,
                setOpenConnectWallet,
                currentConnectedWallet,
                openEnterRefCode,
                setOpenEnterRefCode,
            }}
        >
            <DefaultLayout>
                <div>
                    <Switch>
                        <Route exact path={`${'/dashboard'}`} component={Dashboard} />
                        <Route path={`${'/buy-token/:id'}`} component={BuyToken} />
                        <Route path={'/confirm-email/:token'} component={ConfirmEmail} />
                        {/* <Route path={"/account"} component={AccountV2} /> */}
                        <Route path={'/account/:slug'} component={AccountV2} />
                        <Route path={'/pools'} component={Pools} />
                        <Route path={'/staking-pools'} component={StakingPools} />
                        <Route path={'/terms'} component={Terms} />
                        <Route path={'/supply'} component={Supply} />
                        <Route path={'/privacy'} component={PrivacyPolicy} />
                        <Route path={'/calendar'} component={Calendar} />
                        {/* <Route path={"/referrals"} component={Referrals} /> */}
                        <Route path={'/'} component={Dashboard} />

                        <Route component={NotFoundPage} />
                    </Switch>
                </div>
            </DefaultLayout>
        </AppContext.Provider>
    );
};

const RoutesHistory = withRouter(Routes);

const routing = function createRouting() {
    return (
        <>
            <NotificationContainer />
            <Router>
                <AppContainer>
                    <ErrorBoundary>
                        <RoutesHistory />
                    </ErrorBoundary>
                </AppContainer>
            </Router>
        </>
    );
};
/**
 * Wrap the app routes into router
 *
 * PROPS
 * =============================================================================
 * @returns {React.Node}
 */
export default routing;
