const list = [
  {
    id: 1,
    title: "How to stake?",
    url: "https://docs.rwa.inc/launchpad/get-started-with-rwa-inc.#stake-usdrwa",
  },
  {
    id: 2,
    title: "My Tier FAQs",
    url: "https://docs.rwa.inc/faq/user-faq#user-faqs",
  },
  {
    id: 3,
    title: "What are RWA Tiers?",
    url: "https://docs.rwa.inc/tier-system-and-staking-rewards/introduction",
  },
  {
    id: 4,
    title: "What are RWA Tier Points?",
    url: "https://docs.rwa.inc/launchpad/how-is-usdrwa-token-involved",
  },
];

export const listQA = { list: list };
