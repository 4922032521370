import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  return {
    dialog: {
      "& .MuiPaper-root": {
        position: "relative",
        padding: "33px 40px",
        width: "100%",
        maxWidth: 384,
        backgroundColor: "#FFFFFF",
        borderRadius: 28,
        textAlign: "center",
      },
      "& .button-close": {
        position: "absolute",
        width: 24,
        height: 24,
        top: 12,
        right: 12,
        borderRadius: "50%",
        border: "none",
        cursor: "pointer",
      },
      "& .title": {
        marginBottom: 12,
        color: "#000B60",
        fontWeight: 600,
        fontSize: 32,
        lineHeight: "32px",
        textAlign: "center",
      },
    },
    event: {
      marginTop: 6,
      borderRadius: 8,
      padding: "10px 8px",
      display: "flex",
    },
    logo: {
      background: "black",
      borderRadius: 4,
      width: 26,
      height: 26,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      overflow: "hidden",

      "& img": {
        maxWidth: 26,
        display: "block",
      },
    },
    info: {
      maxWidth: "calc(100% - 34px)",
      marginLeft: 8,
      color: "white",
      textAlign: "left",

      "& p": {
        display: "block",
        margin: 0,
        fontSize: "16px",
        lineHeight: "19px",
        fontWeight: 600,
        color: "#000B60",
      },

      "& p:first-child": {
        fontSize: "14px",
        lineHeight: "16px",
        fontWeight: 400,
        color: "#000B60",
      },
    },
  };
});

export default useStyles;
