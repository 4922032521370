import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
  let nnb1418i = "normal normal 600 14px/18px ";
  return {
    wrapper: {
      padding: "28px 40px 40px",

      [theme.breakpoints.down("sm")]: {
        border: "none",
        padding: "20px 20px",
      },
    },

    headPage: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      marginBottom: 16,
      "& h2": {
        fontSize: "32px",
        lineHeight: "32px",
      },
      "& h1": {
        fontSize: "32px",
        lineHeight: "32px",
      },

      [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
        marginBottom: 20,
      },
    },

    title: {
      fontWeight: 600,
      fontSize: 24,
      lineHeight: "24px",
      color: "#000B60",

      [theme.breakpoints.down("sm")]: {
        fontWeight: 500,
        fontSize: 16,
        lineHeight: "24px",
      },
      [theme.breakpoints.only("xs")]: {
        display: "none",
      },
    },

    bodyPage: {
      display: "flex",
      [theme.breakpoints.down("md")]: {
        display: "grid",
        gap: 16,
      },
      [theme.breakpoints.down("xs")]: {
        gap: 0,
      },
    },

    titleLeft: {
      marginBottom: 12,
      fontSize: "16px",
      lineHeight: "19px",
      fontWeight: 600,
      color: "#000B60",
    },

    contentLeft: {
      marginBottom: 12,
      fontSize: 14,
      lineHeight: "24px",
      fontFamily: "",
    },

    bodyPageLeft: {
      width: "52%",
      minWidth: "52%",
      paddingRight: 40,

      [theme.breakpoints.down("md")]: {
        width: "100%",
        paddingRight: 0,
      },
    },
    walletFormTop: {
      display: "flex",
      justifyContent: "space-between",
      "& button:hover": {
        outline: "none",
        boxShadow: "none",
      },
    },
    walletForm: {
      display: "flex",
      marginBottom: "6px",
      padding: "12px",
      background: "#D5E3FF",
      borderRadius: 12,
      alignItems: "center",

      "& img": {
        width: 32,
        height: 32,
      },

      "& .wallet-address": {
        display: "grid",
        width: "100%",
        paddingLeft: 8,
        font: "12px/16px ",
        color: "#AEAEAE",
        gap: 4,

        "& span": {
          fontSize: "14px",
          lineHeight: "16px",
          color: "#000B60",
        },

        "& span:last-child": {
          fontSize: "18px",
          lineHeight: "21px",
          fontWeight: 600,
        },

        '& span[data-role="dot"]': {
          color: "#D01F36",
        },
      },

      "& button": {
        alignSelf: "stretch",
        width: 80,
        minWidth: 80,
        height: 16,
        fontSize: 14,
        lineHeight: "16px",
        color: "#DC3030",
        fontWeight: 600,
        border: "none",
        background: "none",
        cursor: "pointer",
        textAlign: "right",
        outline: "none",

        '&[data-role="connect"]': {
          color: "#6DBE84",
        },
      },
    },

    btnEditProfile: {
      padding: "10px 20px 12px",
      border: "1px solid #0058FF",
      borderRadius: 60,
      fontWeight: 600,
      fontSize: 14,
      lineHeight: "16px",
      color: "#0058FF",
      textAlign: "center",
      textTransform: "initial",
      background: "#FFFFFF",

      [theme.breakpoints.down("sm")]: {
        padding: "10px 20px 12px",
      },
      "&:hover": {
        background: "#FFFFFF",
        opacity: 0.85,
      },
    },

    mainInfomation: {
      width: "100%",
    },

    inputGroup: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      marginBottom: 6,
      fontFamily: "",
      fontSize: 14,
      lineHeight: "20px",
      color: "#FFFFFF",

      "& .flex": {
        display: "flex",
        alignItems: "center",
        color: "white",
      },

      "& > span:first-child": {
        minWidth: 100,
        whiteSpace: "nowrap",
        marginRight: 12,
        fontWeight: 600,
        fontSize: 16,
        lineHeight: "19px",
        color: "#000B60",
      },
      "& span.title": {
        display: "flex",
        alignItems: "center",
        fontWeight: "400 !important",
      },

      "& span.title:not(:first-child)": {
        fontFamily: "",
        fontWeight: "normal",
        paddingTop: 8,
      },

      "& div.accountContent": {
        width: "100%",
        color: "#000B60",
      },

      "& span.verify-email": {
        textDecoration: "underline",
        cursor: "pointer",
        width: "auto",
      },

      "& span.unverified": {
        marginRight: 12,
        color: "#DC3030",
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "19px",

        "& button": {
          height: "fit-content",
          padding: "10px 32px 12px",
          marginLeft: 7,
          background: "#0058FF",
          borderRadius: 60,
          fontWeight: 600,
          fontSize: 12,
          lineHeight: "14px",
          color: "#FFFFFF",
        },
      },

      "& span.verified": {
        fontFamily: "",
        color: "#00A82F",
      },

      "& button": {
        fontFamily: "",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "14px",
        lineHeight: "18px",
        color: "#6398FF",
        mixBlendMode: "normal",
        minWidth: "90px",
        height: "28px",
        border: "1px solid #6398FF",
        boxSizing: "border-box",
        borderRadius: "36px",
        background: "none",
        cursor: "pointer",
      },
    },

    nnn1424h: {
      fontSize: "14px",
      lineHeight: "16px",
      fontWeight: 600,
      color: "#000B60",
    },

    iconStatus: {
      marginLeft: 4,
    },

    groupInput: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },

    errorInput: {
      color: "#D01F36",
      fontSize: "14px",
      lineHeight: "14px",
    },

    inputNewValue: {
      width: "100%",
      maxWidth: 300,
      height: 36,

      "&.MuiInputBase-root, &.MuiInputBase-input": {
        height: 32,
      },

      "&>div:before, &>div:after": {
        display: "none",
      },

      [theme.breakpoints.down("sm")]: {
        width: "100%",
        maxWidth: "100%",
      },

      "& input": {
        width: "100%",
        height: 18,
        borderRadius: 8,
        padding: "6px 10px 8px",
        fontFamily: "",
        fontSize: 14,
        lineHeight: "20px",
        color: "#000B60",
        background: "#FFFFFF",

        [theme.breakpoints.down("sm")]: {
          width: "100%",
          maxWidth: "100%",
          marginTop: 12,
        },

        "&::placeholder": {
          fontFamily: "",
          fontSize: 14,
          lineHeight: "20px",
          color: "#AEAEAE",
        },
      },
    },
    smallInputNewValue: {
      width: "100%",
      maxWidth: 300,
      height: 28,
      padding: "0 !important",
      "& div": {
        padding: "0 !important",
      },

      "&.MuiInputBase-root, &.MuiInputBase-input": {
        height: 28,
        padding: "0 !important",
      },

      "&>div:before, &>div:after": {
        display: "none",
      },

      [theme.breakpoints.down("sm")]: {
        width: "100%",
        maxWidth: "100%",
      },

      "& input": {
        width: "100%",
        height: 18,
        border: "1px solid #44454B",
        borderRadius: 4,
        padding: "5px",
        fontFamily: "Darker Grotesque !important",
        fontSize: 12,
        lineHeight: "20px",
        color: "#AEAEAE",

        [theme.breakpoints.down("sm")]: {
          width: "100%",
          maxWidth: "100%",
        },

        "&::placeholder": {
          fontFamily: "",
          fontSize: 12,
          lineHeight: "20px",
          color: "#AEAEAE",
        },
      },
    },
    lineBottom: {
      height: 1,
      margin: "20px 0",
      background:
        "linear-gradient(90deg, #A5CCFF 0%, #C6DFFF 50%, #A5CCFF 100%)",
    },

    nameSocial: {
      color: "#000B60",
      [theme.breakpoints.down("sm")]: {
        marginTop: 8,
      },
    },

    redKiteInfo: {
      marginTop: "25px",

      "& .kyc-info": {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      },

      "& .kyc-info span": {
        font: "normal normal normal 14px/24px ",
        color: "#fff",
      },

      [theme.breakpoints.down("xs")]: {
        "& .kyc-info": {
          flexDirection: "column",
          alignItems: "flex-start",
        },
      },
    },

    walletInfo: {
      display: "flex",
      flexDirection: "column",
      background: "rgba(255, 255, 255, 0.06)",
      borderRadius: "8px",
      width: "100%",
      marginTop: "15px",
      padding: "26px 22px",

      "& p": {
        fontFamily: "",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "24px",
        color: "#999999",
      },
      "& span": {
        fontWeight: "bold",
        color: "#FFFFFF",
        fontSize: "28px",
        lineHeight: "32px",
        fontFamily: "",
        fontStyle: "normal",
      },
    },

    [theme.breakpoints.down("sm")]: {
      wrapper: {
        border: "none",
        padding: "24px 20px",
      },
      inputGroup: {
        flexDirection: "column",
        alignItems: "flex-start",

        "& span": {
          fontWeight: "normal !important",
        },
      },
    },
    footerUnderTitle: {
      margin: "8px 0 18px",
      fontSize: 16,
      lineHeight: "19px",
      color: "#000B60",
    },

    footerPage: {
      display: "flex",
      justifyContent: "flex-end",
    },

    form: {
      paddingTop: "16px",
    },
    btnUpdateProfile: {
      padding: "16px 40px 18px",
      background: "#0058FF",
      borderRadius: 60,
      fontWeight: 600,
      fontSize: 14,
      lineHeight: "16px",
      color: "#FFFFFF",
      textAlign: "center",
      textTransform: "initial",

      [theme.breakpoints.down("sm")]: {
        padding: "10px 20px 12px",
      },
      "&:hover": {
        background: "#0058FF",
        opacity: 0.85,
      },
    },

    startSteps: {
      display: "flex",
      width: "100%",
      padding: "0 20px",
      background: "#D5E3FF",
      borderRadius: "20px",
      overflow: "hidden",
      "& .steps-top": {
        display: "flex",
        gap: "7px",
        alignItems: "center",
      },

      '& div[data-role="step"]': {
        display: "flex",
        flexDirection: "column",
        width: "calc(100% / 3)",
        marginRight: "2px",
        padding: "20px",
        borderRadius: 2,

        "&:first-child .actionStep>a div": {
          background: "#0058FF",
          color: "#ffffff",
        },
        "&:last-child": {
          marginRight: 0,
        },

        "& .idStep": {
          fontSize: "12px",
          lineHeight: "14px",
          color: "#0055D6",
          fontWeight: 600,
        },
        "& img": {
          width: 32,
          height: 32,
        },

        "& .titleStep": {
          fontSize: "18px",
          lineHeight: "21px",
          fontWeight: 600,
          color: "#000B60",
        },

        "& .contentStep": {
          padding: "15px 0 12px",
          fontSize: "14px",
          lineHeight: "16px",
          color: "#000B60",
        },

        "& .actionStep": {
          "& div": {
            width: "fit-content",
            padding: "10px 32px 12px",
            border: "1px solid #0058FF",
            borderRadius: 60,
            fontWeight: 600,
            fontSize: 12,
            lineHeight: "14px",
            color: "#0058FF",
            mixBlendMode: "normal",
            background: "none",
          },
        },

        "& .completedOverlay": {
          display: "flex",
          padding: "16px 16px 16px 16px",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "center",
          gap: "12px",
          flex: "1 0 0",
          alignSelf: "stretch",

          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "rgba(33, 33, 38, .32)",
          backdropFilter: "blur(3px)",

          "& .completedOverlay__content": {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",

            "& img": {
              width: 24,
              height: 24,
            },
            "& span": {
              marginLeft: 8,
              color: "#93E185",
              fontSize: 22,
              fontWeight: 400,
              fontFamily: "",
            },
          },
        },
      },

      [theme.breakpoints.down("sm")]: {
        display: "grid",

        '& div[data-role="step"]': {
          width: "100%",
          marginBottom: 10,
          padding: "20px 0",
        },

        "& .titleStep": {
          height: "50px !important",
          padding: "5px 0 10px !important",
        },
      },
    },
    [theme.breakpoints.only("xs")]: {
      wrapper: {
        background: "none",
        padding: "10px",
      },
      headPage: {
        display: "none",
      },
      titleLeft: {
        marginTop: 20,
      },
      inputGroup: {
        "& input": {
          marginTop: 0,
          background: "#292929",
        },
      },
    },
  };
});

export default useStyles;
