import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import mobile from "is-mobile";
import {
  settingAppNetwork,
  NetworkUpdateType,
} from "../../../../store/actions/appNetwork";
import { WalletInfo } from "../../../../constants/connectors";
import { NetworkInfo } from "../../../../constants/network";
import { HeaderContext, HeaderContextType } from "../context/HeaderContext";
import useStyles from "./style";
import { MetaMask } from "@web3-react/metamask";
import { WalletConnect } from "@web3-react/walletconnect-v2";
import { BscWallet } from "../../../../connectors/BinanceWallet";

interface ConnectWalletBoxPropsType {
  appNetwork?: NetworkInfo;
  wallet?: WalletInfo;
  isAppNetwork?: boolean;
  handleProviderChosen?: (
    name: string,
    connector: MetaMask | WalletConnect | BscWallet
  ) => void;
  connectWalletLoading?: boolean;
  walletName?: (string | undefined)[];
  forceEnable?: boolean;
  handleClose?: () => void;
  isSwitchNetwork?: boolean;
}

const ConnectWalletBox: React.FC<ConnectWalletBoxPropsType> = (
  props: ConnectWalletBoxPropsType
) => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const {
    appNetwork,
    isAppNetwork = false,
    handleProviderChosen,
    wallet,
    walletName,
    connectWalletLoading,
    forceEnable,
    handleClose,
    isSwitchNetwork = false,
  } = props;
  const { appChainID } = useSelector((state: any) => state.appNetwork).data;
  const { agreedTerms } = useContext<HeaderContextType>(HeaderContext);

  const handleNetworkChange = (
    appNetwork: boolean,
    updatedVal: string,
    agreedTerms: boolean = false
  ) => {
    if (agreedTerms || forceEnable) {
      if (appNetwork) {
        dispatch(settingAppNetwork(NetworkUpdateType.App, updatedVal));
        handleClose && handleClose();
        return;
      }

      wallet &&
        handleProviderChosen &&
        handleProviderChosen(wallet.name, wallet.connector);
    }
  };

  const pointerStyle = {
    cursor: `${agreedTerms || forceEnable ? "pointer" : "initial"}`,
  };

  const render = () => {
    if (appNetwork) {
      const { name, icon, id, disableIcon } = appNetwork;
      const temporaryDisable = false;

      return (
        <div
          className={`${appChainID === id && styles.activeNetwork} ${
            isSwitchNetwork ? styles.walletBox : styles.networkBox
          } ${
            !((agreedTerms || forceEnable) && !temporaryDisable) &&
            styles.disableNetwork
          }`}
          onClick={() => {
            !temporaryDisable &&
              handleNetworkChange(isAppNetwork, id as string, agreedTerms);
          }}
          style={pointerStyle}
        >
          {<img src={icon} alt={name} />}
          <p className={styles.walletBoxText}>{name}</p>
        </div>
      );
    }

    if (wallet) {
      const { name, icon } = wallet;
      const selectingWallet = walletName && walletName.indexOf(name) >= 0;

      return (
        <div
          className={`${styles.walletBox} ${
            selectingWallet && styles.activeNetwork
          } ${!agreedTerms && styles.disableNetwork}`}
          onClick={() => {
            if (mobile() && wallet?.deepLink) {
              window.open(wallet.deepLink);
              return;
            }
            handleNetworkChange(isAppNetwork, name, agreedTerms);
          }}
          style={pointerStyle}
        >
          <div className={styles.walletBoxIconWrap}>
            {connectWalletLoading && selectingWallet && agreedTerms ? (
              <img
                className={styles.loadingAnimation}
                src="/images/loading-v3.svg"
                alt="wallet"
              />
            ) : (
              <img src={icon} alt={name} />
            )}
          </div>
          <p className={styles.walletBoxText}>{name}</p>
        </div>
      );
    }

    return null;
  };

  return render();
};

export default ConnectWalletBox;
