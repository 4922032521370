import { withWidth } from "@material-ui/core";
import BigNumber from "bignumber.js";
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import NumberFormat from "react-number-format";
import { useDispatch } from "react-redux";
import { AppContext } from "../../../AppContext";
import TransactionSubmitModal from "../../../components/Base/TransactionSubmitModal";
import { ACCEPT_CURRENCY } from "../../../constants";
import {
  ETH_CHAIN_ID,
  POLYGON_CHAIN_ID,
  AVALANCHE_CHAIN_ID,
  ARBITRUM_CHAIN_ID,
  BASE_CHAIN_ID,
  currencyAddress,
} from "../../../constants/network";
import { PurchaseCurrency } from "../../../constants/purchasableCurrency";
import useTokenAllowance from "../../../hooks/useTokenAllowance";
import useTokenApprove from "../../../hooks/useTokenApprove";
import useTokenBalance from "../../../hooks/useTokenBalance";
import { TokenType } from "../../../hooks/useTokenDetails";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { alertFailure } from "../../../store/actions/alert";
import { connectWalletSuccess } from "../../../store/actions/wallet";
import { useCommonStyle } from "../../../styles";
import {
  getBUSDAddress,
  getUSDCAddress,
  getUSDTAddress,
} from "../../../utils/contractAddress/getAddresses";
import {
  convertDateTimeToUnix,
  convertTimeToStringFormatWithoutGMT,
  convertUnixTimeToDateTime,
} from "../../../utils/convertDate";
import {
  formatRoundDown,
  formatRoundUp,
  numberWithCommas,
} from "../../../utils/formatNumber";
import getAccountBalance from "../../../utils/getAccountBalance";
import { getEtherscanName } from "../../../utils/network";
import { getIconCurrencyUsdt } from "../../../utils/usdt";
import Button from "../Button";
import usePoolDepositAction from "../hooks/usePoolDepositAction";
import useUserPurchased from "../hooks/useUserPurchased";
import useStyles from "./style";

const REGEX_NUMBER = /^-?[0-9]{0,}[.]{0,1}[0-9]{0,6}$/;

type BuyTokenFormProps = {
  tokenDetails: TokenType | undefined;
  rate: number | undefined;
  poolAddress: string | undefined;
  maximumBuy: number;
  minimumBuy: number;
  poolAmount: number | undefined;
  purchasableCurrency: string | undefined;
  poolId: number | undefined;
  joinTime: Date | undefined;
  method: string | undefined;
  availablePurchase: boolean | undefined;
  ableToFetchFromBlockchain: boolean | undefined;
  minTier: any | undefined;
  isDeployed: boolean | undefined;
  endBuyTimeInDate: Date | undefined;
  startBuyTimeInDate: Date | undefined;
  endJoinTimeInDate: Date | undefined;
  tokenSold: string | undefined;
  setBuyTokenSuccess: Dispatch<SetStateAction<boolean>>;
  isClaimable: boolean | undefined;
  currentUserTier: any;
  alreadyJoinPool: any;
  joinPoolSuccess: boolean;
  existedWinner: any;
  disableAllButton: boolean;
  networkAvailable: string;
  poolDetailsMapping: any;
  poolDetails: any;
  isInPreOrderTime: boolean;
  connectedAccount: any;
  wrongChain: any;
  guaranteeAllocation: number;
  fcfsAllocation: number;
};

enum MessageType {
  error = "error",
  warning = "warning",
}

const envLocal = localStorage?.getItem("env");
const env = envLocal ? JSON?.parse(envLocal) : {};

const BuyTokenForm: React.FC<BuyTokenFormProps> = (props: any) => {
  const styles = useStyles();
  const commonStyles = useCommonStyle();
  const dispatch = useDispatch();

  const [input, setInput] = useState("");
  const [openApproveModal, setApproveModal] = useState(false);
  const [openSubmitModal, setOpenSubmitModal] = useState(false);
  const [estimateTokens, setEstimateTokens] = useState<number>(0);
  const [tokenAllowance, setTokenAllowance] = useState<string | undefined>(
    undefined
  );
  const [tokenBalance, setTokenBalance] = useState<number>(0);
  const [walletBalance, setWalletBalance] = useState<number>(0);
  const [userPurchased, setUserPurchased] = useState<number>(0);
  const [poolBalance, setPoolBalance] = useState<number>(0);
  const [loadingPoolInfo, setLoadingPoolInfo] = useState<boolean>(false);

  const {
    tokenDetails,
    rate,
    poolAddress,
    maximumBuy,
    purchasableCurrency,
    poolId,
    availablePurchase,
    ableToFetchFromBlockchain,
    isDeployed,
    minimumBuy,
    poolAmount,
    startBuyTimeInDate,
    endBuyTimeInDate,
    tokenSold,
    setBuyTokenSuccess,
    isClaimable,
    existedWinner,
    disableAllButton,
    networkAvailable,
    poolDetails,
    isInPreOrderTime,
    connectedAccount,
    wrongChain,
    guaranteeAllocation,
    fcfsAllocation,
  } = props;

  const { currentConnectedWallet } = useContext(AppContext);

  const currentAccount =
    currentConnectedWallet && currentConnectedWallet.addresses[0];
  const balance = currentConnectedWallet
    ? currentConnectedWallet.balances[currentAccount]
    : 0;
  const { appChainID, walletChainID } = useTypedSelector(
    (state) => state.appNetwork
  ).data;
  const connector = useTypedSelector((state) => state.connector).data;

  const etherscanName = getEtherscanName({ networkAvailable });
  const {
    deposit,
    tokenDepositLoading,
    tokenDepositTransaction,
    depositError,
    tokenDepositSuccess,
  } = usePoolDepositAction({
    poolAddress,
    poolId,
    purchasableCurrency,
    amount: input,
    isClaimable,
    networkAvailable,
    isInPreOrderTime,
  });

  const { currencyName } = getIconCurrencyUsdt({
    purchasableCurrency,
    networkAvailable,
  });
  const purchasebleAddress =
    currencyAddress?.[poolDetails?.networkClaim]?.[
      poolDetails?.purchasableCurrency
    ] || env.REACT_APP_USDC_BSC_SMART_CONTRACT;
  const { retrieveTokenAllowance } = useTokenAllowance();
  const { retrieveUserPurchased } = useUserPurchased(
    tokenDetails,
    poolAddress,
    ableToFetchFromBlockchain,
    purchasebleAddress
  );

  const getApproveToken = useCallback(
    (appChainID: string) => {
      if (
        purchasableCurrency &&
        purchasableCurrency === PurchaseCurrency.USDT
      ) {
        return {
          address: getUSDTAddress(appChainID),
          name: "USDT",
          symbol: "USDT",
          decimals:
            appChainID == ETH_CHAIN_ID ||
            appChainID == POLYGON_CHAIN_ID ||
            appChainID == BASE_CHAIN_ID ||
            appChainID == AVALANCHE_CHAIN_ID ||
            appChainID === ARBITRUM_CHAIN_ID
              ? 6
              : 18,
        };
      }

      if (
        purchasableCurrency &&
        purchasableCurrency === PurchaseCurrency.BUSD
      ) {
        return {
          address: getBUSDAddress(appChainID),
          name: "BUSD",
          symbol: "BUSD",
          decimals: 18,
        };
      }

      if (
        purchasableCurrency &&
        purchasableCurrency === PurchaseCurrency.USDC
      ) {
        return {
          address: getUSDCAddress(appChainID),
          name: "USDC",
          symbol: "USDC",
          decimals:
            appChainID == ETH_CHAIN_ID ||
            appChainID == POLYGON_CHAIN_ID ||
            appChainID === BASE_CHAIN_ID
              ? 6
              : 18,
        };
      }

      if (purchasableCurrency && purchasableCurrency === PurchaseCurrency.ETH) {
        return {
          address: "0x00",
          name: "ETH",
          symbol: "ETH",
          decimals: 18,
        };
      }
    },
    [purchasableCurrency, appChainID]
  );

  const tokenToApprove = getApproveToken(appChainID);

  const { approveToken, tokenApproveLoading, transactionHash } =
    useTokenApprove(tokenToApprove, connectedAccount, poolAddress, false, true);

  const now = new Date();
  const isInFreeBuying =
    poolDetails?.freeBuyTimeSetting?.start_buy_time &&
    endBuyTimeInDate &&
    new Date(poolDetails?.freeBuyTimeSetting.start_buy_time * 1000) < now &&
    now < endBuyTimeInDate;

  const { retrieveTokenBalance } = useTokenBalance(
    tokenToApprove,
    connectedAccount
  );

  const remainingAmount = formatRoundDown(
    new BigNumber(maximumBuy).minus(
      new BigNumber(userPurchased).multipliedBy(rate)
    )
  );
  const firstBuy = localStorage.getItem("firstBuy") || undefined;
  let parsedFirstBuy = {} as any;
  if (firstBuy) {
    try {
      parsedFirstBuy = JSON.parse(firstBuy);
    } catch (err: any) {
      console.error("parsedFirstBuy", err.message);
    }
  }
  const connectedAccountFirstBuy = connectedAccount
    ? parsedFirstBuy[poolAddress]
      ? parsedFirstBuy[poolAddress][connectedAccount]
      : false
    : false;

  const availableMaximumBuy = useMemo(() => {
    let maxBuy = new BigNumber(guaranteeAllocation).minus(
      new BigNumber(new BigNumber(userPurchased).multipliedBy(rate))
    );

    if (isInFreeBuying) maxBuy = new BigNumber(remainingAmount);
    if (maxBuy.gt(tokenBalance)) {
      return new BigNumber(tokenBalance).gt(0)
        ? formatRoundDown(new BigNumber(tokenBalance))
        : "0";
    }

    return maxBuy.gt(0) ? formatRoundDown(maxBuy) : "0";
  }, [tokenBalance, maximumBuy, userPurchased, poolAmount, tokenSold, rate]);

  const poolErrorBeforeBuy = useMemo(() => {
    if (
      minimumBuy &&
      input &&
      new BigNumber(input || 0).lt(minimumBuy) &&
      !connectedAccountFirstBuy &&
      new Date() > startBuyTimeInDate
    ) {
      return {
        message: `The minimum amount you must trade is ${new BigNumber(
          minimumBuy
        ).toFixed(2)} ${currencyName}.`,
        type: MessageType.error,
      };
    }

    return;
  }, [
    startBuyTimeInDate,
    minimumBuy,
    input,
    connectedAccountFirstBuy,
    currencyName,
    tokenDetails?.symbol,
  ]);

  const [enableApprove, setEnableApprove] = useState<boolean>(false);
  const purchasable =
    availablePurchase &&
    estimateTokens > 0 &&
    !poolErrorBeforeBuy &&
    !wrongChain &&
    !disableAllButton &&
    !enableApprove &&
    (purchasableCurrency !== PurchaseCurrency.ETH
      ? new BigNumber(tokenAllowance || 0).gt(0)
      : true);

  useEffect(() => {
    if (tokenAllowance === undefined) return;

    if (
      (+tokenAllowance <= 0 || new BigNumber(tokenAllowance).lt(input)) &&
      purchasableCurrency &&
      purchasableCurrency !== PurchaseCurrency.ETH &&
      !wrongChain &&
      ableToFetchFromBlockchain &&
      isDeployed &&
      existedWinner &&
      !disableAllButton
    ) {
      setEnableApprove(true);
      return;
    }

    setEnableApprove(new BigNumber(tokenAllowance).lt(input));
  }, [
    tokenAllowance,
    input,
    purchasableCurrency,
    wrongChain,
    ableToFetchFromBlockchain,
    isDeployed,
    existedWinner,
    disableAllButton,
    rate,
  ]);
  const fetchUserBalance = useCallback(async () => {
    if (appChainID && connectedAccount && connector) {
      const accountBalance = await getAccountBalance(
        appChainID,
        walletChainID,
        connectedAccount as string,
        connector
      );

      dispatch(
        connectWalletSuccess(connector, [connectedAccount], {
          [connectedAccount]: new BigNumber(accountBalance._hex)
            .div(new BigNumber(10).pow(18))
            .toFixed(5),
        })
      );
    }
  }, [connector, appChainID, walletChainID, connectedAccount]);

  const fetchPoolDetails = useCallback(async () => {
    if (tokenDetails && poolAddress && connectedAccount && tokenToApprove) {
      setTokenAllowance(
        (await retrieveTokenAllowance(
          tokenToApprove,
          connectedAccount,
          poolAddress
        )) || "0"
      );
      setUserPurchased(
        (await retrieveUserPurchased(connectedAccount, poolAddress)) as number
      );
      const result = await retrieveTokenBalance(
        tokenToApprove,
        connectedAccount
      );

      setTokenBalance(
        (await retrieveTokenBalance(tokenToApprove, connectedAccount)) as number
      );
      setWalletBalance(
        (await retrieveTokenBalance(tokenDetails, connectedAccount)) as number
      );
      setPoolBalance(
        (await retrieveTokenBalance(tokenDetails, poolAddress)) as number
      );
    }
  }, [tokenDetails, connectedAccount, tokenToApprove, poolAddress]);

  useEffect(() => {
    if (maximumBuy && userPurchased && rate) {
      const remainingAmountObject = new BigNumber(remainingAmount);
      remainingAmountObject.gt(0) && setInput(remainingAmountObject.toFixed(2));
    }

    return () => {
      setInput("");
    };
  }, [maximumBuy, userPurchased, rate]);

  useEffect(() => {
    const fetchPoolDetailsBlockchain = async () => {
      await fetchPoolDetails();
      setLoadingPoolInfo(false);
    };

    loadingPoolInfo && fetchPoolDetailsBlockchain();
  }, [loadingPoolInfo]);
  useEffect(() => {
    const fetchTokenPoolAllowance = async () => {
      try {
        setLoadingPoolInfo(true);
      } catch (err) {
        setLoadingPoolInfo(false);
      }
    };

    ableToFetchFromBlockchain && connectedAccount && fetchTokenPoolAllowance();
  }, [connectedAccount, ableToFetchFromBlockchain]);
  useEffect(() => {
    if (depositError) {
      setOpenSubmitModal(false);
    }
  }, [depositError]);
  useEffect(() => {
    const handleWhenDepositSuccess = async () => {
      setBuyTokenSuccess(true);
      await fetchUserBalance();
      await fetchPoolDetails();
    };

    tokenDepositSuccess && handleWhenDepositSuccess();
  }, [tokenDepositSuccess]);

  useEffect(() => {
    if (tokenDepositTransaction) {
      setInput("");
      setEstimateTokens(0);

      if (!connectedAccountFirstBuy) {
        localStorage.setItem(
          "firstBuy",
          JSON.stringify(
            Object.assign(
              {},
              {
                ...parsedFirstBuy,
                [poolAddress as string]: {
                  ...parsedFirstBuy[poolAddress],
                  [connectedAccount as string]: true,
                },
              }
            )
          )
        );
      }
    }
  }, [tokenDepositTransaction, connectedAccountFirstBuy]);

  useEffect(() => {
    if (input && rate && purchasableCurrency) {
      const tokens = new BigNumber(input)
        .multipliedBy(new BigNumber(1).div(rate))
        .toNumber();
      const tokenWithDecimal = new BigNumber(tokens)
        .decimalPlaces(6)
        .toNumber();
      setEstimateTokens(tokenWithDecimal);
    } else {
      setEstimateTokens(0);
    }
  }, [input, purchasableCurrency, rate]);

  const checkAllowInput = (values: any) => {
    const { value, floatValue } = values;
    return (
      Number(value) === 0 ||
      (!!floatValue && floatValue <= formatRoundDown(availableMaximumBuy))
    );
  };

  const handleInputChange = async (e: any) => {
    const value = e.target.value.replaceAll(",", "");
    if (value === "" || REGEX_NUMBER.test(value)) {
      setInput(value);
    }
  };

  const validateDeposit = () => {
    if (new BigNumber(tokenBalance).eq(0)) {
      dispatch(alertFailure(`You don't have enough token balance.`));
      return false;
    }
    const currencyUserBought = formatRoundUp(
      new BigNumber(userPurchased).multipliedBy(poolDetails?.ethRate || 0)
    );
    const remainCurrencyAvailable = formatRoundDown(
      new BigNumber(maximumBuy).minus(currencyUserBought)
    );

    if (new BigNumber(maximumBuy).eq(0) && !isInFreeBuying) {
      dispatch(alertFailure(`Please wait until Phase 2 start time.`));
      return false;
    }

    const isOverMaxBuy = new BigNumber(input).gt(remainCurrencyAvailable);
    if (isOverMaxBuy) {
      dispatch(
        alertFailure(
          `You can only buy up to ${numberWithCommas(
            remainCurrencyAvailable,
            2
          )} ${currencyName}`
        )
      );
      return false;
    }
    const remainToken = new BigNumber(poolAmount).minus(tokenSold).toFixed();
    const isOverRemainToken = new BigNumber(estimateTokens).gt(remainToken);
    if (isOverRemainToken) {
      dispatch(
        alertFailure(
          `Not enough token for sale, you can only buy up to ${numberWithCommas(
            remainToken,
            2
          )} ${tokenDetails?.symbol}`
        )
      );
      return false;
    }

    return true;
  };

  const handleTokenDeposit = async () => {
    const isValid = validateDeposit();
    if (!isValid) {
      return false;
    }
    try {
      if (purchasableCurrency && ableToFetchFromBlockchain) {
        setOpenSubmitModal(true);
        setBuyTokenSuccess(false);
        await deposit();
      }
    } catch (err) {
      setOpenSubmitModal(false);
    }
  };

  const handleTokenApprove = async () => {
    try {
      setApproveModal(true);
      await approveToken();

      if (tokenDetails && poolAddress && connectedAccount && tokenToApprove) {
        setTokenAllowance(
          (await retrieveTokenAllowance(
            tokenToApprove,
            connectedAccount,
            poolAddress
          )) || "0"
        );
        setTokenBalance(
          (await retrieveTokenBalance(
            tokenToApprove,
            connectedAccount
          )) as number
        );
      }
    } catch (err) {
      setApproveModal(false);
    }
  };

  const renderSwapTokenInfo = () => {
    return (
      <div className={styles.poolBuyTokenItem}>
        <div className={styles.leftBuyTokenForm}>
          <div className={styles.buyTokenFormTitle}>
            <div className={styles.allowcationWrap}>
              <span className={styles.allocationTitle}>
                {!!poolDetails?.freeBuyTimeSetting?.start_buy_time
                  ? "Allocation for Phase 1"
                  : "Max Allocation"}
              </span>
              <span className={styles.allocationContent}>
                {numberWithCommas(
                  formatRoundDown(new BigNumber(guaranteeAllocation))
                )}{" "}
                {currencyName}
              </span>
            </div>

            {!!poolDetails?.freeBuyTimeSetting?.start_buy_time && (
              <div className={styles.allowcationWrap}>
                <span className={styles.allocationTitle}>
                  Allocation for Phase 2{" "}
                </span>
                <span className={styles.allocationContent}>
                  {numberWithCommas(
                    formatRoundDown(new BigNumber(fcfsAllocation))
                  )}{" "}
                  {currencyName}
                </span>
              </div>
            )}

            <div className={styles.allowcationWrap}>
              <span className={styles.allocationTitle}>Have Bought </span>
              <span className={styles.allocationContent}>
                {numberWithCommas(
                  formatRoundUp(new BigNumber(userPurchased).multipliedBy(rate))
                )}{" "}
                {currencyName}
              </span>
            </div>

            <div className={styles.allowcationWrap}>
              <span className={styles.allocationTitle}>Remaining </span>
              <span className={styles.allocationContent}>
                {numberWithCommas(
                  new BigNumber(remainingAmount).lte(0)
                    ? "0"
                    : formatRoundDown(new BigNumber(remainingAmount))
                )}{" "}
                {currencyName}
              </span>
            </div>

            {isInPreOrderTime && (
              <div className={styles.allowcationWrap}>
                <span className={styles.allocationTitle}>Pre Order Time</span>
                <span className={styles.allocationContent}>
                  {convertTimeToStringFormatWithoutGMT(startBuyTimeInDate)} -{" "}
                  <br />
                  {convertUnixTimeToDateTime(
                    parseInt(convertDateTimeToUnix(endBuyTimeInDate)),
                    1
                  )}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderSwapForm = () => {
    return (
      <div className={styles.rightBuyTokenForm}>
        <div className={styles.title2}>
          Your Wallet Balance&nbsp;
          <div className={styles.currencyName}>
            {`(Balance: ${numberWithCommas(
              parseFloat(tokenBalance.toString()).toFixed(6)
            )} ${currencyName})`}
          </div>
        </div>

        <div className={styles.buyTokenInputForm}>
          <div className={styles.buyTokenInputWrapper}>
            <NumberFormat
              className={styles.buyTokenInput}
              placeholder={"Enter amount"}
              thousandSeparator={true}
              onChange={handleInputChange}
              decimalScale={6}
              value={input}
              defaultValue={maximumBuy || 0}
              max={tokenBalance}
              min={0}
              maxLength={255}
              disabled={wrongChain}
            />
            <span className={styles.purchasableCurrency}>
              {/* <img src={currencyIcon} alt={purchasableCurrency} className={styles.purchasableCurrencyIcon} /> */}
              {currencyName}
              <button
                className={styles.purchasableCurrencyMax}
                onClick={() => {
                  setInput(formatRoundDown(availableMaximumBuy));
                }}
              >
                Max
              </button>
            </span>
          </div>
        </div>

        <div className={styles.buyTokenEstimate}>
          <div className={commonStyles.flexRow}>
            <p className={styles.approcimately}>You will get approximately</p>
            {poolDetails?.ethRate && poolDetails?.tokenDetails?.symbol && (
              <span className={styles.tokenPrice}>
                {poolDetails?.ethRate} {currencyName} per&nbsp;
                {poolDetails?.tokenDetails?.symbol}
              </span>
            )}
          </div>
          <strong className={styles.buyTokenEstimateAmount}>
            {numberWithCommas(`${estimateTokens || 0}`, 2)}{" "}
            {tokenDetails?.symbol}
          </strong>
        </div>

        {
          <p
            className={`${
              poolErrorBeforeBuy?.type === MessageType.error
                ? `${styles.poolErrorBuy}`
                : `${styles.poolErrorBuyWarning}`
            }`}
          >
            {poolErrorBeforeBuy && poolErrorBeforeBuy.message}
          </p>
        }

        {purchasableCurrency !== PurchaseCurrency.ETH &&
          purchasableCurrency?.toUpperCase() !==
            ACCEPT_CURRENCY.ETH?.toUpperCase() && (
            <p
              className={styles.title3}
            >{`You need to Approve first before purchasing. Please set a sufficient spending cap or use the default value.`}</p>
          )}
        <div className={styles.btnGroup}>
          <div>
            {purchasableCurrency?.toUpperCase() !==
              ACCEPT_CURRENCY.ETH?.toUpperCase() && (
              <Button
                text={enableApprove ? "Approve" : "Approved"}
                disabled={!enableApprove}
                onClick={handleTokenApprove}
                loading={tokenApproveLoading}
              />
            )}
          </div>

          <div>
            <Button
              text={isInPreOrderTime ? "Pre-order" : "Swap"}
              disabled={!purchasable}
              onClick={handleTokenDeposit}
              loading={tokenDepositLoading}
            />
          </div>
        </div>

        <TransactionSubmitModal
          opened={openSubmitModal}
          handleClose={() => {
            setOpenSubmitModal(false);
          }}
          transactionHash={tokenDepositTransaction}
        />
        <TransactionSubmitModal
          additionalText={`Please be patient and no need to approve again, you can check the transaction status on ${etherscanName}.`}
          opened={openApproveModal}
          handleClose={() => {
            setApproveModal(false);
          }}
          transactionHash={transactionHash}
        />
      </div>
    );
  };

  return (
    <div className={styles.buyTokenForm}>
      {renderSwapTokenInfo()}
      {renderSwapForm()}
    </div>
  );
};

export default withWidth()(BuyTokenForm);
