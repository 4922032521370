import BigNumber from "bignumber.js";
import _ from "lodash";
import {
  ADMIN_URL_PREFIX,
  API_URL_PREFIX,
  ETHERSCAN_BASE_URL,
  IMAGE_URL_PREFIX,
  NETWORK_AVAILABLE,
} from "../constants";
import { getApiUrl } from "../types/getEnv";

const envLocal = localStorage?.getItem("env");
const env = envLocal ? JSON?.parse(envLocal) : {};

export function formatPrecisionAmount(
  amount: any,
  precision: number = 18
): string {
  const rawValue = new BigNumber(`${amount}`).toFixed(precision);
  return amount && parseFloat(amount) !== Infinity
    ? new BigNumber(rawValue).toFormat()
    : "0";
}

export const routeWithPrefix = (prefix = ADMIN_URL_PREFIX, url = "") => {
  const truncateUrl = _.trim(url, "/");
  return `/${prefix}/${truncateUrl}`;
};

export const adminRoute = (url = "") => {
  const truncateUrl = _.trim(url, "/");
  const resUrl = `/${ADMIN_URL_PREFIX}/${truncateUrl}`;
  return resUrl;
};

export const publicRoute = (url = "") => {
  const truncateUrl = _.trim(url, "/");
  const resUrl = `/${truncateUrl}`;
  return resUrl;
};

export const checkIsAdminRoute = (pathname: string) => {
  return (
    pathname.indexOf(`/${ADMIN_URL_PREFIX}`) !== -1 ||
    pathname === "/dashboard/login"
  );
};

export const checkIsLoginRoute = (pathname: string) => {
  return pathname.indexOf(`/login`) !== -1;
};

export const checkIsInvestorRoute = (pathname: string) => {
  return pathname.indexOf(`/buy-token`) !== -1 || pathname === "/login";
};

export const apiRoute = (url = "") => {
  const truncateUrl = _.trim(url, "/");
  const resUrl = `/${API_URL_PREFIX}/${truncateUrl}`;
  return resUrl;
};

export const imageRoute = (url = "") => {
  const truncateUrl = _.trim(url, "/");
  const baseUrl = getApiUrl();
  const resUrl = `${baseUrl}/${IMAGE_URL_PREFIX}/${truncateUrl}`;
  return resUrl;
};

export const etherscanRoute = (address = "", poolDetail: any = null) => {
  let network = "";
  if (poolDetail) {
    switch (poolDetail.network_available) {
      case NETWORK_AVAILABLE.BSC:
        network = env.REACT_APP_BSC_CHAIN_ID + "";
        break;

      case NETWORK_AVAILABLE.BASE:
        network = env.REACT_APP_BASE_CHAIN_ID + "";
        break;

      case NETWORK_AVAILABLE.POLYGON:
        network = env.REACT_APP_POLYGON_CHAIN_ID + "";
        break;

      case NETWORK_AVAILABLE.ETH:
        network = env.REACT_APP_ETH_CHAIN_ID + "";
        break;
    }
  }

  const networkId =
    network ||
    localStorage.getItem("NETWORK_ID") ||
    env.REACT_APP_ETH_CHAIN_ID ||
    "1";
  const baseUrl = ETHERSCAN_BASE_URL[networkId];
  const truncateUrl = _.trim(address, "/");
  const resUrl = `${baseUrl}/${truncateUrl}`;
  return resUrl;
};

export const etherscanAddressRoute = (address = "", poolDetail: any = null) => {
  return etherscanRoute(`address/${address}`, poolDetail);
};

export const etherscanTransactionRoute = (
  address = "",
  poolDetail: any = null
) => {
  return etherscanRoute(`tx/${address}`, poolDetail);
};

export const getTransactionRowType = (transaction: any) => {
  if (transaction?.type === "Refund") {
    return "Refund";
  }
  if (transaction?.type === "TokenClaimed") {
    return "Claim";
  }
  return "Buy";
};

export const fixGasLimit = (type = "deposit") => {
  let overrides = {};
  if (env.NODE_ENV !== "production") {
    if (type == "deposit") {
      overrides = {
        gasLimit: 200000,
        gasPrice: 10000000000,
      };
    } else if (type == "approve") {
      overrides = {
        gasLimit: 500000,
        gasPrice: 50000000000,
      };
    } else if (type == "claim") {
      overrides = {
        gasLimit: 200000,
        gasPrice: 10000000000,
      };
    } else if (type == "buy") {
      overrides = {
        gasLimit: 500000,
        gasPrice: 10000000000,
      };
    }
  }

  return overrides;
};

export const fixGasLimitWithProvider = (library: any, type = "deposit") => {
  let overrides = {};
  return overrides;
};

export const paginationArray = (
  array: any,
  page_number: any,
  page_size: any
) => {
  const newData = JSON.parse(JSON.stringify(array));
  const pageData = newData.slice(
    (page_number - 1) * page_size,
    page_number * page_size
  );
  const dataLength = newData.length;
  return {
    data: pageData,
    total: dataLength,
    perPage: page_size,
    lastPage: Math.ceil(dataLength / page_size),
    page: page_number,
  };
};

export const shortenAddress = (address: string, digits: number = 4) => {
  return `${address.substring(0, digits + 2)}...${address.substring(
    42 - digits
  )}`;
};

export function formatDecimal(num: BigNumber, tokenDecimal: number) {
  const decimalCount = new BigNumber(num)?.isInteger()
    ? 0
    : new BigNumber(num)?.toString()?.split(".")?.[1]?.length;
  const precision = decimalCount > tokenDecimal ? tokenDecimal : decimalCount;
  return new BigNumber(num)?.toFixed(precision)?.replace(/\.0+$/, "");
}
