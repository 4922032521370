import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
  return {
    pageNeedHelp: {
      borderRadius: "2px",
      padding: "28px 40px 40px 40px",
      fontSize: 16,
      lineHeight: "19px",
      color: "#000B60",
      [theme.breakpoints.down("sm")]: {
        border: "none",
        padding: "20px 20px",
      },
      [theme.breakpoints.only("xs")]: {
        background: "transparent",
        padding: "10px",
      },
    },

    title: {
      marginBottom: 20,
      fontSize: "32px",
      lineHeight: "32px",
      fontWeight: 600,
      color: "#000B60",

      [theme.breakpoints.down("sm")]: {
        textAlign: "center",
        fontWeight: 500,
        marginBottom: 12,
      },
      [theme.breakpoints.only("xs")]: {
        display: "none",
      },
    },

    sectionBody: {
      marginBottom: 40,
      display: "flex",
      alignItems: "flex-start",

      [theme.breakpoints.down("sm")]: {
        "&:last-child": {
          marginBottom: 0,
        },
      },

      "&:last-child": {
        marginBottom: 0,
      },
    },

    sectionBodyQuestions: {
      width: "calc(360px + 16px + 8px)",
      maxWidth: "100%",

      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },

    iconSectionBody: {
      width: 16,
      marginRight: 8,
      marginTop: 2,

      [theme.breakpoints.down("sm")]: {
        marginTop: 0,
        marginBottom: -2,
      },
    },

    subTitle: {
      marginBottom: 8,
      fontSize: "24px",
      lineHeight: "24px",
      fontWeight: 600,
      color: "#000B60",
    },

    des: {
      fontSize: "16px",
      lineHeight: "19px",
      "& a": {
        color: "#0058FF",
        fontWeight: 600,
      },
    },

    groupSearch: {
      width: "100%",
      maxWidth: "320px",
      marginTop: "12px",
      background: "#D5E3FF",
      border: "none",
      borderRadius: 4,
      display: "flex",
      marginBottom: 20,

      "& input": {
        height: 36,
        width: "100%",
        border: "none",
        outline: "none",
        padding: 12,
        background: "transparent",
        fontFamily: "",
        fontSize: 14,
        lineHeight: "24px",
        color: "#AEAEAE",
      },

      "& button": {
        minWidth: 40,
        padding: "10px 12px",
      },
    },

    boxQuestions: {
      marginBottom: 20,

      [theme.breakpoints.down("sm")]: {
        "&:last-child": {
          marginBottom: 0,
        },
      },

      "&:last-child": {
        marginBottom: 0,
      },
    },

    listQuestions: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr",

      [theme.breakpoints.down("sm")]: {
        gridTemplateColumns: "1fr",
      },
    },

    itemQuestions: {
      minWidth: "300px",
      fontFamily: "",
      fontSize: 14,
      lineHeight: "24px",
      marginBottom: 4,
      paddingRight: 5,

      "& a": {
        fontSize: "16px",
        lineHeight: "19px",
        fontWeight: 600,
        color: "#0058FF",
        "&:hover": {
          color: "#0058FF",

          '& span[data-role="title"]': {
            textDecorationLine: "underline",
          },
        },

        '& span[data-role="dot"]': {
          marginRight: "6px",
        },
      },
    },
  };
});

export default useStyles;
