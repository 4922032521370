import React from "react";
import useStyles from "./styles";

const LoadingTable = () => {
  const styles = useStyles();
  return (
    <div className={styles.loadingContainer}>
      <img
        src="/images/loading_submit.svg"
        className="loading animate-spin"
        width={48}
        height={18}
        alt="loading"
      />
    </div>
  );
};

export default LoadingTable;
