import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
  return {
    grid: {
      width: "100%",
      color: "#FFFFFF",
      display: "grid",
      gridTemplateColumns: "100px 50px 100px",
      gridColumnGap: 40,
      marginBottom: 6,
      background: "#2E2E2E",
      borderRadius: 4,
      padding: "6px 12px",
      textAlign: "right",
      font: "14px/24px ",

      "&:first-child": {
        color: "#AEAEAE",
        font: "normal normal 500 14px/18px ",
        background: "transparent",
        paddingBottom: 0,
      },

      "& :first-child": {
        textAlign: "left",
      },

      [theme.breakpoints.down("xs")]: {
        gridColumnGap: 10,
        gridTemplateColumns: "1fr 0.4fr 0.7fr",
      },
    },
    modalStake: {
      "& .modal-content": {
        backgroundColor: "#09090E",
        border: "1px solid #353539",
        width: 442,
        padding: "32px 0 20px",
        borderRadius: 8,
        color: "#FFFFFF",
      },
      "& .modal-content__head": {
        padding: 0,
        "& .title": {
          margin: "0 auto",
          font: "normal normal 500 22px/28px ",
        },
      },
      "& .modal-content__body": {
        padding: "0 40px",
        margin: "20px 0",

        "& .token-type": {
          display: "flex",
          justifyContent: "space-between",
          fontSize: "16px",
          alignItems: "center",
          marginBottom: "14px",
        },
        "& .token-detail": {
          display: "flex",
          alignItems: "center",
        },
        "& .token-logo": {
          width: "22px",
          height: "22px",
          borderRadius: "28px",
          margin: "0 5px",
        },

        "& .input-group": {
          background: "#222228",
          padding: "0 10px",
          borderRadius: "4px",
        },
        "& .input-group input": {
          cursor: "default",
          padding: "0",
        },
        "& .token-balance": {
          color: "#AEAEAE",
          marginTop: "5px",
        },
        "& .subtitle": {
          marginBottom: "5px",
          fontWeight: 700,
          color: "#FFFFFF",
        },
      },
      "& .modal-content__foot": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        borderTop: "1px solid rgba(114, 114, 114, 0.5)",
        paddingTop: 20,
      },
    },
    notice: {
      font: "normal normal 500 14px/18px ",
      color: "#FFF",
      marginTop: "30px",
    },
    roiDescription: {
      listStyleType: "disc",
      paddingLeft: "12px",
      margin: "20px 0 8px",
      font: "14px/24px ",
    },

    [theme.breakpoints.down("xs")]: {
      modalStake: {
        "& .MuiPaper-root": {
          [theme.breakpoints.down("xs")]: {
            margin: "0 28px",
          },
          "& .modal-content__body": {
            padding: "0 20px 20px",
            marginBottom: 0,
          },
        },
      },
      modalTransaction: {
        "& .modal-content__body": {
          padding: "0",
        },
      },
    },
  };
});

export default useStyles;
