import { Tooltip } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { FC, useState } from "react";
import { isMobile } from "react-device-detect";
import ReactHtmlParser from "react-html-parser";
import {
  CLAIM_TYPE,
  CLAIM_TYPE_DESCRIPTION,
  CLAIM_TYPE_TEXT,
  NETWORK,
  NETWORK_TEXT,
  POOL_IS_PRIVATE,
} from "../../../constants";
import { useCommonStyle } from "../../../styles";
import { showTotalRaisePrice } from "../../../utils/campaign";
import { numberWithCommas } from "../../../utils/formatNumber";
import useStyles from "./styles";

type Props = {
  poolDetails: any;
  currentUserTierLevel: number;
  currencyName: any;
};

const BuyTokenPoolDetails: FC<Props> = ({
  poolDetails,
  currentUserTierLevel,
  currencyName,
}) => {
  const styles = useStyles();
  const commonStyles = useCommonStyle();
  const [showFull, setShowFull] = useState<boolean>(false);

  const loadingPool = !poolDetails;
  const claimType = poolDetails?.claimType ?? CLAIM_TYPE.CLAIM_ON_LAUNCHPAD;
  const getTextColor = () => {
    if (!poolDetails) return "";

    let className: string = "";
    switch (poolDetails.isPrivate) {
      case POOL_IS_PRIVATE.COMMUNITY:
        className = "text-yellow";
        break;
      case POOL_IS_PRIVATE.EVENT:
        className = "text-purple";
        break;
      default:
        className = "text-purple";
        break;
    }
    return className;
  };

  const renderIntroduction = () => {
    const MAX_LENGTH = 210;
    let showButton = false,
      shortText = "",
      description = poolDetails?.description;
    if (!description) return <></>;

    if (isMobile && description.length > MAX_LENGTH) {
      showButton = true;
      shortText = description.slice(0, MAX_LENGTH - 3).trim() + "...";
    }

    return (
      <>
        <div className={styles.titleBot}>PROJECT INTRODUCTION</div>
        <div className={styles.botSection}>
          <div>{ReactHtmlParser(description)}</div>
          {/* Can't show short version, will break the html */}
          {/* <span>{isMobile && !showFull ? shortText : description}</span> */}
          {/* {showButton && (
        <span
          className={`${commonStyles.nnb1418i} ${
            showFull ? "hide" : "view-more"
          }`}
          onClick={() => setShowFull((prev) => !prev)}
        >
          &nbsp;{showFull ? "Hide" : "View more"}
        </span>
      )} */}
        </div>
      </>
    );
  };

  const renderTextLoading = () => {
    return (
      <Skeleton
        className={commonStyles.skeleton}
        variant="text"
        style={{ flex: 1 }}
      />
    );
  };

  const renderTotalRaiseTextLoading = () => {
    return (
      <li className={styles.itemListContent}>
        <span className={styles.nameItemListContent}>Total Raise</span>
        <Skeleton
          className={commonStyles.skeleton}
          variant="text"
          style={{ flex: 1 }}
        />
      </li>
    );
  };

  const renderTokenDetailsLeft = () => {
    return (
      <ul className={styles.listContent}>
        <li className={styles.itemListContent}>
          <span className={styles.nameItemListContent}>Price per token</span>
          {loadingPool ? (
            renderTextLoading()
          ) : (
            <span className={styles.detailItemListContent}>
              {Number(poolDetails?.ethRate) > 0 ? (
                <>
                  {poolDetails?.ethRate} {currencyName} per &nbsp;
                  {poolDetails?.tokenDetails?.symbol}
                </>
              ) : (
                "TBA"
              )}
            </span>
          )}
        </li>
        <li className={styles.itemListContent}>
          <span className={styles.nameItemListContent}>Swap Amount</span>
          {loadingPool ? (
            renderTextLoading()
          ) : (
            <span
              className={styles.detailItemListContent}
              style={{ textTransform: "capitalize" }}
            >
              {Number(poolDetails?.amount) > 0 ? (
                <>
                  {numberWithCommas(poolDetails?.amount.toString())}{" "}
                  {poolDetails?.tokenDetails?.symbol}
                </>
              ) : (
                "TBA"
              )}
            </span>
          )}
        </li>
        {loadingPool
          ? renderTotalRaiseTextLoading()
          : poolDetails?.website && (
              <li className={styles.itemListContent}>
                <span className={styles.nameItemListContent}>Total Raise</span>
                <span
                  className={`${
                    styles.detailItemListContent
                  } ${getTextColor()}`}
                >
                  {Number(poolDetails?.ethRate) > 0
                    ? showTotalRaisePrice(poolDetails)
                    : "TBA"}
                </span>
              </li>
            )}
        <li className={styles.itemListContent}>
          <span className={styles.nameItemListContent}>Claim Type</span>
          {loadingPool ? (
            renderTextLoading()
          ) : (
            <Tooltip
              classes={{ tooltip: styles.tooltip }}
              title={CLAIM_TYPE_DESCRIPTION[claimType]}
              arrow
              placement="top"
            >
              <span className={styles.detailItemListContent}>
                {CLAIM_TYPE_TEXT[claimType]}
              </span>
            </Tooltip>
          )}
        </li>
      </ul>
    );
  };

  const renderTokenDetailsRight = () => {
    return (
      <ul className={styles.listContent}>
        <li className={styles.itemListContent}>
          <span className={styles.nameItemListContent}>Accepted Currency</span>
          {loadingPool ? (
            renderTextLoading()
          ) : (
            <span className={styles.detailItemListContent}>{currencyName}</span>
          )}
        </li>
        <li className={styles.itemListContent}>
          <span className={styles.nameItemListContent}>Network</span>
          {loadingPool ? (
            renderTextLoading()
          ) : (
            <div className={styles.networkDisplay}>
              <img
                src={`/images/chain/${(
                  poolDetails?.networkAvailable ?? NETWORK.ETHEREUM
                )?.toLowerCase()}.png`}
                alt=""
              />
              <span className="total">
                {
                  NETWORK_TEXT[
                    poolDetails?.networkAvailable ?? NETWORK.ETHEREUM
                  ]
                }
              </span>
            </div>
          )}
        </li>

        {poolDetails?.airdropNetwork &&
        poolDetails?.airdropNetwork !== "none" ? (
          <li className={styles.itemListContent}>
            <span className={styles.nameItemListContent}>
              Token Airdrop Network
            </span>
            {loadingPool ? (
              renderTextLoading()
            ) : (
              <div className={styles.networkDisplay}>
                <img
                  src={`/images/chain/${(
                    poolDetails?.airdropNetwork ?? NETWORK.ETHEREUM
                  )?.toLowerCase()}.png`}
                  alt=""
                />
                <span className="total">
                  {
                    NETWORK_TEXT[
                      poolDetails?.airdropNetwork ?? NETWORK.ETHEREUM
                    ]
                  }
                </span>
              </div>
            )}
          </li>
        ) : (
          <li className={styles.itemListContent}>
            <span className={styles.nameItemListContent}>
              Token Claim Network
            </span>
            {loadingPool ? (
              renderTextLoading()
            ) : (
              <div className={styles.networkDisplay}>
                <img
                  src={`/images/chain/${(
                    poolDetails?.networkClaim ??
                    poolDetails?.networkAvailable ??
                    NETWORK.ETHEREUM
                  )?.toLowerCase()}.png`}
                  alt=""
                />
                <span className="total">
                  {
                    NETWORK_TEXT[
                      poolDetails?.networkClaim ??
                        poolDetails?.networkAvailable ??
                        NETWORK.ETHEREUM
                    ]
                  }
                </span>
              </div>
            )}
          </li>
        )}
        <li className={styles.itemListContent}>
          <span className={styles.nameItemListContent}>Vesting Schedule</span>
          {loadingPool ? (
            renderTextLoading()
          ) : (
            <span
              className={
                styles.detailItemListContent + " text-schedule text-vesting"
              }
            >
              {poolDetails?.claimPolicy ?? "TBA"}
            </span>
          )}
        </li>
      </ul>
    );
  };

  return (
    <section className={styles.sectionBuyTokenPoolDetails}>
      <div className={styles.midSection}>
        {renderTokenDetailsLeft()}

        {renderTokenDetailsRight()}
      </div>

      {renderIntroduction()}
    </section>
  );
};

export default BuyTokenPoolDetails;
