import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
  return {
    cardActive: {
      backgroundColor: "#ECF5FF",
      borderRadius: 20,
      overflow: "hidden",
    },
    sectionTop: {
      alignItems: "center",
    },
    cardActiveTop: {
      display: "grid",
      gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
      "& img": {
        width: "100%",
        height: "360px",
        objectFit: "cover",
        [theme.breakpoints.down("md")]: {
          height: 260,
        },
      },
      [theme.breakpoints.down("sm")]: {
        gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
      },
    },
    sectionStatus: {
      display: "grid",
      gap: 24,
      padding: "0 40px",
      gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
      "& div": {
        "& h4": {
          fontSize: "32px",
          lineHeight: "32px",
          fontWeight: 600,
          color: "#0058FF",
        },
        "& p": {
          fontSize: "20px",
          lineHeight: "24px",
          color: "#000B60",
        },
      },
    },
    cardActiveBot: {
      display: "grid",
      gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
      alignItems: "end",
      padding: "23px 40px 32px",
      "& img": {
        width: "100%",
        height: "353px",
        objectFit: "cover",
      },
      "& div": {
        "& h4": {
          marginBottom: "11px",
          fontSize: "32px",
          lineHeight: "32px",
          fontWeight: 600,
          color: "#0058FF",
        },
        "& p": {
          fontSize: "16px",
          lineHeight: "19px",
          color: "#000B60",
        },
      },
      "& div:last-child": {
        paddingLeft: "40px",
      },
      "& button": {
        width: "100%",
        height: "43px",
        padding: "12px 20px 16px",
        background: "#0058FF",
        border: "none",
        borderRadius: 60,
        color: "#FFFFFF",
      },
      [theme.breakpoints.down("sm")]: {
        gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
      },
    },
  };
});

export default useStyles;
