import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
  return {
    link: {
      display: "block",
      width: "100%",
      "& img": {
        width: 32,
        height: 32,
      },
    },
    loader: {
      height: 300,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    poolsContainer: {
      maxWidth: 1320,
      minHeight: "calc(100vh - 400px)",
      margin: "0 auto",
      padding: "40px 20px",
      "& .MuiPaper-elevation1": {
        boxShadow: "none",
      },
      [theme.breakpoints.down("sm")]: {
        padding: "20px",
      },
    },
    listPoolHeader: {
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        flexWrap: "wrap",
      },
    },
    listPoolTitle: {
      fontSize: "48px",
      lineHeight: "48px",
      fontWeight: 500,
      color: "#000B60",
      [theme.breakpoints.down("md")]: {
        fontSize: "40px",
        lineHeight: "40px",
      },
    },
    btnCalender: {
      width: 154,
      height: 44,
      font: "normal normal 500 14px/16px ",
      background: "#0058FF",
      borderRadius: 60,
      border: "none",
      marginLeft: "auto",
      color: "white",
      outline: "none",
      display: "flex",
      alignItems: "center",
      textAlign: "center",
      justifyContent: "center",
      cursor: "pointer",
      fontWeight: 600,
      "& img": {
        width: 14,
        height: 14,
        marginRight: 4,
      },
      "& span": {
        paddingBottom: "4px",
      },
      "&:hover": {
        opacity: 1,
      },
      [theme.breakpoints.down("sm")]: {
        width: 154,
        float: "right",
        height: 40,
        padding: "0px 16px",
      },
    },
    tableMobileContainer: {
      paddingTop: 20,
    },
    tableLoading: {
      height: 150,
      display: "flex",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
      background: "transparent",
    },
    searchGroup: {
      position: "relative",
      maxWidth: 320,
      height: 44,
      width: "70%",
      marginLeft: 6,
      border: "none",
      "& input": {
        background: "#F2F6FE",
        border: "none",
        boxSizing: "border-box",
        borderRadius: "60px",
        outline: "none",
        width: "100%",
        height: 44,
        fontSize: "16px",
        lineHeight: "19px",
        color: "#000B60",
        padding: "0 40px 0 14px",
      },
      "& img": {
        position: "absolute",
        right: "14px",
        transform: "translateY(-50%)",
        top: "50%",
      },
      [theme.breakpoints.down("sm")]: {
        marginLeft: 0,
        marginTop: 24,
        maxWidth: "100%",
        width: "100%",
      },
    },

    table: {
      "& .MuiTableBody-root td": {
        fontSize: "16px",
        lineHeight: "19px",
        fontWeight: 600,
      },
      borderCollapse: "separate !important" as any,
      borderSpacing: "0 6px !important" as any,
    },

    tableContainer: {
      width: "100%",
      background: "transparent",
      marginTop: 16,

      "& .loading": {
        display: "flex",
        align: "center",
        alignItems: "center",
        justifyContent: "center",
      },
      "& th": {
        borderTop: "1px solid #353539",
        borderBottom: "1px solid #353539",
        fontSize: "16px",
        lineHeight: "19px",
        fontWeight: 600,
        color: "#000B60",
        background: "#ECF5FF",
        padding: "10px 10px 12px",
        border: 0,
        textAlign: "right",
        "&:first-child": {
          textAlign: "left",
        },
        [theme?.breakpoints?.down("sm")]: {
          fontSize: 14,
          lineHeight: "20px",
        },
      },
      "& th:first-child": {
        paddingLeft: "20px",
        borderTopLeftRadius: 2,
        borderBottomLeftRadius: 2,
      },
      "& th:last-child": {
        paddingRight: "20px",
        borderTopRightRadius: 2,
        borderBottomRightRadius: 2,
      },
      "& .MuiTableRow-head th, & td": {
        border: 0,
        background: "transparent",
      },
      "& .MuiTableRow-head": {
        fontSize: "16px",
        lineHeight: "19px",
        fontWeight: 600,
        color: "#000B60",
      },
      "& .MuiTableBody-root th": {
        fontSize: "18px",
        lineHeight: "21px",
        fontWeight: 600,
        borderTop: "1px solid #A5CCFF",
        borderBottom: "1px solid #A5CCFF",
        "&:first-child": {
          borderLeft: "1px solid #A5CCFF",
          borderTopLeftRadius: 12,
          borderBottomLeftRadius: 12,
        },
        "&:last-child": {
          borderRight: "1px solid #A5CCFF",
          borderTopRightRadius: 12,
          borderBottomRightRadius: 12,
        },
      },
      "& .MuiTableRow-root:hover": {
        background: "none",
      },
    },

    tableHeaderWrapper: {
      "& th": {
        font: "normal normal 500 14px/18px ",
        color: "#000B60",
        padding: "0 10px",
      },
      "& tr": {
        background: "transparent",
      },
    },

    pagination: {
      display: "flex",
      justifyContent: "center",
      marginTop: "18px !important",
      "& *": {
        color: "#000B60",
      },
      "& .MuiPaginationItem-page.Mui-disabled": {
        cursor: "not-allowed",
        pointerEvents: "auto",
      },
      "& li": {
        "& .pagination-item": {
          font: "normal normal 500 14px/18px ",
          backgroundColor: "transparent !important",
          color: "#000B60",
          fontSize: "16px",
          lineHeight: "19px",
          fontWeight: 600,
          "&.Mui-selected": {
            color: "#0058FF",
          },
        },
        "&:first-child .pagination-item": {
          backgroundColor: "#F2F6FE !important",
          borderRadius: 6,
          color: "#000B60",
          "& path": {
            fill: "#000B60",
          },
        },
        "&:last-child .pagination-item": {
          backgroundColor: "#F2F6FE !important",
          borderRadius: 6,
          color: "#000B60",
          "& path": {
            fill: "#000B60",
          },
        },
        "& .MuiPaginationItem-page.Mui-disabled": {
          opacity: 1,
        },
      },
    },
  };
});

export default useStyles;
