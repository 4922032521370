import { useState, useCallback } from "react";
import { ethers } from "ethers";
import BigNumber from "bignumber.js";

import { getETHBalance, getContractReadInstance } from "../../../services/web3";
import ERC20_ABI from "../../../abi/Erc20.json";

const envLocal = localStorage?.getItem("env");
const env = envLocal ? JSON?.parse(envLocal) : {};

const usePoolBalance = (
  currency: string,
  poolAddress: string | undefined,
  networkAvaiable: string | undefined
) => {
  const [poolBalance, setPoolBalance] = useState<number>();
  const [loadingPoolBalance, setLoadingPoolBalance] = useState<boolean>(false);

  const retrievePoolBalance = useCallback(async () => {
    try {
      if (poolAddress && currency && ethers.utils.isAddress(poolAddress)) {
        setLoadingPoolBalance(true);
        if (currency == "eth") {
          const ethBalance = await getETHBalance(poolAddress);

          setPoolBalance(new BigNumber(ethBalance).toNumber());
          return ethBalance;
        } else {
          const currencyAddress: any = {
            eth: {
              usdt: env.REACT_APP_USDT_SMART_CONTRACT,
              usdc: env.REACT_APP_USDC_SMART_CONTRACT,
            },
            bsc: {
              usdt: env.REACT_APP_USDT_BSC_SMART_CONTRACT,
              busd: env.REACT_APP_BUSD_BSC_SMART_CONTRACT,
              usdc: env.REACT_APP_USDC_BSC_SMART_CONTRACT,
            },
            base: {
              usdc: env.REACT_APP_USDC_BASE_SMART_CONTRACT,
            },
            polygon: {
              usdt: env.REACT_APP_USDT_POLYGON_SMART_CONTRACT,
              usdc: env.REACT_APP_USDC_POLYGON_SMART_CONTRACT,
            },
            avalanche: {
              usdt: env.REACT_APP_USDT_AVALANCHE_SMART_CONTRACT,
            },
            arbitrum: {
              usdt: env.REACT_APP_USDT_ARBITRUM_SMART_CONTRACT,
            },
          };

          const contract = getContractReadInstance(
            ERC20_ABI,
            currencyAddress[networkAvaiable || "eth"][currency],
            networkAvaiable || "eth"
          );

          const [balance, decimals] = await Promise.all([
            contract?.methods.balanceOf(poolAddress).call(),
            contract?.methods.decimals().call(),
          ]);
          setPoolBalance(
            new BigNumber(balance).div(Math.pow(10, decimals)).toNumber()
          );
        }

        setLoadingPoolBalance(false);
      }
    } catch (err) {
      console.error(err);
    }
  }, [currency, poolAddress, networkAvaiable]);

  return {
    loadingPoolBalance,
    poolBalance,
    retrievePoolBalance,
  };
};

export default usePoolBalance;
