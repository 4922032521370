import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import useCommonStyle from "../../../styles/CommonStyle";
import useStyles from "./style";
import useStakingStyles from "../Pool/style";

const closeIcon = "/images/icons/close.svg";

const ModalStake = (props: any) => {
  const styles = useStyles();
  const stakingStyles = useStakingStyles();
  const commonStyles = useCommonStyle();

  const { open, onConfirm, onClose, text } = props;

  return (
    <Dialog
      open={open}
      keepMounted
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      className={commonStyles.modal + " " + styles.modalConfirm}
    >
      <div className="modal-content">
        <DialogTitle
          id="alert-dialog-slide-title"
          className="modal-content__head"
        >
          <img src={closeIcon} alt="" onClick={onClose} className="btn-close" />
        </DialogTitle>
        <DialogContent className="modal-content__body">
          <div style={{ textAlign: "center" }}>{text}</div>
        </DialogContent>
        <DialogActions
          className="modal-content__foot"
          style={{ border: "none" }}
        >
          <button
            className={`${stakingStyles.btn} ${styles.btnYes}`}
            onClick={onConfirm}
          >
            Yes, Sure
          </button>

          <button
            className={`${stakingStyles.btn} ${styles.btnCancel}`}
            onClick={onClose}
          >
            Cancel
          </button>
        </DialogActions>
        {/* {transactionHashes[0].isApprove && <p className={styles.notice}>Please be patient and no need to approve again, you can check the transaction status on Etherscan.</p>} */}
      </div>
    </Dialog>
  );
};

export default ModalStake;
