import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  return {
    dialog: {
      "& .MuiPaper-root": {
        minWidth: 480,
        padding: 40,
        maxWidth: 400,
        textAlign: "center",
        background: "#FFFFFF",
        borderRadius: "28px",
        border: "none",
      },
      "& h6": {
        width: "100%",
      },
      "& .MuiIconButton-root": {
        width: "28px",
        height: "28px",
        top: "12px",
        right: "12px",
        background: "#F2F6FE",
        "& path": {
          fill: "#0058FF",
        },
      },
    },
    dialogTitle: {
      "& .MuiTypography-h6": {
        font: "normal normal 500 22px/28px ",
        paddingBottom: 16,
      },

      "& .MuiSvgIcon-root": {
        fontSize: "1rem",
      },
    },
    dialogContent: {
      padding: "0 !important" as any,
      position: "relative",
    },
    disconnectTitle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    accountTitle: {
      display: "flex",
      fontSize: "24px",
      lineHeight: "24px",
      color: "#000B60",
      fontWeight: 600,
      alignItems: "center",
      "& div": {
        paddingBottom: 4,
      },
    },
    mainDialog: {
      alignItems: "center",
      border: "none",
      borderRadius: 4,
    },
    mainContent: {
      display: "flex",
      gap: "40px",
    },
    disconnectIcon: {
      widht: 12,
      height: 12,
      marginRight: 6,
    },
    disconnectButton: {
      display: "flex",
      padding: "11px 32px 12px",
      alignItems: "center",
      cursor: "pointer",
      background: "#000B60",
      borderRadius: "60px",
      "& span": {
        color: "#FFFFFF",
        fontSize: "14px",
        lineHeight: "16px",
      },
    },
    accountDetails: {
      width: "100%",
      color: "#fff",
      display: "flex",
      flexDirection: "column",
      padding: "12px 8px 0",
    },
    accountItem: {
      display: "flex",
      minHeight: "33px",
      color: "#000B60",
      justifyContent: "space-between",
      "& div:first-child": {
        fontSize: "16px",
        lineHeight: "19px",
      },
      "& div:last-child": {
        display: "flex",
        gap: "4px",
        alignItems: "center",
        paddingBottom: "4px",
        fontSize: "18px",
        lineHeight: "21px",
        fontWeight: 600,
        "& p": {
          paddingBottom: "4px",
          fontSize: "18px",
          lineHeight: "21px",
          fontWeight: 600,
        },
      },
    },
    walletNameIcon: {
      width: 80,
      height: 80,
      margin: "0 auto",
    },
    walletAddress: {
      fontSize: "18px",
      lineHeight: "21px",
      fontWeight: 600,
      color: "#000B60",
    },
    accountDetailCta: {
      textAlign: "right",
      marginTop: 20,
    },
    netWorkIcon: {
      width: 16,
      height: 16,
    },
    accountDetailCtaIcon: {
      marginRight: 8,
      display: "inline-block",
    },
    accountDetailDisconnect: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "#59f",
      cursor: "pointer",
      font: "normal normal bold 14px/18px ",
    },

    [theme.breakpoints.down("xs")]: {
      dialog: {
        "& .MuiPaper-root": {
          padding: "40px 20px 32px",
          minWidth: "unset",
          width: "calc(100vw - 56px)",
          margin: 28,
          borderRadius: 15,
        },
      },
      walletNameIcon: {
        marginRight: 8,
      },
    },
  };
});

export default useStyles;
