import React, { useState, useEffect } from "react";
import useStyles from "./style";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { ACCEPT_CURRENCY } from "../../../constants";
import { numberWithCommas } from "../../../utils/formatNumber";
import useCommonStyle from "../../../styles/CommonStyle";
import { getIconCurrencyUsdt } from "../../../utils/usdt";
import { PoolStatus } from "../../../utils/getPoolStatus";
import {
  getAccessPoolText,
  getProgressWithPools,
  getTokenSold,
} from "../../../utils/campaign";
import { TableCell, TableRow } from "@material-ui/core";

const Pool = (props: any): JSX.Element => {
  const styles = useStyles();
  const commonStyle = useCommonStyle();
  const history = useHistory();
  const [timeLeft, setTimeLeft] = useState("");
  const [progress, setProgress] = useState(0);

  const { pool, loading } = props;

  useEffect(() => {
    const getTokenSoldByPool = async () => {
      let resTokenSold = "0";
      if (pool?.is_deploy) {
        const tokenSold = await getTokenSold(pool);
        resTokenSold = tokenSold;
      }

      let { progress: progressPercent } = getProgressWithPools({
        ...pool,
        tokenSold: resTokenSold,
      });
      setProgress(parseFloat(progressPercent));
    };

    getTokenSoldByPool();
    const intervalProgress = setInterval(() => {
      getTokenSoldByPool();
    }, 20000);

    return () => {
      intervalProgress && clearInterval(intervalProgress);
    };
  }, [pool]);

  useEffect(() => {
    const currentTime = moment().unix();
    var diffTime = parseInt(pool?.start_time) - currentTime;
    let intervalCount: any;
    if (diffTime > 0) {
      let timeLeftToStart = diffTime * 1000;
      const interval = 1000;

      intervalCount = setInterval(() => {
        timeLeftToStart -= interval;
        const timeLeftDuration = moment.duration(
          timeLeftToStart,
          "milliseconds"
        );
        let timeLeftString = "";
        if (timeLeftToStart >= 86400000) {
          timeLeftString = "In " + timeLeftDuration.days() + " days";
        } else {
          timeLeftString =
            "In " +
            timeLeftDuration.hours() +
            ":" +
            timeLeftDuration.minutes() +
            ":" +
            timeLeftDuration.seconds();
        }
        setTimeLeft(timeLeftString);
      }, interval);
    }

    return () => clearInterval(intervalCount);
  }, []);

  const { currencyName } = getIconCurrencyUsdt({
    purchasableCurrency: pool?.accept_currency,
    networkAvailable: pool?.network_available,
  });

  const handleClickRow = () => {
    history.push(`/buy-token/${pool?.id}`);
  };

  const renderStatusColumn = () => {
    switch (pool?.status) {
      case PoolStatus.TBA:
        return <span className={`${PoolStatus.TBA}`}>{PoolStatus.TBA}</span>;
      case PoolStatus.Upcoming:
        return (
          <span className={`${PoolStatus.Upcoming}`}>
            {PoolStatus.Upcoming}
          </span>
        );
      case PoolStatus.Progress:
        return (
          <span className={`${PoolStatus.Progress}`}>
            {PoolStatus.Progress}
          </span>
        );
      case PoolStatus.Filled:
        return (
          <span className={`${PoolStatus.Filled}`}>{PoolStatus.Filled}</span>
        );
      case PoolStatus.Closed:
        return (
          <span className={`${PoolStatus.Closed}`}>{PoolStatus.Closed}</span>
        );
      case PoolStatus.Claimable:
        return (
          <span className={`${PoolStatus.Claimable}`}>
            {PoolStatus.Claimable}
          </span>
        );
      default:
        return <span className={`none`}> {pool?.status}</span>;
    }
  };

  return (
    <TableRow className={styles.tableRow} onClick={handleClickRow}>
      <TableCell component="th" scope="row">
        <div className="pool-name">
          <img src={pool?.token_images} alt="" />
          <div>
            <p className={`${styles.title} ${loading && "placeholder-ske"}`}>
              {pool?.title}
            </p>
            <p className={`token-symbol ${loading && "placeholder-ske"}`}>
              {pool?.symbol}
            </p>
          </div>
        </div>
      </TableCell>
      <TableCell component="th" scope="row">
        <div className={`${styles.chain} ${loading && "placeholder-ske"}`}>
          <img
            src={`/images/chain/${pool?.network_available?.toLowerCase()}.png`}
            width={14}
            height={14}
            alt="logo"
          />
          <p>{pool?.network_available}</p>
        </div>
      </TableCell>
      <TableCell component="th" scope="row">
        <span className={`${loading && "placeholder-ske"}`}>
          {pool?.accept_currency === ACCEPT_CURRENCY.ETH && (
            <>{`${numberWithCommas(pool?.price_usdt, 4)} USD`}</>
          )}
          {pool?.accept_currency !== ACCEPT_CURRENCY.ETH && (
            <>
              {numberWithCommas(pool?.token_conversion_rate, 4)} {currencyName}
            </>
          )}
        </span>
      </TableCell>
      <TableCell component="th" scope="row">
        <span className={`${loading && "placeholder-ske"}`}>
          {pool?.current_price
            ? pool?.current_price.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })
            : "Updating..."}
        </span>
      </TableCell>
      <TableCell component="th" scope="row">
        <span className={`${loading && "placeholder-ske"}`}>
          {pool?.ath_roi ? `${pool?.ath_roi.toFixed(2)}x` : "Updating..."}
        </span>
      </TableCell>
      <TableCell component="th" scope="row" className={styles.relationshipType}>
        <span className={`${loading && "placeholder-ske"}`}>
          {pool?.relationship_type ? pool?.relationship_type : "None"}
        </span>
      </TableCell>
      <TableCell component="th" scope="row">
        {pool?.market_maker ? (
          <span className={`${loading && "placeholder-ske"}`}>
            {pool?.market_maker_icon && (
              <img
                className="mm-icon"
                src={pool?.market_maker_icon}
                alt="icon"
              />
            )}
            {pool?.market_maker}
          </span>
        ) : (
          "None"
        )}
      </TableCell>
      <TableCell component="th" scope="row" className={styles.poolStatus}>
        <span className={`${loading && "placeholder-ske"}`}>
          {renderStatusColumn()}
        </span>
      </TableCell>
    </TableRow>
  );
};

export default Pool;
