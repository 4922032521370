import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => {
  return {
    main: {
      position: "relative",
      background: "#ffffff",
      overflow: "hidden",
    },
    container: {
      position: "relative",
      maxWidth: "1416px",
      padding: "0 20px",
      margin: "0 auto",
    },
    heroImg: {
      width: "100%",
      height: "auto",
      minHeight: "500px",
      borderRadius: "40px",
      objectFit: "cover",
      [theme.breakpoints.down("sm")]: {
        minHeight: "100px",
      },
    },
    filter: {
      position: "absolute",
      display: "flex",
      width: "100%",
      height: "100%",
      top: "0",
      left: "0",
      padding: "0 20px",
      flexDirection: "column",
      zIndex: 10,
      [theme.breakpoints.down("sm")]: {
        position: "relative",
        padding: "0",
      },
    },
    title: {
      flex: 1,
      width: "100%",
      maxWidth: "1216px",
      margin: "0 auto",
      [theme.breakpoints.down("md")]: {
        padding: "0 20px",
      },
      [theme.breakpoints.down("sm")]: {
        padding: "0",
      },
    },
    titleHead: {
      "& h1": {
        maxWidth: "700px",
        padding: "80px 0 0 0",
        fontSize: "68px",
        lineHeight: "61px",
        fontWeight: 600,
        color: "#000000",
        "& div": {
          color: "#0055D6",
        },
        [theme.breakpoints.down("sm")]: {
          maxWidth: "100%",
          textAlign: "center",
          "& div": {
            margin: "0 auto",
            textAlign: "center",
            display: "block",
          },
        },
      },
      "& div": {
        display: "flex",
        gap: "8px",
        marginTop: "35px",
      },
      [theme.breakpoints.down("md")]: {
        "& h1": {
          padding: "20px 0 0",
          fontSize: "50px",
          lineHeight: "48px",
        },
        "& div": {
          marginTop: "25px",
        },
      },
      [theme.breakpoints.down("sm")]: {
        "& h1": {
          fontSize: "34px",
          lineHeight: "30px",
        },
        "& div": {
          marginTop: "15px",
        },
        "& .btn-grid": {
          display: "flex",
          gap: 16,
          justifyContent: "center",
          flexWrap: "wrap",
        },
      },
    },
    line: {
      width: "100%",
      maxWidth: "558px",
      height: "1px",
      marginTop: "49px",
      background: "#7FB6FF",
      [theme.breakpoints.down("md")]: {
        marginTop: "20px",
      },
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100%",
      },
    },
    titleNumber: {
      display: "grid",
      maxWidth: "600px",
      gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
      paddingTop: "40px",
      textAlign: "center",
      "& h6": {
        fontSize: "32px",
        lineHeight: "32px",
        fontWeight: 700,
        color: "#0058FF",
      },
      "& p": {
        paddingTop: "2px",
        fontSize: "16px",
        lineHeight: "19px",
        fontWeight: 400,
        color: "#0058FF",
      },
      [theme.breakpoints.down("md")]: {
        "& h6": {
          fontSize: "28px",
          lineHeight: "28px",
        },
        "& p": {
          fontSize: "16px",
          lineHeight: "19px",
        },
      },
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100%",
        gap: "16px",
        paddingTop: "16px",
        gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
        "& h6": {
          fontSize: "24px",
          lineHeight: "24px",
        },
        "& p": {
          fontSize: "14px",
          lineHeight: "16px",
        },
      },
      [theme.breakpoints.down("xs")]: {
        maxWidth: "100%",
        gap: "16px",
        gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
        "& h6": {
          fontSize: "24px",
          lineHeight: "24px",
        },
        "& p": {
          fontSize: "14px",
          lineHeight: "16px",
        },
      },
    },
    btnApply: {
      height: 44,
      padding: "13px 48px 15px",
      background: "#0055D6",
      fontSize: "14px",
      lineHeight: "16px",
      color: "#FFFFFF",
      border: "none",
      borderRadius: "45px",
      fontWeight: 600,
      "& div": {
        margin: "0",
        paddingBottom: "4px",
      },
      [theme.breakpoints.down("md")]: {
        padding: "10px 30px 12px",
      },
      [theme.breakpoints.down("sm")]: {
        width: 130,
        padding: "6px 20px 8px",
      },
    },
    btnDoc: {
      width: 134,
      height: 44,
      padding: "13px 32px 15px",
      fontSize: "14px",
      lineHeight: "16px",
      background: "transparent",
      color: "#0058FF",
      border: "1px solid #0058FF",
      borderRadius: "45px",
      fontWeight: 600,
      "& div": {
        margin: "0",
        paddingBottom: "4px",
      },
      [theme.breakpoints.down("md")]: {
        padding: "10px 30px 12px",
      },
      [theme.breakpoints.down("sm")]: {
        width: 130,
        padding: "6px 20px 8px",
      },
    },
    btnInform: {
      width: 134,
      display: "flex",
      alignItems: "center",
      height: 44,
      padding: "13px 10px 15px",
      fontSize: "14px",
      lineHeight: "16px",
      background: "transparent",
      color: "#0058FF",
      border: "1px solid #0058FF",
      borderRadius: "45px",
      fontWeight: 600,
      justifyContent: "center",
      "& div": {
        margin: "0",
        paddingBottom: "4px",
      },
      [theme.breakpoints.down("md")]: {
        padding: "10px 10px 12px",
      },
      [theme.breakpoints.down("sm")]: {
        width: 130,
        padding: "6px 10px 8px",
      },
    },
    learnMenu: {
      "& .Component-root-331 .MuiPaper-root": {
        borderRadius: "12px !important",
        overflow: "hidden",
        boxShadow: "0px 0px 20px 0px #0055D60A !important",
      },
      "& .MuiMenu-paper": {
        background: "transparent !important",
        border: "none !important",
        boxShadow: "0px 0px 20px 0px #0055D60A !important",
        borderRadius: "12px !important",
      },
      "& ul": {
        backgroundColor: "#FFFFFF",
        borderRadius: "12px",
        overflow: "hidden",
        "& li": {
          padding: "7px 16px !important",
          borderBottom: "none !important",
          color: "#000000",
        },
        "& li:hover": {
          background: "#0055D6 !important",
          color: "#ffffff",
        },
      },
    },
    contentBot: {
      display: "grid",
      gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
      height: "90px",
      [theme.breakpoints.down("sm")]: {
        height: "fit-content",
        gap: "4px",
        gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
        padding: "20px 0",
      },
    },
    contentBotSlide: {
      position: "relative",
      "&:before": {
        content: "''",
        position: "absolute",
        width: "30px",
        height: "30px",
        bottom: "4px",
        right: "-40px",
        background: "transparent",
        zIndex: "2",
        border: "none",
        transform: "skew(20deg)",
        borderBottomLeftRadius: "40px",
        boxShadow: "-8px 9px 0 7px #ffffff",
      },
      [theme.breakpoints.down("sm")]: {
        "&:before": {
          display: "none",
        },
      },
    },
    slide: {
      position: "relative",
      width: "100%",
      height: "100%",
      padding: "0 20px 0 0",
      zIndex: 1,
      color: "#000000",
      background: "transparent",
      "& img": {
        height: "48px",
      },
      "&:before": {
        content: "''",
        position: "absolute",
        width: "40px",
        height: "40px",
        top: "-40px",
        left: "0",
        background: "transparent",
        zIndex: "0",
        border: "none",
        borderBottomLeftRadius: "40px",
        boxShadow: "-8px 9px 0 7px #ffffff",
      },
      [theme.breakpoints.down("sm")]: {
        padding: "0",
        "&:before": {
          display: "none",
        },
      },
    },
    slideChild: {
      position: "relative",
      display: "flex",
      height: "100%",
      padding: "0 40px",
      fontSize: "18px",
      lineHeight: "21px",
      alignItems: "center",
      overflow: "hidden",
      "& div": {
        display: "flex",
        width: "fit-content",
        height: "100%",
        flexWrap: "nowrap",
        alignItems: "center",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "16px",
        lineHeight: "19px",
        padding: "0 20px",
      },
    },
    filterSkew: {
      position: "absolute",
      width: "105%",
      height: "100%",
      top: "0",
      right: "0",
      background: "#ffffff",
      transform: "skew(30deg)",
      borderRadius: "0 30px 0 0",
      zIndex: 0,
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    contentBotSupport: {
      display: "flex",
      gap: "9px",
      alignItems: "center",
      justifyContent: "center",
      "& .launchpad-support": {
        display: "flex",
        gap: "9px",
        alignItems: "center",
        justifyContent: "center",
        "& div": {
          display: "flex",
          gap: "4px",
          padding: "8px 14px",
          background: "#ffffff",
          alignsItems: "center",
          borderRadius: "60px",
        },
        "& span": {
          fontSize: "16px",
          lineHeight: "16px",
          fontWeight: 600,
          color: "#000B60",
        },
      },
      "& p": {
        paddingRight: "5px",
        fontSize: "20px",
        lineHeight: "24px",
        fontWeight: 600,
        color: "#020664",
      },
      [theme.breakpoints.down("md")]: {
        flexWrap: "wrap",
        gap: "2px",
        alignItems: "start",
        "& p": {
          padding: "8px 0 4px",
        },
      },
    },
  };
});

export default useStyles;
