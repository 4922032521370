import { useMemo } from "react";
import { showTotalRaisePrice } from "../../../utils/campaign";
import useStyles from "./styles";
import { NETWORK, NETWORK_TEXT } from "../../../constants";
import { Link } from "react-router-dom";

interface Props {
  pool: any;
  isUpcoming?: boolean;
}

const CardActive = ({ pool, isUpcoming }: Props) => {
  const styles = useStyles();

  const idoPrice = useMemo(() => {
    const price = +pool?.price_usdt;
    return price || 0;
  }, [pool]);

  return (
    <div className={styles.cardActive}>
      <div className={`${styles.cardActiveTop} ${styles.sectionTop}`}>
        <img
          src={isUpcoming ? "/images/dashboard/upcoming.svg" : pool?.banner}
          alt="banner"
        />
        <div className={styles.sectionStatus}>
          <div>
            <h4>{!isUpcoming ? "LIVE" : "TBA"}</h4>
            <p>Type</p>
          </div>
          <div>
            <h4>
              {isUpcoming
                ? "TBA"
                : pool.isTBAPool
                ? "-"
                : Number(pool?.token_conversion_rate) > 0
                ? showTotalRaisePrice(pool)
                : "TBA"}
            </h4>
            <p>Total raise</p>
          </div>
          <div>
            <h4>${idoPrice}</h4>
            <p>IDO price</p>
          </div>
          <div>
            <h4>
              {NETWORK_TEXT?.[pool?.network_available ?? NETWORK.ETHEREUM]}
            </h4>
            <p>Network</p>
          </div>
        </div>
      </div>
      <div className={styles.cardActiveBot}>
        <div>
          <h4>{pool?.title}</h4>
          <p>{pool?.description}</p>
        </div>
        <div>
          <Link to={`/buy-token/${pool?.id}`}>
            <button>View project</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CardActive;
