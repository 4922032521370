import { withWidth } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import useStyles from "./style";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
      paddingTop: 0,
      borderRadius: 50,
      display: "flex",
      justifyContent: "space-between",
    },
    svgIcon: {
      fontSize: 5,
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  customClass: string;
}

export interface ComponentProps {
  opened: boolean;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, customClass, ...other } = props;

  const customStyles = {
    color: "white",
    padding: 0,
  };

  return (
    <MuiDialogTitle
      disableTypography
      className={`${classes.root} ${customClass}`}
      {...other}
      style={customStyles}
    >
      <Typography variant="h6">{children}</Typography>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
    color: "#fff",
  },
}))(MuiDialogContent);

const SignRequiredModal: React.FC<ComponentProps> = (props: any) => {
  const styles = useStyles();
  const { opened } = props;

  return (
    <Dialog open={opened} className={styles.dialog} maxWidth="xs">
      <img src="/images/icons/digital_signature.svg" alt="" />
      <DialogTitle
        id="customized-dialog-title"
        customClass={styles.dialogTitle}
      >
        Signature Required
      </DialogTitle>
      <div className={styles.dialogContent}>
        Please sign on your wallet to confirm.
      </div>
    </Dialog>
  );
};

export default withWidth()(SignRequiredModal);
