import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  return {
    container: {
      padding: "22px 0 16px",
      background: "linear-gradient(93.49deg, #0559D7 1.89%, #96C1FC 84.98%)",
      [theme.breakpoints.down("sm")]: {
        padding: "40px 20px",
      },
    },
    footerTop: {
      display: "flex",
      padding: "0 78px",
      alignItems: "center",
      justifyContent: "space-between",
      [theme.breakpoints.down("sm")]: {
        padding: 0,
        flexWrap: "wrap",
        justifyContent: "center",
        gap: "20px 80px",
      },
    },
    footerTopLeft: {
      display: "flex",
      gap: "39px",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        "& img": {
          width: "auto",
          height: "40px",
        },
      },
      [theme.breakpoints.down("xs")]: {
        gap: "20px",
        flexWrap: "wrap",
      },
    },
    learnMenu: {
      "& .Component-root-331 .MuiPaper-root": {
        borderRadius: "12px !important",
        overflow: "hidden",
        boxShadow: "0px 0px 20px 0px #0055D60A !important",
      },
      "& .MuiMenu-paper": {
        background: "transparent !important",
        border: "none !important",
        boxShadow: "0px 0px 20px 0px #0055D60A !important",
        borderRadius: "12px !important",
      },
      "& ul": {
        backgroundColor: "#FFFFFF",
        borderRadius: "12px",
        overflow: "hidden",
        "& li": {
          padding: "7px 16px !important",
          borderBottom: "none !important",
          color: "#000000",
        },
        "& li:hover": {
          background: "#0055D6 !important",
          color: "#ffffff",
        },
      },
    },
    btnLearnFooter: {
      display: "flex",
      alignItems: "center",
      "& svg": {
        paddingTop: 3,
      },
    },
    footerNav: {
      display: "flex",
      gap: "60px",
      alignItems: "center",
      fontSize: "16px",
      lineHeight: "16px",
      fontWeight: 500,
      "& div, & a": {
        cursor: "pointer",
        color: "#FFFFFF",
      },
      "& div": {},
      "& a:hover": {
        transform: "none",
        boxShadow: "none",
      },
      [theme.breakpoints.down("sm")]: {
        gap: "20px",
        flexWrap: "wrap",
      },
    },
    footerTopRight: {
      display: "flex",
      gap: "24px",
      alignItems: "center",
    },
    followUs: {
      paddingRight: "16px",
      fontSize: "16px",
      lineHeight: "19px",
      fontWeight: 600,
      color: "#FFFFFF",
    },
    social: {
      display: "flex",
      gap: "8px",
      alignItems: "center",
      "& div": {
        display: "flex",
        width: "24px",
        height: "24px",
        alignItems: "center",
        justifyContent: "center",
        background: "#FFFFFF",
        borderRadius: "50%",
        cursor: "pointer",
      },
    },
    foooterLine: {
      height: "1px",
      margin: "14px 0 14px",
      background: "#FFFFFF",
      opacity: "0.2",
    },
    footerBot: {
      display: "flex",
      padding: "0 78px",
      alignItems: "center",
      justifyContent: "space-between",
      "& p": {
        fontSize: "14px",
        lineHeight: "16px",
        color: "#FFFFFF",
      },
      "& div, & a": {
        fontSize: "14px",
        lineHeight: "16px",
        color: "#FFFFFF",
      },
      [theme.breakpoints.down("sm")]: {
        padding: 0,
        flexWrap: "wrap",
        justifyContent: "center",
        gap: "8px 40px",
      },
    },
  };
});

export default useStyles;
