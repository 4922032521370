import { useContext } from "react";
import useStyles from "./style";
import { AppContext } from "../../../AppContext";
import { useHistory } from "react-router";

const UserProcess = () => {
  const styles = useStyles();
  const history = useHistory();
  const { currentConnectedWallet, setOpenConnectWallet } =
    useContext(AppContext);

  const currentAccount =
    currentConnectedWallet && currentConnectedWallet.addresses[0];

  const handleConnectWalletOpen = () => {
    setOpenConnectWallet && setOpenConnectWallet(true);
  };
  console.log("current accoun: ", currentAccount);

  return (
    <div className={styles.main}>
      <div className={styles.title}>
        <h2>Getting Started</h2>
        <p>First time users can get set up in four simple steps.</p>
      </div>
      <div className={styles.step}>
        <div className={styles.stepItem}>
          <div className={styles.stepItemDiv}>
            <div>
              <img
                src="/images/landing/user_process.svg"
                width={32}
                height={32}
                alt="process"
              />
              <h6>Connect Wallet</h6>
            </div>
            <p>Connect your favorite crypto wallet.</p>
          </div>
          <button onClick={() => handleConnectWalletOpen()}>
            <div>Connect</div>
          </button>
        </div>
        <div className={styles.stepItem}>
          <div className={styles.stepItemDiv}>
            <div>
              <img
                src="/images/landing/user_process.svg"
                width={32}
                height={32}
                alt="process"
              />
              <h6>Create Account / KYC</h6>
            </div>
            <p>
              Users must create an account and complete KYC to participate in
              RWA project launches
            </p>
          </div>
          <button
            onClick={() => {
              currentAccount
                ? history.push("/account/my_profile")
                : handleConnectWalletOpen();
            }}
          >
            <div>Account Setup</div>
          </button>
        </div>
        <div className={styles.stepItem}>
          <div className={styles.stepItemDiv}>
            <div>
              <img
                src="/images/landing/user_process.svg"
                width={32}
                height={32}
                alt="process"
              />
              <h6>Stake $RWA</h6>
            </div>
            <p>
              To participate and receive additional perks, stake your $RWA in
              one of our staking pools
            </p>
          </div>
          <button onClick={() => history.push("/staking-pools")}>
            <div>Stake</div>
          </button>
        </div>
        <div className={styles.stepItem}>
          <div className={styles.stepItemDiv}>
            <div>
              <img
                src="/images/landing/user_process.svg"
                width={32}
                height={32}
                alt="process"
              />
              <h6>Register for a Project</h6>
            </div>
            <p>
              Explore our upcoming project launches and register for projects
              you are interested in. In order to participate in the launch users
              must register their interest
            </p>
          </div>
          <button onClick={() => history.push("/pools")}>
            <div>Register</div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserProcess;
