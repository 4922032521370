import { useState, useCallback } from "react";
import { ethers } from "ethers";
import { useWeb3React } from "@web3-react/core";
import { useDispatch } from "react-redux";

import { alertSuccess, alertFailure } from "../../../store/actions/alert";
import { getContract } from "../../../utils/contract";

import STAKING_POOL_ABI from "../../../abi/StakingPool.json";
import { getErrorMessage } from "../../../utils/getErrorMessage";
import {Web3Provider} from "@ethersproject/providers";

const useLinearSwitch = (
  poolAddress: string | null | undefined,
  currentPoolId: number | null | undefined,
  targetPoolId: number | null | undefined
) => {
  const [switchPoolLoading, setSwitchPoolLoading] = useState<boolean>(false);
  const [transactionHash, setTransactionHash] = useState<string>("");
  const dispatch = useDispatch();

  const { provider: library, account } = useWeb3React();

  const linearSwitchPool = useCallback(async () => {
    setTransactionHash("");

    try {
      if (poolAddress && ethers.utils.isAddress(poolAddress)) {
        setSwitchPoolLoading(true);

        const contract = getContract(
          poolAddress,
          STAKING_POOL_ABI,
          library as Web3Provider,
          account as string
        );
        if (contract && targetPoolId && currentPoolId) {
          const transaction = await contract.linearSwitch(
            currentPoolId,
            targetPoolId
          );

          setTransactionHash(transaction.hash);

          await transaction.wait(1);

          dispatch(
            alertSuccess(
              "Your staking amount and rewards have been successfully switched!"
            )
          );
          setSwitchPoolLoading(false);
        }
      }
    } catch (err: any) {
      dispatch(alertFailure(getErrorMessage(err)));
      setSwitchPoolLoading(false);
      throw new Error(err.message);
    }
  }, [poolAddress, currentPoolId, targetPoolId, library, account, dispatch]);

  return {
    switchPoolLoading,
    linearSwitchPool,
    setSwitchPoolLoading,
    transactionHash,
  };
};

export default useLinearSwitch;
