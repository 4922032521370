import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
  return {
    noStakeComponent: {
      paddingTop: "20px",
      fontSize: 14,
      lineHeight: "24px",
      fontFamily: "",
    },

    title: {
      marginBottom: 12,
      "& span": {
        color: "#D01F36",
      },
    },

    buttonStakeNow: {
      padding: "7.2px 29.7px",
      background: "#B073FF",
      borderRadius: "2px",
      cursor: "pointer",
      border: "none",
      font: "normal normal 500 12px/16px ",
      color: "#000",

      "&:hover": {
        opacity: "0.8",
        color: "#FFFFFF",
      },
    },

    boxQuestions: {
      maxWidth: 500,
      marginBottom: 20,

      [theme.breakpoints.down("sm")]: {
        "&:last-child": {
          marginBottom: 0,
        },
      },

      "&:last-child": {
        marginBottom: 0,
      },
    },

    listQuestions: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr",

      [theme.breakpoints.down("sm")]: {
        gridTemplateColumns: "1fr",
      },
    },

    subTitle: {
      marginTop: 40,
      marginBottom: 20,
      fontSize: "16px",
      lineHeight: "19px",
      fontWeight: 600,
      color: "#000B60",
    },

    itemQuestions: {
      minWidth: "200px",
      marginBottom: 4,
      paddingRight: 5,

      "& a": {
        fontSize: "16px",
        lineHeight: "19px",
        fontWeight: 600,
        color: "#0058FF",
        "&:hover": {
          '& span[data-role="title"]': {
            textDecorationLine: "underline",
          },
        },

        '& span[data-role="dot"]': {
          marginRight: "6px",
        },
        "& span": {
          whiteSpace: "nowrap",
        },
      },
    },
  };
});

export default useStyles;
