import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
  return {
    tierListComponent: {
      display: "flex",
      flexDirection: "column",
      marginTop: 12,
      padding: "20px",
      border: "1px solid rgba(255, 255, 255, 0.1)",
      borderRadius: "12px",
      background: "#D5E3FF",
    },
    noTierContent: {
      fontSize: "16px",
      lineHeight: "19px",
      color: "#000B60",
      "& span": {
        color: "#0058FF",
        fontWeight: 700,
      },
    },
    buttonStakeNow: {
      maxWidth: "113px",
      marginTop: "21px",
      color: "#FFFFFF",
      border: "none",
      padding: "8px 32px 10px",
      fontSize: "12px",
      background: "#0058FF",
      fontWeight: 600,
      lineHeight: "14px",
      borderRadius: "60px",
    },
    nnn1424h: {
      fontSize: "16px",
      lineHeight: "19px",
      color: "#000B60",
    },
    currentTier: {
      display: "flex",
      gap: "8px",
      paddingTop: "7px",
      fontSize: "24px",
      lineHeight: "24px",
      fontWeight: 600,
      color: "#000B60",

      "& span": {
        color: "#0058FF",
      },
    },

    tierList: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      position: "relative",
      marginTop: 20,

      "& li.process": {
        display: "block",
        height: "12px",
        position: "absolute",
        top: "11.5px",
        left: "0",
        backgroundColor: "#232394",
        zIndex: 1,
        transition: "1s",
        transitionDelay: "0.5s",
        transitionTimingFunction: "linear",

        "&.inactive": {
          width: "0!important",
        },
      },
    },

    pointsLeft: {
      marginTop: "28px",
      fontSize: "14px",
      lineHeight: "16px",
      fontWeight: 600,
      color: "#000B60",
    },

    buttonViewTier: {
      marginLeft: 12,
      padding: "10px 32px 12px",
      border: "1px solid #0058FF",
      color: "#0058FF",
      borderRadius: "60px",
      fontSize: "12px",
      lineHeight: "14px",
      fontWeight: 600,
      background: "none",
    },

    tierInfo: {
      width: "33.3%",
      position: "relative",
      "&::before": {
        content: '""',
        display: "block",
        position: "absolute",
        width: "calc(100% - 10px)",
        height: "4px",
        top: "10px",
        left: "5px",
        background: "#F2F6FE",
      },

      "&.line-active::before": {
        background: "#0058FF",
      },
      "&:last-child": {
        width: "0",

        "&.active .icon:before": {},

        "&.active .icon:after": {},
      },
      "& > div": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
        zIndex: 2,
        width: "1px",
        whiteSpace: "nowrap",
      },

      "& .icon": {
        width: "48px",
        height: "48px",
        marginTop: -12,
        position: "relative",
        "&::after": {
          content: '""',
          display: "none",
          position: "absolute",
          width: "13px",
          height: "13px",
          top: "30px",
          left: "30px",
          backgroundImage: "url(/images/account_v3/check_tier.svg)",
          backgroundSize: "100% 100%",
          zIndex: 2,
        },
      },

      "&.active": {
        "& .icon": {
          "&::after": {
            display: "block",
          },
        },
      },
      "& .icon-inner": {
        transform: "scale(.85, .85)",
        zIndex: 1,
        width: "48px !important",
        height: "48px !important",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        border: "3px solid #F2F6FE",
        borderRadius: "50%",
        overflow: "hidden",
        background: "#000B60",
      },

      "&.active .icon-inner": {
        border: "3px solid #0058FF",
      },

      "& .icon-tick": {
        position: "absolute",
        right: "-6.5px",
        bottom: "-7.5px",
        zIndex: 2,
      },

      "& .progress-bar": {
        display: "block",
        height: "6px",
        position: "absolute",
        top: "6.5px",
        left: "1px",
        width: "calc(100% - 2px)",
        backgroundColor: "#0058FF",

        "&.inactive": {
          width: "0",
        },
      },

      "&.hide-statistics": {
        "& > div:before": {
          top: -8,
        },
        "& .progress-bar": {
          top: -8,
        },
      },

      "& .info": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "15px",
      },

      "&:nth-last-child(2) .progress-bar": {
        borderTopRightRadius: "20px",
        borderBottomRightRadius: "20px",
      },

      "&:last-child > div": {
        alignItems: "flex-end",

        "& .info": {
          alignItems: "flex-end",
        },

        "&:before": {
          display: "none",
        },
      },

      "&:first-child > div": {
        alignItems: "flex-start",
      },

      "&:first-child > div .info": {
        alignItems: "flex-start",
      },

      "& span": {
        fontSize: "16px",
        lineHeight: "19px",
        fontWeight: 600,
        color: "#0058FF",
      },

      "& .tier-name": {
        fontSize: "18px",
        lineHeight: "21px",
        fontWeight: 600,
        color: "#000B60",
      },

      "&.Bronze .progress-bar": {
        width: "calc(100% - 24px)",
        marginLeft: 24,
      },
      "&.Gold .progress-bar": {
        width: "calc(100% - 24px)",
      },
      "& .icon img": {
        width: "100%",
        height: "100%",
      },
      "& .icon.current-tier img": {
        width: 64,
        height: 64,
      },
    },

    [theme.breakpoints.down("xs")]: {
      tierInfo: {
        display: "flex",
        flexDirection: "row",
        width: "auto",
        height: "25%",
        "&.hide-statistics > div:before": {
          top: 0,
        },

        "& .info": {
          alignItems: "flex-start",
          marginLeft: "10px",
          position: "relative",
          marginTop: 0,
        },

        "&:last-child > div .info": {
          alignItems: "flex-start",
        },

        "&:last-child > div": {
          alignItems: "normal",
        },

        "& .icon, & .info": {
          marginBottom: "0",
        },

        "& .icon": {
          marginTop: 0,
        },

        "& > div": {
          display: "flex",
          flexDirection: "row",
          alignItems: "normal",
          justifyContent: "space-between",
          position: "relative",
          width: "auto",
        },

        "& .progress-bar": {
          display: "block",
          width: "6px",
          position: "absolute",
          bottom: 20,
          top: "auto",
          left: "13px",
          height: "calc(100% - 2px)",
          borderBottomLeftRadius: "0",
          borderTopLeftRadius: "0",

          "&.inactive": {
            width: "0",
          },
        },

        "&.first-tier .progress-bar": {
          borderTopLeftRadius: "20px",
          borderTopRightRadius: "20px",
          borderBottomLeftRadius: "0",
        },

        ".progress-bar": {
          borderBottomLeftRadius: "20px",
          borderBottomRightRadius: "20px",
          borderTopRightRadius: "0",
        },
        "&:before": {
          width: "4px",
          height: "100%",
          left: "22px",
        },
        "&:last-child:before": {
          display: "none",
        },
      },
      currentTier: {
        marginBottom: 16,
      },
      pointsLeft: {
        marginTop: 28,
        fontSize: "14px",
        lineHeight: "16px",
        fontWeight: 600,
        color: "#000B60",
        "& button": {
          display: "block",
          margin: "12px 0 0",
        },
      },

      tierList: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "space-between",
        position: "relative",
        height: 300,
        marginTop: 30,
        "& li.process": {
          height: "0",
          width: "12px",
          position: "absolute",
          top: "0",
          left: "13px",

          "&.inactive": {
            width: "5!important",
            height: "0!important",
          },
        },
      },
    },
  };
});

export default useStyles;
