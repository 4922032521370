import { makeStyles, Theme } from "@material-ui/core";

const useCommonStyle = makeStyles((theme: Theme) => {
  return {
    DefaultLayout: {
      background: "#FFFFFF",
      minHeight: "100vh",
      display: "grid",
      gridTemplateRows: "auto 1fr auto",
      gridTemplateAreas: `'header'
        'main'
        'footer'`,
    },
    bgBody: {
      position: "relative",
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column",
      overflow: "auto",
    },
    dashboardLayout: {
      "& .dashboard": {
        paddingTop: 0,
        background: "#ffffff",
        backgroundSize: "cover",
      },
      "& .footer": {
        borderTop: 0,
      },
    },
    eventLayout: {
      "& .dashboard": {
        background: "none",
      },
    },
    flexCol: {
      display: "flex",
      flexDirection: "column",
    },
    flexColCenter: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    flexRow: {
      display: "flex",
      flexDirection: "row",
    },
    flexRowCenter: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
    },
    mb8: {
      marginBottom: 8,
    },
    mt8: {
      marginTop: 8,
    },
    colorRed: {
      color: "#D01F36",
    },
    colorGray: {
      color: "rgba(239, 239, 229, 0.6)",
    },
    skeleton: {
      background: "#515151",
    },
    headPage: {
      display: "flex",
      marginBottom: 25,
    },
    headPageLeft: {},
    headPageRight: {
      marginLeft: "auto",
      display: "flex",
    },
    btnBack: {
      background: "#FFCC00",
      boxShadow: "0px 0px 15px rgba(243, 203, 25, 0.3)",
      borderRadius: 8,
      height: 40,
      minWidth: 92,
      fontWeight: 500,
      fontSize: 14,
      lineHeight: 160,
      alignItems: "center",
      color: "#FFFFFF",
      textTransform: "inherit",
      overflow: "hidden",
      "&:hover": {
        background: "#FFCC00",
      },
    },
    TimePicker: {
      "& .react-time-picker__wrapper": {
        background: "#F0F0F0",
        borderRadius: 8,
        border: "none",
        height: 40,
        padding: "5px 10px",
      },
      "& .react-time-picker__inputGroup": {
        fontSize: 14,
        lineHeight: "20px",
        letterSpacing: "0.25px",
        color: "#9A9A9A",
        "& input": {
          outline: "none",
          border: "none",
          fontSize: 14,
          lineHeight: "20px",
          letterSpacing: "0.25px",
          color: "#9A9A9A",
        },
      },
      "& .react-time-picker__button": {
        padding: 5,
        outline: "none",
        border: "none",

        "& svg": {
          width: 16,
          stroke: "#9A9A9A",
        },
      },
      "& .react-time-picker__inputGroup__input": {
        outline: "none",
        border: "none",
        fontSize: 14,
        lineHeight: "20px",
        letterSpacing: "0.25px",
        color: "#9A9A9A",
      },
    },
    DatePicker: {
      "& .react-date-picker__wrapper": {
        background: "#F0F0F0",
        borderRadius: 8,
        border: "none",
        height: 40,
        padding: "5px 10px",
      },
      "& .react-date-picker__inputGroup": {
        fontSize: 14,
        lineHeight: "20px",
        letterSpacing: "0.25px",
        color: "#9A9A9A",
        "& input": {
          outline: "none",
          border: "none",
          fontSize: 14,
          lineHeight: "20px",
          letterSpacing: "0.25px",
          color: "#9A9A9A",
        },
      },
      "& .react-date-picker__button": {
        padding: 5,
        outline: "none",
        border: "none",

        "& svg": {
          width: 16,
          stroke: "#9A9A9A",
        },
      },
    },

    exchanges: {
      margin: '10px 0',
      display: "flex",
      gap: 8,
      backgroundColor: "transparent",
      "& .kucoin": {
        "& .exchange-logo": {
          background: "url(/images/exchanges/kucoin.png)",
          backgroundSize: "contain",
        },
        "&:hover": {
          backgroundColor: "transparent",
          "& .exchange-logo": {
            background: "url(/images/exchanges/kucoin.png)",
            backgroundSize: "contain",
          },
        },
      },
      "& .uniswap": {
        "& .exchange-logo": {
          background: "url(/images/exchanges/uniswap-gray.png)",
          backgroundSize: "contain",
        },
        "&:hover": {
          backgroundColor: "transparent",
          "& .exchange-logo": {
            background: "url(/images/exchanges/uniswap-gray.png)",
            backgroundSize: "contain",
          },
        },
      },
      "& .gate": {
        "& .exchange-logo": {
          background: "url(/images/exchanges/gate-gray.png)",
          backgroundSize: "contain",
        },
        "&:hover": {
          backgroundColor: "transparent",
          "& .exchange-logo": {
            background: "url(/images/exchanges/gate-gray.png)",
            backgroundSize: "contain",
          },
        },
      },
      "& .ascendex": {
        "& .exchange-logo": {
          background: "url(/images/exchanges/ascendex-gray.png)",
          backgroundSize: "contain",
        },
        "&:hover": {
          backgroundColor: "transparent",
          "& .exchange-logo": {
            background: "url(/images/exchanges/ascendex-gray.png)",
            backgroundSize: "contain",
          },
        },
      },

      [theme?.breakpoints?.down(720)]: {
        width: "100%",
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr 1fr",
      },
    },
    exchangeItem: {
      display: "flex",
      borderRadius: 4,
      width: 128,
      height: 36,
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "transparent",
      transition: "ease 0.35s",
      position: 'relative',
      color: "#EFEFE5",
      "& .exchange-stick-line" : {
        position: 'absolute',
        height: 8,
        width: 1,
        bottom: -14,
        left: "50%",
        transform: "translate(0, -50%)",
        border: '0.5px solid #353539',
      },
      "& .exchange-logo": {
        width: 20,
        height: 20,
      },
      "&:hover": {
        "& .exchange-label": {
          color: "white",
        },
      },
      "& .exchange-label": {
        font: "600 12px/16px ",
        marginLeft: 4,
      },

      [theme?.breakpoints?.down(720)]: {
        width: "100%",
      },
    },

    iconLine: {
      margin: "0px 8px",
      position: "relative",
      width: 12,
    },
    boxSearch: {
      position: "relative",
      marginLeft: 12,
    },
    inputSearch: {
      background: "#F0F0F0",
      borderRadius: 8,
      width: 228,
      maxWidth: "100%",
      height: 40,
      outline: "none",
      border: "none",
      fontSize: 14,
      lineHeight: "20px",
      letterSpacing: "0.25px",
      color: "black",
      padding: "10px 15px",
      paddingRight: 40,
    },
    iconSearch: {
      position: "absolute",
      right: 16,
      top: 12,
    },

    loader: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    loaderText: {
      fontWeight: 700,
      marginTop: 20,
      color: "#999999",
      font: "normal normal bold 14px/18px ",
    },

    loadingTransaction: {
      position: "fixed",
      width: "100%",
      height: "100%",
      top: "0",
      left: "0",
      zIndex: 10,
      "& .MuiBackdrop-root": {
        backgroundColor: "rgba(3, 9, 46, 0.6)",
      },
      "& .MuiPaper-rounded": {
        background: "none",
      },

      "& .content": {
        padding: "60px",
        background: "#020616",
        borderRadius: "4px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      },

      "& .content img": {
        position: "absolute",
        top: "10px",
        right: "10px",
        cursor: "pointer",
      },

      "& .content > span": {
        textAlign: "center",
        marginBottom: "40px",
        display: "block",
        width: "100%",
        color: "#ffffff",
      },
    },
    modalTransactionInfomation: {
      "& .modal-content__body span": {
        fontFamily: "",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "24px",
        color: "#FDFDFD",
      },

      "& .MuiBackdrop-root": {
        background: "none",
      },
      "& .MuiPaper-rounded": {
        background: "none",
      },
      "& .modal-content__body": {
        backgroundColor: "unset!important",
      },

      "& .modal-content__foot button": {
        padding: "12px!important",
        background: "none",
      },
    },
    modal: {
      position: "fixed",
      width: "100%",
      height: "100%",
      top: "0",
      left: "0",
      zIndex: 5,
      backgroundColor: "#09090E96",

      "& .MuiBackdrop-root": {
        background: "none",
      },
      "& .MuiPaper-rounded": {
        background: "none",
      },
      "& .modal-content": {
        width: "480px",
        maxWidth: "100%",
        maxHeight: "80%",
        overflow: "auto",
        padding: "60px",
        backgroundColor: "#09090E",
        border: "1px solid #353539",
        borderRadius: "2px",
      },

      "& .modal-content__head": {
        padding: "10px 0",
        "& .title": {
          color: "#FFFFFF",
          fontFamily: "",
          fontStyle: "normal",
          fontWeight: "bold",
          fontSize: "18px",
          lineHeight: "24px",
          textAlign: "center",
        },

        "& .btn-close": {
          position: "absolute",
          top: "10px",
          right: "10px",
          cursor: "pointer",
        },
      },

      "& .modal-content__body": {
        borderRadius: "4px",
        padding: "10px 12px",
        margin: "20px 0 32px 0",

        "& .subtitle": {
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          color: "#999999",
          fontFamily: "",
          fontStyle: "normal",
          fontWeight: "normal",
          fontSize: "12px",
          lineHeight: "18px",
        },

        "& .input-group": {
          position: "relative",
        },

        "& .input-group input": {
          width: "100%",
          height: "40px",
          background: "none",
          fontFamily: "",
          fontStyle: "normal",
          fontWeight: "normal",
          fontSize: "14px",
          lineHeight: "24px",
          color: "#FDFDFD",
          border: "none",
          outline: "none",
          paddingRight: "60px",
        },

        "& .input-group .btn-max": {
          width: "50px",
          height: "20px",
          color: "#000",
          fontFamily: "",
          fontStyle: "normal",
          fontWeight: "bold",
          fontSize: "12px",
          lineHeight: "14px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: "none",
          outline: "none",
          background: "#FFFFFF",
          padding: "0 12px",
          borderRadius: "1rem",

          "&:hover": {
            cursor: "pointer",
          },
        },

        "& .input-group span": {
          color: "#000",
        },

        "& .input-group div": {
          position: "absolute",
          right: "0",
          top: "10px",
        },
      },

      "& .modal-content__foot": {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        padding: 0,

        "& button": {
          borderRadius: "60px",
          color: "#FFFFFF",
          fontFamily: "",
          fontStyle: "normal",
          fontWeight: "bold",
          fontSize: "14px",
          lineHeight: "18px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: "none",
          outline: "none",
          padding: "12px 60px",

          "&:hover": {
            cursor: "pointer",
          },

          "&.disabled": {
            backgroundColor: "#727272!important",
          },

          "&:first-child": {
            backgroundColor: "#3232DC",
          },

          "&.btn-cancel": {
            backgroundColor: "#727272",
          },
        },
      },
    },
    nnb2832d: {
      font: "normal normal 500 28px/32px ",
    },
    nnb1824d: {
      font: "normal normal 500 18px/24px ",
    },
    nnb1624d: {
      font: "normal normal 500 16px/24px ",
    },
    nnb1418d: {
      font: "normal normal 500 14px/18px ",
    },
    nnb1214d: {
      font: "normal normal 500 12px/14px ",
    },
    nnb2432d: {
      font: "normal normal 500 24px/32px ",
    },
    nnn1012h: {
      font: "normal normal normal 10px/12px ",
    },
    nnn1214h: {
      font: "normal normal normal 12px/14px ",
    },
    nnn1216h: {
      font: "normal normal normal 12px/16px ",
    },
    nnn1418h: {
      font: "normal normal normal 14px/18px ",
    },
    nnn1424h: {
      font: "normal normal normal 14px/24px ",
    },
    nnn1624h: {
      font: "normal normal normal 16px/24px ",
    },
    nnn2432h: {
      font: "normal normal normal 24px/32px ",
    },
    nnn1218h: {
      font: "normal normal 400 12px/18px ",
    },
    nnb1214i: {
      font: "normal normal 500 12px/14px ",
    },
    nnb1418i: {
      font: "normal normal 500 14px/18px ",
    },
    nnb1424i: {
      font: "normal normal 500 14px/24px ",
    },
    nnb1624i: {
      font: "normal normal 500 16px/24px ",
    },
    nnb2024i: {
      font: "normal normal 500 20px/24px ",
    },
    nnb2228i: {
      font: "normal normal 500 22px/28px ",
    },
    nnb2428i: {
      font: "normal normal 500 24px/28px ",
    },
    nnb2432i: {
      font: "normal normal 500 24px/32px ",
    },
    nnb4048i: {
      font: "normal normal 500 40px/48px ",
    },
    nnb4056i: {
      font: "normal normal 500 40px/56px ",
    },
    nnb4856i: {
      font: "normal normal 500 40px/56px ",
    },

    tooltip: {
      maxWidth: 500,
      backgroundColor: "#030925",
      boxShadow: "0px 12px 20px rgba(0, 0, 0, 0.07)",
      borderRadius: "4px",
      padding: "7px 10px",
      "&:before": {
        content: '""',
        width: "10px",
        height: "10px",
        display: "block",
        backgroundColor: "#030925",
        transform: "rotate(-45deg)",
        position: "absolute",
        left: "5px",
        bottom: "10px",
      },
    },

    [theme.breakpoints.down("xs")]: {
      DefaultLayout: {
      },
      modal: {
        "& .modal-content__body": {
          padding: "0",
        },
        "& .modal-content": {
          padding: "15px",
        },
        "& .modal-content__foot": {
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",

          "& button": {
            padding: "12px 30px",
            width: "100%",
            marginBottom: "15px",
          },
        },
        "& .MuiDialogActions-spacing > :not(:first-child)": {
          marginLeft: "0",
        },
      },
      loadingTransaction: {
        width: "100vw",
        height: "100vh",
        "& .MuiPaper-rounded": {
          width: "100%",
        },
        "& .content": {
          padding: "15px",
        },
      },
    },
  };
});

export default useCommonStyle;
