import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, withRouter } from "react-router-dom";
import { USER_STATUS } from "../../constants";
import { BASE_CHAIN_ID, ChainId } from "../../constants/network";
import useAuth from "../../hooks/useAuth";
import useFetch from "../../hooks/useFetch";
import useUserTier from "../../hooks/useUserTier";
import axios from "../../services/axios";
import {
  getTiers,
  getUserInfo,
  getUserTier,
} from "../../store/actions/sota-tiers";
import { getConfigHeader } from "../../utils/configHeader";
import { numberWithCommas } from "../../utils/formatNumber";
import AccountInformation from "./AccountInformation";
import MyPools from "./MyPools";
import MyTiers from "./MyTiers";
import NeedHelp from "./NeedHelp";
import useStyles from "./style";

const iconWarning = "/images/warning-red.svg";

export const menuAccount = {
  my_profile: {
    key: "my_profile",
    name: "My Profile",
    icon: "/images/account_v3/icons/icon_my_profile.svg",
  },
  my_tier: {
    key: "my_tier",
    name: "My Tier",
    icon: "/images/account_v3/icons/icon_my_tier.svg",
  },
  my_pools: {
    key: "my_pools",
    name: "My IDO Pools",
    icon: "/images/account_v3/icons/icon_my_pools.svg",
  },
  help: {
    key: "help",
    name: "Need Help",
    icon: "/images/account_v3/icons/icon_need_help.svg",
  },
};

export const menuMyAccount = [
  menuAccount.my_profile,
  menuAccount.my_tier,
  menuAccount.my_pools,
  menuAccount.help,
];

const DEFAULT_PROFILE = {
  email: "",
  twitter: "",
  telegram: "",
  solanaAddress: "",
  terraAddress: "",
  emailVeryfied: USER_STATUS.UNVERIFIED,
  isKYC: false,
  isLoadingKYC: false,
  createdAt: "",
};

const AccountV2 = (props: any) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { data: userInfo = {} } = useSelector((state: any) => state.userInfo);
  const { isAuth, connectedAccount, wrongChain } = useAuth();

  const { data = {}, loading } = useFetch<any>(
    connectedAccount ? `/user/profile` : undefined
  );
  const params: { slug: string } = useParams();
  const currentMenuAccount = params?.slug
    ? (menuAccount as any)?.[params?.slug]
    : menuAccount?.my_profile;

  const [userProfile, setUserProfile] = useState<null | {
    email: string;
    twitter: string;
    telegram: string;
    solanaAddress: string;
    terraAddress: string;
    emailVeryfied: number;
    isKYC: boolean;
    isLoadingKYC: boolean;
    createdAt: string;
  }>(DEFAULT_PROFILE);
  const [emailVerified, setEmailVeryfied] = useState(0);
  const [email, setEmail] = useState<string>("");
  const { data: appChainID } = useSelector((state: any) => state.appNetwork);
  const { currentTier, totalUnstaked, total } = useUserTier(
    connectedAccount || "",
    "eth"
  );
  const [tabAccount] = useState([
    menuAccount?.my_profile,
    menuAccount?.my_tier,
    menuAccount?.my_pools,
    menuAccount?.help,
  ]);
  const [updatedSuccess, setUpdatedSuccess] = useState(false);
  const { data: tiers = {} } = useSelector((state: any) => state.tiers);
  const { data: userTier = 0 } = useSelector((state: any) => state.userTier);

  useEffect(() => {
    if (isAuth && connectedAccount && !wrongChain) {
      dispatch(getUserTier(connectedAccount));
    }
  }, [isAuth, wrongChain, connectedAccount, dispatch]);

  useEffect(() => {
    if (isAuth && !wrongChain) {
      getUserProfile();
    }
  }, [updatedSuccess]);

  useEffect(() => {
    setUserProfile(DEFAULT_PROFILE);
    setEmail("");
    setEmailVeryfied(USER_STATUS.UNVERIFIED);
    getUserProfile();
  }, [connectedAccount]);

  const getUserProfile = useCallback(
    debounce(async () => {
      if (!connectedAccount) {
        return null;
      } else {
        let config = getConfigHeader(connectedAccount);
        const response = await axios.get(`/user/profile`, config);
        if (response?.data) {
          if (response.data.status === 200) {
            const user = response?.data?.data?.user;
            setEmail(user?.email);
            setEmailVeryfied(user?.status);
            setUserProfile({
              email: user?.email,
              emailVeryfied: user?.status,
              twitter: user?.user_twitter,
              telegram: user?.user_telegram,
              solanaAddress: user?.solana_address,
              terraAddress: user?.terra_address,
              isKYC: !!user?.is_kyc,
              isLoadingKYC: false,
              createdAt: user?.created_at,
            });
          } else {
            setUserProfile(DEFAULT_PROFILE);
            setEmail("");
            setEmailVeryfied(USER_STATUS.UNVERIFIED);
          }
        }
      }
    }, 200),
    [connectedAccount]
  );

  useEffect(() => {
    dispatch(getTiers());
    connectedAccount && dispatch(getUserInfo(connectedAccount));
    connectedAccount && dispatch(getUserTier(connectedAccount));
  }, [connectedAccount, dispatch]);

  const totalRedKitePoints = userInfo?.totalStaked
    ? numberWithCommas(Number(userInfo?.totalStaked).toString() || "0")
    : "0";
  const pointsLeftToNextTier = userInfo?.totalStaked
    ? (tiers[userTier] - Number(userInfo?.totalStaked)).toString() || "0"
    : "0";

  return (
    <>
      <div className={classes.accountContainer}>
        {/* appChainID > KOVAN ID => Not Ethereum mainnet/testnet */}
        {appChainID?.appChainID !== BASE_CHAIN_ID &&
          userProfile?.isKYC &&
          currentMenuAccount?.key === menuAccount?.my_tier?.key && (
            <div
              className={`${classes.alertVerifyEmail} ${classes.errorSwich}`}
            >
              <img src={iconWarning} style={{ marginRight: "12px" }} alt="" />
              <span>Please switch to the Base network to Stake/Unstake</span>
            </div>
          )}

        {updatedSuccess && (
          <div className={classes.messageUpdateSuccess}>
            <img
              src="/images/account_v3/icons/icon_updated_success.svg"
              alt=""
            />
            Your profile has been updated successfully
          </div>
        )}

        <div className={classes.bodyContentMyAccount}>
          <div className={classes.leftAccount}>
            <nav className={classes.tabAccount}>
              {tabAccount.map((item, index) => {
                return (
                  <Link
                    key={index}
                    to={`/account/${item?.key}`}
                    className={`${classes.itemTabAccount}  ${
                      currentMenuAccount?.key === item.key ? "active" : ""
                    }`}
                  >
                    <div>
                      <img src={item.icon} alt="" />
                      {item.name}
                    </div>
                  </Link>
                );
              })}
            </nav>
          </div>

          <div className={classes.rightAccount}>
            <div className={classes?.rightAccountBack}>
              {currentMenuAccount?.key === menuAccount?.my_profile?.key && (
                <AccountInformation
                  notEth={+appChainID?.appChainID > ChainId.BSC_TESTNET}
                  classNamePrefix="account-infomation"
                  userInfo={userInfo}
                  userTier={userTier}
                  email={email}
                  twitter={userProfile?.twitter}
                  telegram={userProfile?.telegram}
                  solanaWallet={userProfile?.solanaAddress}
                  terraWallet={userProfile?.terraAddress}
                  emailVerified={emailVerified}
                  setEmail={setEmail}
                  setEmailVeryfied={setEmailVeryfied}
                  isKYC={userProfile?.isKYC}
                  isLoadingKYC={userProfile?.isLoadingKYC}
                  kycStatus={data?.user?.is_kyc}
                  setUpdatedSuccess={setUpdatedSuccess}
                />
              )}

              {currentMenuAccount?.key === menuAccount?.my_tier?.key && (
                <div className={classes.tier}>
                  <MyTiers
                    showMoreInfomation={false}
                    total={total}
                    isKYC={userProfile?.isKYC}
                    tiers={tiers}
                    userInfo={userInfo}
                    userTier={userTier}
                    emailVerified={emailVerified}
                    connectedAccount={connectedAccount}
                    totalRedKitePoints={totalRedKitePoints}
                    pointsLeftToNextTier={pointsLeftToNextTier}
                  />
                </div>
              )}

              {currentMenuAccount?.key === menuAccount?.my_pools?.key && (
                <MyPools
                  notEth={+appChainID?.appChainID > ChainId.KOVAN}
                  userTier={currentTier}
                />
              )}

              {currentMenuAccount?.key === menuAccount?.help?.key && (
                <NeedHelp />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(AccountV2);
