import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  return {
    dialog: {
      "& .MuiPaper-root": {
        backgroundColor: "#FFFFFF",
        borderRadius: 28,
        padding: 40,
        paddingBottom: 32,
        maxWidth: 400,
        width: 400,
        textAlign: "center",

        [theme?.breakpoints?.down("sm")]: {
          padding: "30px 20px",
        },
      },
    },
    dialogLabel: {
      font: "normal normal normal 12px/18px ",
      textAlign: "left",
    },
    dialogContentTypo: {
      color: "white",
      fontSize: 16,
      marginTop: 40,
      fontWeight: 700,

      "&:first-child": {
        marginTop: 0,
      },
    },
    dialogContentBlock: {
      marginTop: 20,
    },
    dialogTitle: {
      paddingTop: 20,
      padding: 0,
      display: "flex",
      flexDirection: "column",
      backgroundColor: "white !important",

      "& .MuiTypography-h6": {
        fontSize: "32px",
        lineHeight: "32px",
        fontWeight: 600,
        color: "#000B60",
      },

      "& .MuiSvgIcon-root": {
        fontSize: "1rem",
      },
    },
    dialogPrivacy: {
      display: "flex",
      alignItems: "center",
    },
    dialogPrivacyText: {
      fontSize: 16,
    },
    dialogPrivacyHighlight: {
      color: "#3C5EA2",
    },
    dialogCheckbox: {
      padding: 0,
      marginRight: 8,

      "& .MuiSvgIcon-root": {
        fill: "white",
      },
    },
    dialogNetworks: {
      display: "flex",
    },
    dialogInput: {
      width: "100%",
      padding: "8px 15px",
      marginTop: 15,
      background: "#3B3B3B",
      borderRadius: 4,
      border: "none",
      color: "white",
      font: "normal normal normal 14px/24px ",

      "&:focus": {
        outline: "none",
        color: "white",
      },
    },
    dialogButton: {
      margin: "20px auto 32px",
      display: "flex",
      width: "100%",
      background: "#000B60",
      borderRadius: 60,
      padding: "0",
      color: "white",
      border: "none",
      fontSize: "14px",
      lineHeight: "16px",
      fontWeight: 600,
      cursor: "pointer",
      transition: ".2s all ease-out",
      height: 36,
      maxWidth: 164,
      alignItems: "center",
      justifyContent: "center",

      "&:focus": {
        outline: "none",
      },

      "&:hover": {
        opacity: 0.8,
        color: "white",
      },

      "&:active": {
        transform: "translateY(-3px)",
      },

      "& img": {
        marginLeft: 6,
        width: 14,
        height: 14,
      },
    },
    iconSuccess: {
      width: 48,
      height: 48,
      marginBottom: 12,
    },
    additionalText: {
      fontSize: "16px",
      lineHeight: "19px",
      color: "#000B60",
    },
  };
});

export default useStyles;
