export const ALL_WALLETS_INIT_LOADING = "ALL_WALLETS_INIT_LOADING";
export const ALL_WALLETS_INIT_SUCCESS = "ALL_WALLETS_INIT_SUCCESS";
export const ALL_WALLETS_INIT_ERROR = "ALL_WALLETS_INIT_ERROR";

export const WALLET_CONNECT_SUCCESS = "WALLET_CONNECT_SUCCESS";
export const WALLET_DISCONNECT_SUCCESS = "WALLET_DISCONNECT_SUCCESS";
export const WALLET_CONNECT_LAYER2_SUCCESS = "WALLET_CONNECT_LAYER2_SUCCESS";
export const WALLET_CONNECT_SUCCESS_WITHOUT_LAYER2 = "WALLET_CONNECT_SUCCESS_WITHOUT_LAYER2";
export const WALLET_UPDATE_BALANCE = "WALLET_UPDATE_BALANCE";

export const walletActions = {
  ALL_WALLETS_INIT_LOADING,
  ALL_WALLETS_INIT_SUCCESS,
  ALL_WALLETS_INIT_ERROR,
  WALLET_CONNECT_SUCCESS,
  WALLET_DISCONNECT_SUCCESS,
  WALLET_CONNECT_LAYER2_SUCCESS,
  WALLET_CONNECT_SUCCESS_WITHOUT_LAYER2,
  WALLET_UPDATE_BALANCE
}
