import withWidth from "@material-ui/core/withWidth";
import { useSelector } from "react-redux";
import useAuth from "../../../../hooks/useAuth";
import useStyles from "./style";

const Tiers = (props: any) => {
  const { title, action } = props;
  const styles = useStyles();

  return (
    <div className={styles.tierInfomation}>
      <div className={styles.conversionRate}>
        {/* <h3 className={styles.title}>Conversion Rate</h3> */}

        <button className={styles.buttonStake} onClick={() => action()}>
          {title}
        </button>
      </div>
    </div>
  );
};

export default withWidth()(Tiers);
