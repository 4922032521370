import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import useCommonStyle from "../../../styles/CommonStyle";
import useStakingStyles from "../Pool/style";
import useStyles from "./styles";

const closeIcon = "/images/icons/close.svg";
const rewardIcon = "/images/icon-reward.svg";

const ModalRewards = (props: any) => {
  const styles = useStyles();
  const stakingStyles = useStakingStyles();
  const commonStyles = useCommonStyle();

  const { open, onConfirm, onClose, lockDuration } = props;

  return (
    <Dialog
      open={open}
      keepMounted
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      className={commonStyles.modal + " " + styles.modalRewards}
    >
      <div className="modal-content">
        <DialogTitle
          id="alert-dialog-slide-title"
          className="modal-content__head"
        >
          <img src={closeIcon} alt="" onClick={onClose} className="btn-close" />
          <img src={rewardIcon} alt="" className="icon-reward" />
          <div className="title">Stake Reward</div>
        </DialogTitle>
        <p className="duration">
          You are about to add the earned reward back into your principal
          balance, which then brings you even more interest. This action also
          extends your locking duration for another {lockDuration}.
        </p>
        <p className="confirm">Confirm stake your reward?</p>
        <DialogActions className="modal-content__foot">
          <button
            className={styles.btnConfirm}
            onClick={onConfirm}
          >
            Confirm
          </button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default ModalRewards;
