import React from "react";
import useStyles from "./style";

const PrivacyPolicy: React.FC<any> = (props: any) => {
  const styles = useStyles();

  return (
    <div className={styles.privacyPolicy}>
      <h1 className={styles.header}>Privacy Policy</h1>
      <p className={styles.subHeader}>Last Updated: [May 6, 2021]</p>
      <section className={styles.policyDesc}>
        <p>
          This Privacy Policy describes how RWA (referred to herein as “RWA”, “we”, “us”
          or “our”), a company formed pursuant to the laws of the Republic of Panama, will manage
          end user’s personal data collected. This Policy, in consonance with our Terms, applies to
          your use of RWA platform.
        </p>
        <p>
          By accessing the platform and using our services, you consent to the collection, storage,
          use, and disclosure of your personal data, in accordance with this Privacy Policy. We will
          not collect any information from you, except where it is knowingly and explicitly provided
          by you.
        </p>
        <p>
          RWA is committed to respecting your privacy and ensuring that the personal data we
          collect about you is protected and is used, stored and disclosed in accordance with this
          Privacy Policy. Please read this Policy carefully to understand our practices regarding
          your personal data and how we will treat it. If you do not agree with this Privacy Policy,
          please do not use our services or provide RWA platforms with your personal data.
        </p>
      </section>
      <section className={styles.policyExplain}>
        <div>
          <h2>1. Reasons We Collect Your Data</h2>
          <p>
            1.1. RWA will collect your Personal Data only by lawful and fair means for the
            following purposes. One or more purposes may apply simultaneously.
          </p>
          <ul>
            <li>To verify your identity for security purposes;</li>
            <li>
              To provide services to you as a user, for example, processing your requests or
              managing your account;
            </li>
            <li>To update you on technical progress of RWA;</li>
            <li>
              To contact you in relation to the management and administration of your RWA
              account
            </li>
            <li>
              To email you our newsletter or communicate with you in other form about our products
              or services that we think may be of interest to you;
            </li>
            <li>
              To take appropriate action if RWA has reason to suspect that unlawful activity or
              misconduct of a serious nature has been, is being or may be engaged in that relates to
              our functions and activities;
            </li>
            <li>To comply with a legal or regulatory obligation.</li>
          </ul>
          <p>
            1.2. You may choose to deal with us on an anonymous basis or to use a pseudonym unless
            it is not practicable for us to deal with individuals who we cannot identify or we are
            required by law to only deal with identified individuals. Also, if you do not provide
            RWA with the Personal Data we request, we may be unable to process your request to
            become a User, provide you with our services or respond to your enquiry or complaint.
            Examples of Personal Data That may be collected by RWA include your name, email
            address, telephone numbers, your date of birth, other verification information such as
            your Service usage activities. By becoming a User or otherwise choosing to provide RWA
            Pad with Personal Data You consent to RWA collecting, using and disclosing your
            Personal Data For the above purposes.
          </p>
        </div>
        <div>
          <h2>2. Types of Data We Collect</h2>
          <p>2.1. The types of Personal Data that RWA may collect from you are:</p>
          <ul>
            <li>
              Personal identification information: name, email address, country, date of birth,
              nationality, photo of identification document (national ID card or passport), photo of
              you holding your identification document;
            </li>
            <li>User non-custodial wallet address, telegram username.</li>
          </ul>
          <p>
            2.2. With regard to each of your visits to our RWA platform and access to the
            services we may automatically collect the following information:
          </p>
          <ul>
            <li>Transaction data including purchases of crypto assets;</li>
            <li>The smart contract stored in your non-custodial wallet address;</li>
            <li>
              Technical data including IP address, time zone setting and locations, operating
              system, and other technologies on your device used to access the platform;
            </li>
            <li>
              Information about your visits, including the full Uniform Resource Locators (URL)
              clickstream to, through and from the RWA (including date and time);
            </li>
            <li>
              Products or pools you viewed or searched for; page response times, download errors,
              length of visits to certain pages, page interaction information (such as scrolling,
              clicks, and mouse-overs), and methods used to browse away from the page;
            </li>
            <li>
              Information regarding the provision of RWA services we might receive from any
              other sources.
            </li>
          </ul>
        </div>
        <div>
          <h2>3. Collection of Your Data</h2>
          <p>
            3.1. You directly provide RWA with most of the data we collect. We collect data and
            process data when you:
          </p>
          <ul>
            <li>Register online and use the RWA platform services;</li>
            <li>
              Voluntarily complete a Client survey or provide feedback on any of our message boards
              or via email;
            </li>
            <li>
              Contact us, we will keep a record of the information shared during the correspondence.
            </li>
          </ul>
          <p>
            3.2. Additionally, we may receive information about you from publicly available sources
            and collect technical and usage data automatically when you use our services. For
            example, we may aggregate your usage data to calculate the percentage of users accessing
            a specific feature of the services.
          </p>
        </div>
        <div>
          <h2>Management of Your Data</h2>
          <p>
            4.1. RWA will take all reasonable steps to ensure that the Personal Data which it
            collects, uses or discloses is correct and is stored in a secure environment which is
            accessed only by authorised persons.
          </p>
          <p>
            4.2. RWA will destroy or permanently de-identify the Personal Data we hold when it
            is no longer required for any purpose permitted.
          </p>
        </div>
        <div>
          <h2>5. Security of Your Data</h2>
          <p>
            5.1. The security of your Personal Data is important to us, but remember that no method
            of transmission over the Internet, or method of electronic storage is totally secure.
            Therefore, we cannot warrant its absolute security of any information which you
            transmit. Any information which you transmit to RWA is transmitted at your own
            risk.
          </p>
          <p>
            Remember to always log out of your account when you have completed your time on the
            website. This is particularly important if you share a computer with another person. You
            are responsible for the security of and access to your own computer, mobile device or
            any other handset used to access the website.
          </p>
          <p>
            Ultimately, you are solely responsible for maintaining the secrecy of your username,
            password and any account information. Please be careful whenever using the Internet and
            our website.
          </p>
          <p>
            5.2. However, we strive to use commercially acceptable means to protect your Personal
            Data, from misuse, loss and unauthorised access, modification and disclosure including
            by using password protected systems and databases and security technology. RWA
            employees, agents and contractors are required to maintain the confidentiality of users’
            Personal Information and trading behaviour. We will comply with the requirements of
            applicable laws in the event of a data or security risk.
          </p>
        </div>
        <div>
          <h2>6. Law Enforcement</h2>
          <p>
            Under certain circumstances, RWA may be required to disclose your Personal Data if
            required to do so by law or in response to valid requests by public authorities (e.g., a
            court or a government agency).
          </p>
        </div>
        <div>
          <h2>7. Business Transactions</h2>
          <p>
            You are aware that if RWA is ever involved in a merger, acquisition by another
            organisation or goes through asset sale, your personal data might be among the assets
            transferred. We will provide notice before your Personal Data is transferred and becomes
            subject to a different Privacy Policy.
          </p>
        </div>
        <div>
          <h2>8. Link to Other Third Parties</h2>
          <p>
            Under certain circumstances, RWA may be required to disclose your Personal Data if
            required to do so by law or in response to valid requests by public authorities (e.g., a
            court or a government agency).
          </p>
        </div>
        <div>
          <h2>9. Contact Details</h2>
          <p>
            If you believe that someone has provided us with your Personal Data and you would like
            to request that it be removed from our database, please contact us at our contact email
            as specified below. Besides, if you have any queries, requests for access or correction
            or complaints relating to the handling of your personal data, please also contact us by
            email.
          </p>
          <p>
            Email:{" "}
            <a href="mailto:info@RWA.org" style={{ color: "#3232DC" }}>
              info@RWA.org
            </a>
          </p>
        </div>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
