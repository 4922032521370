import React, { useState, useEffect } from "react";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { ClipLoader } from "react-spinners";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import {
  ETH_CHAIN_ID,
  BSC_CHAIN_ID,
  POLYGON_CHAIN_ID,
  AVALANCHE_CHAIN_ID,
  ARBITRUM_CHAIN_ID,
  BASE_CHAIN_ID,
} from "../../../constants/network";

import useStyles from "./style";
import { useCommonStyle } from "../../../styles";

const envLocal = localStorage?.getItem("env");
const env = envLocal ? JSON?.parse(envLocal) : {};

const ETHERSCAN_URL = env.REACT_APP_ETHERSCAN_BASE_URL || "";
const BCSSCAN_URL = env.REACT_APP_BSCSCAN_BASE_URL || "";
const BASESCAN_URL = env.REACT_APP_BASESCAN_BASE_URL || "";
const POLSCAN_URL = env.REACT_APP_POLSCAN_BASE_URL || "";
const AVAXSCAN_URL = env.REACT_APP_AVALANCHESCAN_BASE_URL || "";
const ARBISCAN_URL = env.REACT_APP_ARBITRUMSCAN_BASE_URL || "";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      background: "#242424",
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(2),
      top: theme.spacing(2),
      color: "#0058FF",
      backgroundColor: "#F2F6FE",
      padding: 4,

      "&:hover": {
        backgroundColor: "#D4D4D4",
      },
    },
    svgIcon: {
      fontSize: 5,
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
  customClass: string;
  networkAvailable?: string;
}

export interface ComponentProps {
  opened: boolean;
  handleClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, customClass, onClose, ...other } = props;

  const customStyles = {
    color: "white",
  };

  return (
    <MuiDialogTitle
      disableTypography
      className={`${classes.root} ${customClass}`}
      {...other}
      style={customStyles}
    >
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: 0,
    color: "#fff",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}))(MuiDialogContent);

const TransactionSubmitModal: React.FC<any> = (props: any) => {
  const styles = useStyles();
  const commonStyles = useCommonStyle();
  const { appChainID } = useTypedSelector((state) => state.appNetwork).data;
  const { opened, handleClose, transactionHash, additionalText } = props;

  const [explorerUrl, setExplorerUrl] = useState<String>(ETHERSCAN_URL);
  const [explorerName, setExplorerName] = useState<String>("Etherscan");

  useEffect(() => {
    switch (appChainID) {
      case BSC_CHAIN_ID:
        setExplorerUrl(BCSSCAN_URL);
        setExplorerName("Bscscan");
        break;
      case BASE_CHAIN_ID:
        setExplorerUrl(BASESCAN_URL);
        setExplorerName("Basescan");
        break;

      case POLYGON_CHAIN_ID:
        setExplorerUrl(POLSCAN_URL);
        setExplorerName("Polygonscan");
        break;

      case AVALANCHE_CHAIN_ID:
        setExplorerUrl(AVAXSCAN_URL);
        setExplorerName("Avaxscan");
        break;

      case ARBITRUM_CHAIN_ID:
        setExplorerUrl(ARBISCAN_URL);
        setExplorerName("Arbiscan");
        break;

      case ETH_CHAIN_ID:
      default:
        setExplorerUrl(ETHERSCAN_URL);
        setExplorerName("Etherscan");
        break;
    }
  }, [appChainID]);

  return (
    <Dialog open={opened} className={styles.dialog}>
      <div>
        {transactionHash ? (
          <img
            src="/images/icons/checked.svg"
            alt=""
            className={styles.iconSuccess}
          />
        ) : (
          <img
            src="/images/loading_submit.svg"
            className="loading animate-spin"
            width={48}
            height={48}
            alt="loading"
          />
        )}
      </div>
      <DialogTitle
        id="customized-dialog-title"
        onClose={() => handleClose()}
        customClass={styles.dialogTitle}
      >
        Transaction {transactionHash ? "Submitted" : "Submitting"}
      </DialogTitle>
      {transactionHash && (
        <>
          {/* <span className={styles.dialogLabel}>TXn Hash</span> */}
          {/* <input
                value={transactionHash}
                className={styles.dialogInput}
                disabled={true}
              /> */}
          <a
            href={`${explorerUrl}/tx/${transactionHash}`}
            className={styles.dialogButton}
            target="_blank"
            rel="noreferrer"
          >
            View on {`${explorerName}`}
            <img src="/images/icons/open.svg" alt="" />
          </a>
          {/* <p className={commonStyles.nnn1216h}>
              * Please be patient and no need to approve again, you can check
              the transaction status on the explorer page
            </p> */}
          {additionalText && (
            <p className={styles.additionalText}>{additionalText}</p>
          )}
        </>
      )}
    </Dialog>
  );
};

export default TransactionSubmitModal;
