import { useState, useCallback } from 'react';
import { ethers } from 'ethers';
import { useWeb3React } from '@web3-react/core';
import { useDispatch } from 'react-redux';

import { MAX_INT } from '../services/web3';
import { alertSuccess, alertFailure } from '../store/actions/alert';
import { getContract } from '../utils/contract';
import { TokenType } from '../hooks/useTokenDetails';

import ERC20_ABI from '../abi/Erc20.json';
import { getErrorMessage } from '../utils/getErrorMessage';
import {Web3Provider} from "@ethersproject/providers";

const useTokenApprove = (
  token: TokenType | undefined,
  owner: string | null | undefined,
  spender: string | null | undefined,
  sotaABI: false,
  reload: boolean | null | undefined,
) => {
  const [tokenApproveLoading, setTokenApproveLoading] = useState<boolean>(false);
  const [transactionHash, setTransactionHash] = useState("");
  const dispatch = useDispatch();

  const { provider, account } = useWeb3React();

    const approveToken = useCallback(async () => {
      setTransactionHash("");

      try {
        if (token && spender && owner
            && ethers.utils.isAddress(owner)
            && ethers.utils.isAddress(spender)
            && ethers.utils.isAddress(token.address)
           ) {
             setTokenApproveLoading(true);

             const contract = getContract(token.address, ERC20_ABI, provider as Web3Provider, account as string);

             if (contract) {
               const transaction = await contract.approve(spender, MAX_INT);

               setTransactionHash(transaction.hash);

               await transaction.wait(1);

               dispatch(alertSuccess("Token Approve Successful!"));
               setTokenApproveLoading(false);
             }
           }
      } catch (err: any) {
        dispatch(alertFailure(getErrorMessage(err)));
        setTokenApproveLoading(false);
        throw new Error(err.message);
      }
  }, [owner, spender, token]);

  return {
    tokenApproveLoading,
    approveToken,
    setTokenApproveLoading,
    transactionHash
  }
}

export default useTokenApprove;
