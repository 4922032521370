const TOKENS_BY_USER_REQUEST = 'TOKENS_BY_USER_REQUEST';
const TOKENS_BY_USER_SUCCESS = 'TOKENS_BY_USER_SUCCESS';
const TOKENS_BY_USER_FAIL = 'TOKENS_BY_USER_FAIL';

const TOKENS_CREATE_BY_USER_REQUEST = 'TOKENS_CREATE_BY_USER_REQUEST';
const TOKENS_CREATE_BY_USER_SUCCESS = 'TOKENS_CREATE_BY_USER_SUCCESS';
const TOKENS_CREATE_BY_USER_FAIL = 'TOKENS_CREATE_BY_USER_FAIL';

const TOKEN_REFRESH_SUCCESS = 'TOKEN_REFRESH_SUCCESS';
const TOKEN_REFRESH_FAIL = 'TOKENS_REFRESH_FAIL';
const TOKENS_REFRESHING = 'TOKENS_REFRESHING';
const TOKENS_EXPIRED = 'TOKENS_EXPIRED';

export const tokenActions = {
  TOKENS_BY_USER_REQUEST,
  TOKENS_BY_USER_SUCCESS,
  TOKENS_BY_USER_FAIL,
  TOKENS_CREATE_BY_USER_SUCCESS,
  TOKENS_CREATE_BY_USER_REQUEST,
  TOKENS_CREATE_BY_USER_FAIL,
  TOKEN_REFRESH_SUCCESS,
  TOKEN_REFRESH_FAIL,
  TOKENS_REFRESHING,
  TOKENS_EXPIRED,
}
