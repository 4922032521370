import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
  return {
    modalTransaction: {
      "& .modal-content": {
        width: "calc(100vw - 56px)",
        maxWidth: 400,
        backgroundColor: "#FFFFFF",
        borderRadius: 28,
        padding: 40,
        paddingBottom: 32,
        textAlign: "center",
        [theme.breakpoints.down("xs")]: {
          padding: "40px 20px 30px",
        },
      },
      "& .modal-content__head": {
        display: "flex",
        padding: "20px 0",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        "& .title": {
          fontSize: "32px",
          lineHeight: "32px",
          fontWeight: 600,
          color: "#000B60",
        },
      },
      "& .modal-content__body": {
        padding: "0",

        "& .input-group": {
          background: "#11152A",
          padding: "0 10px",
          borderRadius: "4px",
        },
        "& .input-group input": {
          cursor: "default",
          padding: "0",
        },
        "& .subtitle span": {
          textIndent: "10px",
          marginBottom: "5px",
        },
      },
      "& .modal-content__foot ": {
        justifyContent: "center",
        "& a": {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "#000B60",
          borderRadius: 60,
          height: 36,
          width: 164,
          color: "#FFFFFF",
          "& img": {
            width: 14,
            height: 14,
            marginLeft: 6,
          },
          "& div": {
            paddingBottom: "4px",
          },
        },
      },
    },
    iconSuccess: {
      width: 60,
      height: 60,
    },
    notice: {
      paddingTop: "32px",
      fontSize: "16px",
      lineHeight: "19px",
      color: "#000B60",
    },
    [theme.breakpoints.down("xs")]: {
      modalTransaction: {
        "& .modal-content__body": {
          padding: "0",
        },
      },
    },
  };
});

export default useStyles;
