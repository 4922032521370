import Web3 from "web3";
import {
  connectorNames,
  ConnectorNames,
  connectorsByName,
} from "../constants/connectors";
import {
  ETH_CHAIN_ID,
  BSC_CHAIN_ID,
  POLYGON_CHAIN_ID,
  AVALANCHE_CHAIN_ID,
  ARBITRUM_CHAIN_ID,
  BASE_CHAIN_ID,
  BASE_NETWORK_RPC_URL,
} from "../constants/network";
import { NETWORK_AVAILABLE } from "../constants";
import {
  ETH_NETWORK_RPC_URL,
  BSC_NETWORK_RPC_URL,
  POLYGON_NETWORK_RPC_URL,
  AVALANCHE_NETWORK_RPC_URL,
  ARBITRUM_NETWORK_RPC_URL,
} from "../constants/network";

const POOL_ABI = require("../abi/Pool.json");

const DEFAULT_NETWORK_URL = BSC_NETWORK_RPC_URL;

export const MAX_INT =
  "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff";

export enum SmartContractMethod {
  Write = "Write",
  Read = "Read",
}

type smartContractMethod = Extract<
  SmartContractMethod,
  SmartContractMethod.Write | SmartContractMethod.Read
>;

export const getWeb3Instance = () => {
  const windowObj = window as any;
  const { ethereum, web3 } = windowObj;
  if (ethereum && ethereum.isMetaMask) {
    return new Web3(ethereum);
  }
  if (web3) {
    return new Web3(web3.currentProvider);
  }
  return null;
};

export const isMetaMaskInstalled = () => {
  const windowObj = window as any;
  const { ethereum } = windowObj;
  return ethereum && ethereum.isMetaMask;
};

export const getProviderByNetwork = (
  connector: connectorNames,
  chainId: string,
  typeMethod: smartContractMethod
) => {
  if (chainId && typeMethod === SmartContractMethod.Read) {
    switch (chainId) {
      case BSC_CHAIN_ID:
        return new Web3.providers.HttpProvider(BSC_NETWORK_RPC_URL);
      case BASE_CHAIN_ID:
        return new Web3.providers.HttpProvider(BASE_NETWORK_RPC_URL);
      case POLYGON_CHAIN_ID:
        return new Web3.providers.HttpProvider(POLYGON_NETWORK_RPC_URL);
      case AVALANCHE_CHAIN_ID:
        return new Web3.providers.HttpProvider(AVALANCHE_NETWORK_RPC_URL);
      case ARBITRUM_CHAIN_ID:
        return new Web3.providers.HttpProvider(ARBITRUM_NETWORK_RPC_URL);
      case ETH_CHAIN_ID:
        return new Web3.providers.HttpProvider(ETH_NETWORK_RPC_URL);
      default:
        return new Web3.providers.HttpProvider(DEFAULT_NETWORK_URL);
    }
  }

  const provider = connectorsByName[connector as connectorNames] as any;
  return provider;
};

export const getContractInstance = (
  ABIContract: any,
  contractAddress: string,
  connector: connectorNames = ConnectorNames.MetaMask,
  chainId: string = BASE_CHAIN_ID as string,
  typeMethod: smartContractMethod = SmartContractMethod.Read
) => {
  const provider = getProviderByNetwork(
    connector as connectorNames,
    chainId,
    typeMethod
  );

  if (provider) {
    const web3Instance = new Web3(provider);

    return new web3Instance.eth.Contract(ABIContract, contractAddress);
  }

  return;
};

export const getContractReadInstance = (
  ABIContract: any,
  contractAddress: string,
  networkAvailable: string
) => {
  let provider;
  switch (networkAvailable) {
    case NETWORK_AVAILABLE.BSC:
      provider = new Web3.providers.HttpProvider(BSC_NETWORK_RPC_URL);
      break;
    case NETWORK_AVAILABLE.BASE:
      provider = new Web3.providers.HttpProvider(BASE_NETWORK_RPC_URL);
      break;

    case NETWORK_AVAILABLE.POLYGON:
      provider = new Web3.providers.HttpProvider(POLYGON_NETWORK_RPC_URL);
      break;

    case NETWORK_AVAILABLE.ETH:
      provider = new Web3.providers.HttpProvider(ETH_NETWORK_RPC_URL);
      break;

    case NETWORK_AVAILABLE.AVALANCHE:
      provider = new Web3.providers.HttpProvider(AVALANCHE_NETWORK_RPC_URL);
      break;

    case NETWORK_AVAILABLE.ARBITRUM:
      provider = new Web3.providers.HttpProvider(ARBITRUM_NETWORK_RPC_URL);
      break;
  }
  if (!provider) {
    return;
  }

  const web3Instance = new Web3(provider);

  return new web3Instance.eth.Contract(ABIContract, contractAddress);
};

export const getContractInstanceWeb3 = (networkAvailable: string) => {
  let provider;
  switch (networkAvailable) {
    case NETWORK_AVAILABLE.BSC:
      provider = new Web3.providers.HttpProvider(BSC_NETWORK_RPC_URL);
      return new Web3(provider);

    case NETWORK_AVAILABLE.BASE:
      provider = new Web3.providers.HttpProvider(BASE_NETWORK_RPC_URL);
      return new Web3(provider);

    case NETWORK_AVAILABLE.POLYGON:
      provider = new Web3.providers.HttpProvider(POLYGON_NETWORK_RPC_URL);
      return new Web3(provider);

    case NETWORK_AVAILABLE.ETH:
      provider = new Web3.providers.HttpProvider(ETH_NETWORK_RPC_URL);
      return new Web3(provider);

    case NETWORK_AVAILABLE.AVALANCHE:
      provider = new Web3.providers.HttpProvider(AVALANCHE_NETWORK_RPC_URL);
      return new Web3(provider);

    case NETWORK_AVAILABLE.ARBITRUM:
      provider = new Web3.providers.HttpProvider(ARBITRUM_NETWORK_RPC_URL);
      return new Web3(provider);
    default:
      return null;
  }
};

export const getPoolContract = ({ networkAvailable, poolHash }: any) => {
  const web3Instance = getContractInstanceWeb3(networkAvailable);
  if (!web3Instance) {
    return null;
  }

  return new web3Instance.eth.Contract(POOL_ABI, poolHash);
};

export const getContractInstanceWithEthereum = (
  ABIContract: any,
  contractAddress: string
) => {
  const windowObj = window as any;
  const { ethereum } = windowObj;
  if (ethereum && ethereum.isMetaMask) {
    const web3Instance = new Web3(ethereum);
    return new web3Instance.eth.Contract(ABIContract, contractAddress);
  } else if (windowObj.web3) {
    const web3Instance = new Web3(windowObj.web3.currentProvider);
    return new web3Instance.eth.Contract(ABIContract, contractAddress);
  } else {
    return null;
  }
};

export const getContractInstanceWithBSC = (
  ABIContract: any,
  contractAddress: string
) => {
  const windowObj = window as any;
  const { ethereum } = windowObj;
  const web3Instance = new Web3(ethereum);
  return new web3Instance.eth.Contract(ABIContract, contractAddress);
};

export const convertFromWei = (value: any, unit = "ether") => {
  return Web3.utils.fromWei(value);
};

export const convertToWei = (value: any, unit = "ether") => {
  return Web3.utils.toWei(value);
};

export const isValidAddress = (address: string) => {
  return Web3.utils.isAddress(address);
};

export const getETHBalance = async (loginUser: string) => {
  const web3 = getWeb3Instance() as any;
  if (web3) {
    const balance = await web3.eth.getBalance(loginUser);

    return web3.utils.fromWei(balance);
  }

  return 0;
};

export const convertToBN = (number: string) => {
  return Web3.utils.toBN(number);
};
