import { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useWeb3React } from "@web3-react/core";
import BigNumber from "bignumber.js";

import useUserPurchaseSignature from "../hooks/useUserPurchaseSignature";
import useWalletSignature from "../../../hooks/useWalletSignature";
import { alertSuccess, alertFailure } from "../../../store/actions/alert";
import Pool_ABI from "../../../abi/Pool.json";
import { NETWORK } from "../../../constants";
import PreSalePool from "../../../abi/PreSalePool.json";
import { getContract } from "../../../utils/contract";
import { TRANSACTION_ERROR_MESSAGE } from "../../../constants/alert";
import axios from "../../../services/axios";
import { getErrorMessage } from "../../../utils/getErrorMessage";
import { Web3Provider } from "@ethersproject/providers";

const envLocal = localStorage?.getItem("env");
const env = envLocal ? JSON?.parse(envLocal) : {};

type PoolDepositActionParams = {
  poolAddress?: string;
  poolId?: number;
  purchasableCurrency: string;
  amount: string;
  isClaimable: boolean;
  networkAvailable: string;
  isInPreOrderTime: boolean;
};

const usePoolDepositAction = ({
  poolAddress,
  poolId,
  purchasableCurrency,
  amount,
  isClaimable,
  networkAvailable,
  isInPreOrderTime,
}: PoolDepositActionParams) => {
  const dispatch = useDispatch();

  const [depositError, setDepositError] = useState("");
  const [tokenDepositTransaction, setTokenDepositTransaction] =
    useState<string>("");
  const [tokenDepositLoading, setTokenDepositLoading] =
    useState<boolean>(false);
  const [tokenDepositSuccess, setTokenDepositSuccess] =
    useState<boolean>(false);

  const { account: connectedAccount, provider: library } = useWeb3React();
  const {
    error,
    signMessage,
    signature: authSignature,
    setSignature,
  } = useWalletSignature();
  const {
    signature,
    minBuy,
    maxBuy,
    error: buyError,
    setSignature: setUserPurchasedSignature,
  } = useUserPurchaseSignature(connectedAccount, poolId, authSignature);

  useEffect(() => {
    poolAddress &&
      purchasableCurrency &&
      signature &&
      minBuy &&
      maxBuy &&
      !depositError &&
      depositWithSignature(
        poolAddress,
        purchasableCurrency,
        amount,
        signature,
        `${minBuy}`,
        maxBuy
      );
  }, [
    signature,
    poolAddress,
    purchasableCurrency,
    amount,
    minBuy,
    maxBuy,
    depositError,
  ]);

  useEffect(() => {
    if (error || buyError) {
      const errorMessage = error || buyError;
      setDepositError(errorMessage as string);
      setTokenDepositLoading(false);
      setSignature("");
      setUserPurchasedSignature("");
    }
  }, [error, buyError]);

  const depositWithSignature = useCallback(
    async (
      poolAddress: string,
      acceptCurrency: string,
      amount: string,
      signature: string,
      minBuy: string,
      maxBuy: string
    ) => {
      try {
        if (minBuy && maxBuy && signature && amount) {
          const abiUse = isClaimable ? PreSalePool : Pool_ABI;
          const poolContract = getContract(
            poolAddress,
            abiUse,
            library as Web3Provider,
            connectedAccount as string
          );

          const method =
            acceptCurrency === "ETH"
              ? "buyTokenByEtherWithPermission"
              : "buyTokenByTokenWithPermission";
          let decimals = 6;
          const isBSC = networkAvailable == "bsc";
          if (isBSC) {
            if (acceptCurrency == "ETH") {
              decimals = 18;
            } else if (acceptCurrency == "BUSD") {
              decimals = 18;
            } else if (acceptCurrency == "USDT") {
              decimals = 18;
            } else if (acceptCurrency == "USDC") {
              decimals = 18;
            }
          } else {
            if (acceptCurrency == "ETH") {
              decimals = 18;
            } else if (acceptCurrency == "USDT") {
              decimals = 6;
            } else if (acceptCurrency == "USDC") {
              decimals = 6;
            }
          }

          let buyCurr = "ETH";
          switch (networkAvailable) {
            case NETWORK.BSC:
              if (acceptCurrency === "BUSD") {
                buyCurr = env.REACT_APP_BUSD_BSC_SMART_CONTRACT || "";
              }
              if (acceptCurrency === "USDT") {
                buyCurr = env.REACT_APP_USDT_BSC_SMART_CONTRACT || "";
              }
              if (acceptCurrency === "USDC") {
                buyCurr = env.REACT_APP_USDC_BSC_SMART_CONTRACT || "";
              }
              break;
            case NETWORK.BASE:
              if (acceptCurrency === "USDC") {
                buyCurr = env.REACT_APP_USDC_BASE_SMART_CONTRACT || "";
              }
              break;

            case NETWORK.POLYGON:
              if (acceptCurrency === "USDT") {
                buyCurr =
                  env.REACT_APP_USDT_POLYGON_SMART_CONTRACT || "";
              }
              if (acceptCurrency === "USDC") {
                buyCurr =
                  env.REACT_APP_USDC_POLYGON_SMART_CONTRACT || "";
              }
              break;

            case NETWORK.AVALANCHE:
              if (acceptCurrency === "USDT") {
                buyCurr =
                  env.REACT_APP_USDT_AVALANCHE_SMART_CONTRACT || "";
              }
              break;

            case NETWORK.ARBITRUM:
              if (acceptCurrency === "USDT") {
                buyCurr =
                  env.REACT_APP_USDT_ARBITRUM_SMART_CONTRACT || "";
              }
              break;

            case NETWORK.ETHEREUM:
              if (acceptCurrency === "USDT") {
                buyCurr = env.REACT_APP_USDT_SMART_CONTRACT || "";
              }
              if (acceptCurrency === "USDC") {
                buyCurr = env.REACT_APP_USDC_SMART_CONTRACT || "";
              }
              break;
          }

          const params =
            acceptCurrency === "ETH"
              ? [
                  connectedAccount,
                  maxBuy,
                  minBuy,
                  signature,
                  {
                    value: new BigNumber(amount)
                      .multipliedBy(10 ** 18)
                      .toFixed(),
                  },
                ]
              : [
                  buyCurr,
                  new BigNumber(amount).multipliedBy(10 ** decimals).toFixed(),
                  connectedAccount,
                  maxBuy,
                  minBuy,
                  signature,
                ];

          const transaction = await poolContract[method](...params);

          setUserPurchasedSignature("");
          setSignature("");
          setTokenDepositTransaction(transaction.hash);

          await transaction.wait(1);

          dispatch(alertSuccess("Token Deposit Successful!"));
          setTokenDepositLoading(false);
          setTokenDepositSuccess(true);
        }
      } catch (err) {
        dispatch(alertFailure(getErrorMessage(err)));
        setDepositError(TRANSACTION_ERROR_MESSAGE);
        setTokenDepositLoading(false);
        setSignature("");
        setUserPurchasedSignature("");
      }
    },
    [minBuy, maxBuy, poolAddress, isClaimable]
  );

  const deposit = useCallback(async () => {
    if (amount && new BigNumber(amount).gt(0) && poolAddress) {
      try {
        setTokenDepositTransaction("");
        setDepositError("");
        setTokenDepositLoading(true);
        setTokenDepositSuccess(false);

        await signMessage();
      } catch (err) {
        dispatch(alertFailure(getErrorMessage(err)));
        setDepositError(TRANSACTION_ERROR_MESSAGE);
        setSignature("");
        setTokenDepositLoading(false);
      }
    }
  }, [connectedAccount, library, poolAddress, amount]);

  return {
    tokenDepositSuccess,
    deposit,
    tokenDepositLoading,
    tokenDepositTransaction,
    setTokenDepositTransaction,
    setTokenDepositLoading,
    depositError,
  };
};

export default usePoolDepositAction;
