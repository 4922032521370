import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  return {
    dialog: {
      "& .MuiPaper-root": {
        padding: 40,
        maxWidth: 400,
        textAlign: "center",
        background: "#FFFFFF",
        borderRadius: "28px",
        border: "none",
      },
      "& .MuiIconButton-root": {
        width: "28px",
        height: "28px",
        top: "12px",
        right: "12px",
        background: "#F2F6FE",
        "& path": {
          fill: "#0058FF",
        },
      },
    },
    dialogTitle: {
      padding: "0 0 20px !important",
      "& h6": {
        width: "100%",
        fontSize: "32px",
        lineHeight: "32px",
        fontWeight: 600,
        color: "#000B60",
        textAligns: "center",
      },
      "& .MuiTypography-h6": {
        font: "normal normal 500 22px/28px ",
      },

      "& .MuiSvgIcon-root": {
        fontSize: "1rem",
      },
    },
    dialogContent: {
      display: "flex",
      flexWrap: "wrap",
      gap: 8,
      padding: "0 !important" as any,
      position: "relative",
      [theme.breakpoints.only("xs")]: {
        justifyContent: "center",
        gap: 8,
      },
    },
    loadingIcon: {
      position: "absolute",
      left: "50%",
      top: "30%",
      transform: "translate(-50%, -50%)",
    },
  };
});

export default useStyles;
