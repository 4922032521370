import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
  const titleFont = "normal normal 500 16px/24px ";
  return {
    calendarView: {
      width: "100%",
      margin: "0 auto",
      padding: "0 20px",
      display: "flex",
      flexDirection: "column",
    },
    header: {
      display: "grid",
      alignItems: "center",
      padding: "20px 0",
      color: "#000B60",
      "& span": {
        fontSize: "12px",
        lineHeight: "14px",
        color: "#000B60",
      },
    },
    headTitleMobile: {
      display: "flex",
      justifyContent: "space-between",
    },
    title: {
      display: "grid",
      fontSize: "40px",
      lineHeight: "40px",
    },
    textTimeZone: {
      color: "#F8F7FA",
      font: titleFont,
    },
    search: {
      display: "flex",
      alignItems: "center",
      padding: "6px 12px",
      background: "#292929",
      borderRadius: 4,
      border: "1px solid #44454B",
      width: "100%",
      height: 36,

      "& input": {
        border: "none",
        fontFamily: "",
        outline: "none",
        background: "transparent",
        paddingLeft: 10,
        color: "white",
        width: "100%",
        "&::placeholder": {
          color: "#ffffffc0",
        },
      },
      "& img:hover": {
        cursor: "pointer",
      },
    },
    iconClose: {
      marginRight: 8,
    },
    wrapper: {},
    row: {
      display: "flex",
      alignItems: "center",
    },
    spacer: {
      flex: 1,
    },
    month: {
      font: titleFont,
      width: "80%",
      textAlign: "center",
      padding: "6px 20px",
      fontWeight: 600,
      color: "#000B60",
    },
    tableLoading: {
      height: 150,
      display: "flex",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
      background: "transparent",
    },
    btn: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      font: "normal normal 500 14px/18px ",
      border: 0,
      borderRadius: 2,
      outline: "none",
      height: 36,
      padding: "0 20px",
      color: "white",
      cursor: "pointer",
      transition: "0.3s",

      "&.btn-square": {
        width: 36,
        height: 36,
        padding: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "#2E2E2E",
      },

      "&.btn-today": {
        width: "fit-content",
        padding: "16px 40px 18px",
        background: "#000B60",
        borderRadius: "60px",
        marginRight: 12,
        fontSize: "14px",
        lineHeight: "16px",
        fontWeight: 600,
        "&:hover": {
          background: "#000B60",
        },
      },
      "&.btn-sync": {
        background: "#6788FF",
        marginLeft: "auto",
        transition: "0.3s",
        "&:hover": {
          opacity: "0.85",
        },
        "& img": {
          width: 12,
          height: 12,
          marginRight: 6,
        },
      },

      "&.btn-list-view": {
        width: "154px",
        height: 40,
        font: "normal normal 500 14px/16px ",
        background: "#0058FF",
        borderRadius: 60,
        border: "none",
        outline: "none",
        display: "flex",
        alignItems: "center",
        textAlign: "center",
        justifyContent: "center",
        cursor: "pointer",
        color: "#ffffff",
        fontWeight: 600,
        "& img": {
          width: 14,
          height: 14,
          marginRight: 4,
        },
        "& span": {
          color: "#ffffff",
        },
        "&:hover": {
          opacity: 1,
        },
      },
      "&:hover": {
        opacity: 0.8,
      },
    },
    searchGroup: {
      position: "relative",
      maxWidth: 320,
      height: 44,
      width: "70%",
      marginLeft: 6,
      border: "none",
      "& input": {
        background: "#F2F6FE",
        border: "none",
        boxSizing: "border-box",
        borderRadius: "60px",
        outline: "none",
        width: "100%",
        height: 44,
        fontSize: "16px",
        lineHeight: "19px",
        color: "#000B60",
        padding: "0 40px 0 14px",
      },
      "& img": {
        position: "absolute",
        right: "14px",
        transform: "translateY(-50%)",
        top: "50%",
      },
      [theme.breakpoints.down("sm")]: {
        marginLeft: 0,
        marginTop: 10,
        maxWidth: "100%",
        width: "100%",
      },
    },
    calendarContainer: {
      marginTop: 6,
      borderRadius: 12,
      padding: 20,
      background: "linear-gradient(235.66deg, #ECF5FF 29.71%, #FFFFFF 118.81%)",
    },
    calendarHeader: {
      display: "grid",
      gridTemplateColumns: "repeat(7, 1fr)",
      gridGap: 6,
      marginTop: 12,
      marginBottom: 8,
    },
    calendarHeaderItem: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      font: titleFont,
      fontWeight: 600,
      color: "#000B60",
    },
    calendarContent: {
      display: "grid",
      gridTemplateColumns: "repeat(7, minmax(0, 1fr))",
      gridTemplateRows: "repeat(6, auto)",
      gridGap: 6,
    },
    calendarPerDay: {
      borderRadius: 4,
      padding: 8,
      cursor: "pointer",
      color: "#000B60",
      minHeight: 50,
      transition: "transform .2s",
      display: "flex",
      flexDirection: "column",
      "& .active-dot": {
        background: "#000B60",
        margin: "auto",
        width: 5,
        height: 5,
        borderRadius: 5,
      },
      "&.selected-day": {
        background: "#0058FF15",
      },
    },
    bgToday: {
      background: "#73a3da",
    },
    event: {
      borderRadius: 2,
      padding: "10px 6px 8px 3px",
      display: "flex",
      cursor: "pointer",
      marginBottom: 4,
      minHeight: 48,
    },
    eventInfo: {
      width: "100%",
      maxWidth: "calc(100% - 26px)",
      marginLeft: 6,
      color: "white",
      display: "flex",
      flexDirection: "column",
    },
    poolName: {
      fontSize: "14px",
      lineHeight: "16px",
      color: "#000B60",
      fontWeight: 600,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    titleCard: {
      fontSize: "14px",
      lineHeight: "16px",
      color: "#000B60",
    },
    dayTopLeft: {
      font: titleFont,
      margin: "0 auto",
      display: "flex",
    },
    filterBar: {
      display: "flex",
      flexWrap: "wrap",
      gap: 4,
    },
    toggle: {
      display: "flex",
      alignItems: "center",
      color: "#000B60",
      "& span": {
        font: "14px/20px ",
      },
    },
    toggleEvents: {
      margin: "0 12px 0 0",
    },
    selectBox: {
      fontFamily: "",
      fontSize: 14,
      lineHeight: "24px",
      color: "#FFFFFF",
      height: 36,
      width: 160,
      background: "#292929",
      border: "1px solid #44454B",
      borderRadius: 4,
      marginLeft: "auto",

      "&::before, &::after": {
        display: "none",
      },

      "& select": {
        padding: 0,
        paddingLeft: 12,
        height: "100%",
      },

      "& .MuiSelect-select option": {
        backgroundColor: "#292929",
      },

      "& .MuiSelect-icon": {
        color: "#FFFFFF",
        fontSize: 20,
        top: "calc(50% - 10px)",
        right: 4,
      },
      [theme.breakpoints.only("xs")]: {
        width: "100% !important" as any,
      },
    },
    timeSearch: {
      marginBottom: 6,
      color: "#000B60",
      fontSize: "18px",
      lineHeight: "24px",
      fontWeight: 600,
    },
    logo: {
      background: "black",
      borderRadius: 4,
      width: 24,
      height: 24,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",

      "& img": {
        width: 24,
        height: 24,
      },
    },
    eventColors: {
      display: "flex",
      color: "#fff",
      marginTop: 20,
      paddingBottom: 60,
    },
    colorDetail: {
      display: "flex",
      alignItems: "center",
      marginRight: 40,
    },
    dotColor: {
      width: 8,
      height: 8,
      borderRadius: "50%",
      marginRight: 6,
      "&.bg-open": {
        background: "#5EFF8B",
      },
      "&.bg-close": {
        background: "#D01F36",
      },
      "&.bg-ido": {
        background: "#6788FF",
      },
      "&.bg-claim": {
        background: "#FFD058",
      },
    },
    result: {
      marginTop: 20,
      marginBottom: 20,
    },
    resultHeader: {
      color: "white",
      fontFamily: "",
      fontSize: 16,
      lineHeight: "24px",
      fontWeight: 700,
      marginBottom: 8,
    },
    resultContent: {
      display: "flex",
      alignItems: "stretch",
      flexWrap: "wrap",

      "& > div": {
        marginTop: 0,
        marginRight: 6,
        marginBottom: 6,
        maxWidth: 150,
      },
    },
    noResult: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingTop: 60,
      textAlign: "center",
      color: "white",
      marginBottom: 20,
      "& h2": {
        margin: "10px 0 2px 0",
      },

      "& p": {
        margin: 0,
        maxWidth: 200,
        color: "#AEAEAE",
      },
    },
    paginationBar: {
      "& .filter-bar": {
        display: "grid",
        gap: 8,
      },
      "& .select-month": {
        marginTop: 10,
        display: "flex",
      },
    },
    paginationBtn: {
      width: "10%",
      "& input": {
        cursor: "pointer",
        width: 36,
        height: 36,
        borderRadius: 6,
        color: "#fff",
        border: 0,
        "&:hover": {
          opacity: "0.8",
        },
        "&:focus": {
          outline: "none",
        },
        "&:disabled": {
          cursor: "not-allowed",
          opacity: 0.5,
        },
      },
    },
    btnPrevious: {
      background: "url('/images/icons/prev.svg') no-repeat center",
    },
    btnNext: {
      background: "url('/images/icons/next.svg') no-repeat center",
    },
    showMore: {
      marginTop: "auto",
      fontFamily: "",
      fontSize: 12,
      lineHeight: "16px",
      fontWeight: 600,
      color: "#EFEFE5",
      textAlign: "center",
      cursor: "pointer",
    },
  };
});

export default useStyles;
