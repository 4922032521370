import Dialog from "@material-ui/core/Dialog";
import moment from "moment";
import React from "react";
import useStyles from "./style";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";

function AllPoolModal(props: any) {
  const { show, handleShow, showMore, currentId, dayList, gotoDetail } = props;
  const styles = useStyles();
  const dayValue = dayList[currentId];

  const handleClose = () => {
    showMore(0);
    handleShow();
  };

  const renderDate = () => {
    const dayOfWeek = moment(dayValue?.date).format("ddd");
    const day = moment(dayValue?.date).format("MMM D YYYY");
    return `${dayOfWeek}, ${day}`;
  };

  return (
    <Dialog open={show} onClose={handleShow} className={styles.dialog}>
      <IconButton
        aria-label="close"
        className="button-close"
        onClick={handleClose}
      >
        <img src="/images/icons/close.svg" width={24} height={24} alt="close" />
      </IconButton>
      <div className="title">{renderDate()}</div>
      {dayValue?.events.length &&
        dayValue?.events.map((ev: any, i: number) => (
          <div
            className={styles.event}
            key={i}
            style={{
              backgroundColor: ev.backgroundColor,
              borderLeft: `2px solid ${ev.color}`,
              cursor: "pointer",
            }}
            onClick={() => gotoDetail(ev.campaign_id)}
          >
            <div className={styles.logo}>
              <img alt="" src={ev.token_image} />
            </div>
            <div className={styles.info}>
              <p>
                {moment.unix(ev.start_time).format("HH:mm")}
                {ev.end_time &&
                  ` - ${moment.unix(ev.end_time).format("HH:mm")}`}
              </p>
              <p className="limit-text-1-line">{ev.title}</p>
              <p className="limit-text-1-line">{ev.phase}</p>
            </div>
          </div>
        ))}
    </Dialog>
  );
}

export default AllPoolModal;
