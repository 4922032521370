const APP_NETWORKS_SETTING_LOADING = 'APP_NETWORKS_SETTING_LOADING';
const APP_NETWORKS_SETTING_SUCCESS = 'APP_NETWORKS_SETTING_SUCCESS';
const APP_NETWORKS_SETTING_ERROR = 'APP_NETWORKS_SETTING_ERROR';
const APP_NETWORKS_SETTING_CLEAR = 'APP_NETWORKS_SETTING_CLEAR';

const CONNECTOR_SETTING_LOADING = 'CONNECTOR_SETTING_LOADING';
const CONNECTOR_SETTING_SUCCESS = 'CONNECTOR_SETTING_SUCCESS';
const CONNECTOR_SETTING_ERROR= 'CONNECTOR_SETTING_ERROR';
const CONNECTOR_SETTING_CLEAR= 'CONNECTOR_SETTING_CLEAR';

export const appNetworkActions = {
  APP_NETWORKS_SETTING_LOADING,
  APP_NETWORKS_SETTING_SUCCESS,
  APP_NETWORKS_SETTING_ERROR,
  APP_NETWORKS_SETTING_CLEAR,
  CONNECTOR_SETTING_LOADING,
  CONNECTOR_SETTING_SUCCESS,
  CONNECTOR_SETTING_CLEAR,
  CONNECTOR_SETTING_ERROR
}
