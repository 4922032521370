import BigNumber from "bignumber.js";
import React, { useContext, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { POOL_IS_PRIVATE, PUBLIC_WINNER_STATUS } from "../../../constants";
import { numberWithCommas } from "../../../utils/formatNumber";
import { PoolStatus } from "../../../utils/getPoolStatus";
import Button from "../Button";
import useStyles from "./styles";
import { AppContext } from "../../../AppContext";
import ConnectWalletModal from "../../../components/Base/HeaderDefaultLayout/ConnectWalletModal";
import { HeaderContext } from "../../../components/Base/HeaderDefaultLayout/context/HeaderContext";

const CLASSES = {
  NORMAL: "normal",
  WIN: "win",
  NOT_WIN: "not-win",
  APPLIED: "applied",
};

function BannerNotificationDisconnected(props: any) {
  const styles = useStyles();
  const { openConnectWallet, setOpenConnectWallet } = useContext(AppContext);

  const {
    poolDetails,
    ableToFetchFromBlockchain,
    winnersList,
    isOverTimeApplyWhiteList,
    poolStatus,
    refetch,
    totalLoading,
    tokenSold,
    soldProgress,
    poolTimeline,
  } = props;

  const [showSwapProgress, setShowSwapProgress] = useState<boolean>(false);
  const [iconBanner, setIconBanner] = useState<string | null>(null);
  const [classNameTitle, setClassNameTitle] = useState<string>("");
  const [notificationText, setNotificationText] = useState<any>({
    whitelist: "",
    allocation: "",
    winAmount: "",
  });
  const [agreedTerms, setAgreedTerms] = useState<boolean>(false);

  const { joinTimeInDate, endJoinTimeInDate, endBuyTimeInDate } = poolTimeline;

  const handleConnectWalletOpen = () => {
    setOpenConnectWallet && setOpenConnectWallet(true);
  };

  const handleConnectWalletClose = () => {
    setOpenConnectWallet && setOpenConnectWallet(false);
  };

  const poolSwapEnded =
    poolStatus === PoolStatus.Filled ||
    poolStatus === PoolStatus.Claimable ||
    poolStatus === PoolStatus.Closed;
  const totalSoldCoin = poolDetails?.totalSoldCoin || 0;
  const poolType = Number(poolDetails?.isPrivate || "0");
  const isEventPool = poolType === POOL_IS_PRIVATE.EVENT;
  const poolForCommunity =
    poolType === POOL_IS_PRIVATE.COMMUNITY || isEventPool;
  const joinableWhitelist =
    !(ableToFetchFromBlockchain && winnersList && winnersList.total > 0) &&
    !isOverTimeApplyWhiteList &&
    (poolStatus === PoolStatus.Upcoming || poolStatus === PoolStatus.TBA);

  const showApplyWhitelistButton = !poolForCommunity && joinableWhitelist;
  const showJoinCompetitionButton =
    poolForCommunity &&
    !!poolDetails?.socialRequirement?.gleam_link &&
    joinableWhitelist;
  const disableJoinButton =
    !joinTimeInDate || (joinTimeInDate && new Date() < joinTimeInDate);
  const showJoinButton = showApplyWhitelistButton || showJoinCompetitionButton;

  useEffect(() => {
    let icon = null;
    let className = CLASSES.NORMAL;
    let textWhitelist = "Loading ...";
    let textAllocation = "";
    let textAmount = "";

    if (!totalLoading) {
      if (poolStatus === PoolStatus.Upcoming || poolStatus === PoolStatus.TBA) {
        className = CLASSES.NORMAL;
        if (!poolForCommunity) {
          textWhitelist = "JOIN INTEREST";
          textAllocation = "The interest is not yet open.";
        } else {
          textWhitelist = isEventPool ? "EVENT POOL" : "JOIN COMPETITION";
          textAllocation = isEventPool
            ? "This pool is reserved for event participants. The event is not yet open."
            : "The competition is not yet open.";
        }
      }
      if (showJoinButton && !disableJoinButton) {
        className = CLASSES.NORMAL;
        if (!poolForCommunity) {
          textWhitelist = "JOIN INTEREST";
          textAllocation = "The whitelist is now open.";
        } else {
          textWhitelist = isEventPool ? "EVENT POOL" : "JOIN COMPETITION";
          textAllocation = isEventPool
            ? "This pool is reserved for event participants. Click on the button below to learn more about the event."
            : "The competition is opening.";
        }
      }

      if (isOverTimeApplyWhiteList) {
        className = CLASSES.NORMAL;
        if (!poolForCommunity) {
          textWhitelist = "Registration Ended";
          textAllocation = "The Registration has ended.";
        } else {
          textWhitelist = isEventPool ? "EVENT POOL" : "JOIN COMPETITION";
          textAllocation = `${
            isEventPool ? "The event" : "Whitelist period"
          } has ended.`;
        }
      }
      if (
        poolStatus === PoolStatus.Progress ||
        poolStatus === PoolStatus.Filled
      ) {
        setShowSwapProgress(true);
      }
    }

    setClassNameTitle(className);
    setIconBanner(icon);
    setNotificationText({
      whitelist: textWhitelist,
      allocation: textAllocation,
      winAmount: textAmount,
    });
  }, [refetch, poolStatus, totalLoading]);

  const renderSwapProgress = () => {
    return (
      <div className={styles.swapProgress}>
        <div className="title-progress">Swap Progress</div>
        <div className={styles.progress}>
          <div className={styles.progressBar}>
            <span
              className={`${styles.currentProgress} ${
                parseFloat(soldProgress) > 0 ? "" : "inactive"
              }`}
              style={{
                width: `${Math.round(parseFloat(soldProgress))}%`,
              }}
            ></span>
          </div>
        </div>
        <div className={styles.progressInfo}>
          <span>
            {new BigNumber(soldProgress).toFixed(0)}
            %&nbsp;
          </span>
          <span>
            {numberWithCommas(tokenSold, 0)}/{numberWithCommas(totalSoldCoin)}
          </span>
        </div>
      </div>
    );
  };
  return (
    <>
      <HeaderContext.Provider value={{ agreedTerms, setAgreedTerms }}>
        <ConnectWalletModal
          opened={openConnectWallet as boolean}
          handleClose={handleConnectWalletClose}
        />
      </HeaderContext.Provider>
      {showSwapProgress ? (
        renderSwapProgress()
      ) : (
        <div className={styles.notification}>
          <>
            <div className={`noti-whitelist ${classNameTitle}`}>
              {iconBanner && <img src={iconBanner} alt="" />}
              <span>{notificationText.whitelist}</span>
            </div>

            <span className="noti-allocation">
              {notificationText.allocation}
              {notificationText.winAmount && (
                <>
                  <span className="text-amount win">
                    {notificationText.winAmount}
                  </span>
                  .
                </>
              )}
            </span>
          </>

          {/* {showSubWallet && !totalLoading && (
            <div>
              <div className={styles.solanaWallet}>Solana Wallet Address</div>
              <div className={styles.solanaInput}>
                <img src={iconSolana} alt="" />
                <span>{`${whitelistSubmission?.airdrop_address.slice(
                  0,
                  8
                )}*****${whitelistSubmission?.airdrop_address.slice(
                  -8
                )}`}</span>
              </div>
            </div>
          )} */}

          {showApplyWhitelistButton && !totalLoading && (
            <Button
              text="Register Interest"
              color={"#fff"}
              style={{
                width: "100%",
                height: 36,
                borderRadius: 60,
                font: "normal normal 500 14px/20px",
                background: "transparent",
                border: "none",
                padding: 4,
                margin: isMobile ? "7px auto" : "unset",
              }}
              disabled={disableJoinButton}
              onClick={handleConnectWalletOpen}
            />
          )}

          {showJoinCompetitionButton && !totalLoading && (
            <Button
              text={isEventPool ? "About Event" : "Join Competition"}
              backgroundColor={isEventPool ? "#B073FF" : "#B073FF"}
              color={isEventPool ? "#000" : "#000"}
              style={{
                width: "100%",
                height: 36,
                borderRadius: 60,
                font: "normal normal 500 14px/20px ",
                border: "none",
                padding: 8,
                margin: isMobile ? "7px auto" : "unset",
              }}
              disabled={disableJoinButton}
              onClick={() =>
                window.open(poolDetails?.socialRequirement?.gleam_link)
              }
            />
          )}
        </div>
      )}
    </>
  );
}

export default BannerNotificationDisconnected;
