import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
  return {
    modalStake: {
      "& .MuiPaper-root": {
        [theme.breakpoints.down("xs")]: {
          margin: "28px",
        },
      },
      "& .subtitle": {
        font: "normal normal 500 14px/18px ",
      },
    },
    notice: {
      font: "normal normal 500 14px/18px ",
      color: "#FFF",
      marginTop: "30px",
    },

    learnMenu: {
      "& .Component-root-331 .MuiPaper-root": {
        borderRadius: "12px !important",
        overflow: "hidden",
        boxShadow: "0px 0px 20px 0px #0055D60A !important",
      },
      "& .MuiMenu-paper": {
        background: "transparent !important",
        border: "none !important",
        boxShadow: "0px 0px 20px 0px #0055D60A !important",
        borderRadius: "12px !important",
      },
      "& ul": {
        backgroundColor: "#FFFFFF",
        borderRadius: "12px",
        overflow: "hidden",
        "& li": {
          padding: "7px 16px !important",
          borderBottom: "none !important",
          color: "#000000",
        },
        "& li:hover": {
          background: "#0055D6 !important",
          color: "#ffffff",
        },
      },
    },
    [theme.breakpoints.down("xs")]: {
      modalTransaction: {
        "& .modal-content__body": {
          padding: "0",
        },
        "& .modal-content__foot": {
          gridTemplateColumns: "1fr",
        },
      },
    },
  };
});

export default useStyles;
