import { useEffect, useMemo } from "react";

interface Props {
  amount: number;
  maxAmount: number;
  isDisabled?: boolean;
  setAmount: (amount: any) => void;
}

const ProgressMovement = ({ amount, maxAmount, isDisabled, setAmount }: Props) => {
  const valuePercent = useMemo(() => {
    return Math.floor((amount / maxAmount) * 100) || 0;
  }, [amount, maxAmount]);

  const changeProgressBar = (value: any) => {
    const progressBar: any = document?.getElementById("progress-bar");
    progressBar && (progressBar.value = value);
    const amountValue = (value * maxAmount) / 100;
    setAmount(amountValue + "");
  };

  const changeProgressValue = (value: any) => {
    const progressValue: any = document?.getElementById("progress-value");
    progressValue && (progressValue.value = value);
    const amountValue = (value * maxAmount) / 100;
    setAmount(amountValue + "");
  };

  const changeValue = (value: any) => {
    let style = document.createElement("style");
    document.head.appendChild(style);
    const progressValue: any = document?.getElementById("progress-value");
    progressValue &&
      (style.textContent = `.progress-value::-webkit-slider-thumb {background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='19px' width='44px'><text x='${+value < 10 ? 14 : +value < 100 ? 12 : 10
        }' y='13.5' fill='white' font-size='11'>${value}%</text></svg>"); }`);
  };

  useEffect(() => {
    changeValue(valuePercent);
  }, [valuePercent]);

  return (
    <div className="progress-component">
      <input
        type="range"
        min="0"
        max="100"
        value={valuePercent}
        className="progress-value"
        id="progress-value"
        disabled={isDisabled}
        onInput={(e: any) => {
          changeProgressBar(e?.target?.value);
          changeValue(e?.target?.value);
        }}
      />
      <input
        type="range"
        min="0"
        max="100"
        value={valuePercent}
        className="progress-bar"
        id="progress-bar"
        disabled={isDisabled}
        onInput={(e: any) => {
          changeProgressValue(e?.target?.value);
          changeValue(e?.target?.value);
        }}
      />
    </div>
  );
};

export default ProgressMovement;
