import { useState, useCallback } from 'react';
import { ethers } from 'ethers';
import { useWeb3React } from '@web3-react/core';
import { useDispatch } from 'react-redux';

import { alertSuccess, alertFailure } from '../../../store/actions/alert';
import { getContract } from '../../../utils/contract';

import STAKING_POOL_ABI from '../../../abi/StakingPool.json';
import { getErrorMessage } from '../../../utils/getErrorMessage';
import {Web3Provider} from "@ethersproject/providers";


const useAllocClaim = (
  poolAddress: string | null | undefined,
  poolId: number | null | undefined,
) => {
  const [tokenClaimLoading, setTokenClaimLoading] = useState<boolean>(false);
  const [transactionHash, setTransactionHash] = useState("");
  const dispatch = useDispatch();

  const { provider: library, account } = useWeb3React();

    const allocClaimToken = useCallback(async () => {
      setTransactionHash("");

      try {
        if (poolAddress && ethers.utils.isAddress(poolAddress)) {
          setTokenClaimLoading(true);

             const contract = getContract(poolAddress, STAKING_POOL_ABI, library as Web3Provider, account as string);

             if (contract) {
               const transaction = await contract.allocClaimReward(poolId);

              setTransactionHash(transaction.hash);

               await transaction.wait(1);

              dispatch(alertSuccess("Token Claimed Successful!"));
              setTokenClaimLoading(false);
             }
           }
      } catch (err: any) {
        dispatch(alertFailure(getErrorMessage(err)));
        setTokenClaimLoading(false);
        throw new Error(err?.message);
      }
  }, [poolAddress, poolId, library, account, dispatch]);

  return {
    tokenClaimLoading,
    allocClaimToken,
    setTokenClaimLoading,
    transactionHash
  }
}

export default useAllocClaim;
