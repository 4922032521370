const TIERS_LOADING = 'TIERS_LOADING';
const TIERS_SUCCESS = 'TIERS_SUCCESS';
const TIERS_FAILURE = 'TIERS_FAILURE';

const USER_TIER_LOADING = 'USER_TIER_LOADING';
const USER_TIER_SUCCESS = 'USER_TIER_SUCCESS';
const USER_TIER_FAILURE = 'USER_TIER_FAILURE';
const USER_TIER_RESET = 'USER_TIER_RESET';

const USER_INFO_LOADING = 'USER_INFO_LOADING';
const USER_INFO_SUCCESS = 'USER_INFO_SUCCESS';
const USER_INFO_FAILURE = 'USER_INFO_FAILURE';

const USER_INFO_LEGACY_LOADING = 'USER_INFO_LEGACY_LOADING';
const USER_INFO_LEGACY_SUCCESS = 'USER_INFO_LEGACY_SUCCESS';
const USER_INFO_LEGACY_FAILURE = 'USER_INFO_LEGACY_FAILURE';

const DEPOSIT_LOADING = 'DEPOSIT_LOADING';
const DEPOSIT_SUCCESS = 'DEPOSIT_SUCCESS';
const DEPOSIT_FAILURE = 'DEPOSIT_FAILURE';

const WITHDRAW_LOADING = 'WITHDRAW_LOADING';
const WITHDRAW_SUCCESS = 'WITHDRAW_SUCCESS';
const WITHDRAW_FAILURE = 'WITHDRAW_FAILURE';

const UPDATE_TIER_LOADING = 'UPDATE_TIER_LOADING';
const UPDATE_TIER_SUCCESS = 'UPDATE_TIER_SUCCESS';
const UPDATE_TIER_FAILURE = 'UPDATE_TIER_FAILURE';

const UPDATE_WITHDRAW_FEE_LOADING = 'UPDATE_WITHDRAW_FEE_LOADING';
const UPDATE_WITHDRAW_FEE_SUCCESS = 'UPDATE_WITHDRAW_FEE_SUCCESS';
const UPDATE_WITHDRAW_FEE_FAILURE = 'UPDATE_WITHDRAW_FEE_FAILURE';

const WITHDRAW_FEE_LOADING = 'WITHDRAW_FEE_LOADING';
const WITHDRAW_FEE_SUCCESS = 'WITHDRAW_FEE_SUCCESS';
const WITHDRAW_FEE_FAILURE = 'WITHDRAW_FEE_FAILURE';

const WITHDRAW_PERCENT_LOADING = 'WITHDRAW_PERCENT_LOADING';
const WITHDRAW_PERCENT_SUCCESS = 'WITHDRAW_PERCENT_SUCCESS';
const WITHDRAW_PERCENT_FAILURE = 'WITHDRAW_PERCENT_FAILURE';

const RATES_LOADING = 'RATES_LOADING';
const RATES_SUCCESS = 'RATES_SUCCESS';
const RATES_FAILURE = 'RATES_FAILURE';

export const sotaTiersActions = {
  TIERS_LOADING,
  TIERS_SUCCESS,
  TIERS_FAILURE,
  USER_TIER_LOADING,
  USER_TIER_SUCCESS,
  USER_TIER_FAILURE,
  USER_TIER_RESET,
  USER_INFO_LOADING,
  USER_INFO_SUCCESS,
  USER_INFO_FAILURE,
  USER_INFO_LEGACY_LOADING,
  USER_INFO_LEGACY_SUCCESS,
  USER_INFO_LEGACY_FAILURE,
  DEPOSIT_LOADING,
  DEPOSIT_SUCCESS,
  DEPOSIT_FAILURE,
  WITHDRAW_LOADING,
  WITHDRAW_SUCCESS,
  WITHDRAW_FAILURE,
  UPDATE_TIER_LOADING,
  UPDATE_TIER_SUCCESS,
  UPDATE_TIER_FAILURE,
  UPDATE_WITHDRAW_FEE_LOADING,
  UPDATE_WITHDRAW_FEE_SUCCESS,
  UPDATE_WITHDRAW_FEE_FAILURE,
  WITHDRAW_FEE_LOADING,
  WITHDRAW_FEE_SUCCESS,
  WITHDRAW_FEE_FAILURE,
  WITHDRAW_PERCENT_LOADING,
  WITHDRAW_PERCENT_SUCCESS,
  WITHDRAW_PERCENT_FAILURE,
  RATES_LOADING,
  RATES_SUCCESS,
  RATES_FAILURE
};
