import React from "react";
import MainDefaultLayout from "../../Base/MainDefaultLayout";
import HeaderDefaultLayout from "../../Base/HeaderDefaultLayout";
import FooterDefaultLayout from "../../Base/FooterDefaultLayout";
import { useCommonStyle } from "../../../styles";

type LayoutProps = {
  isDashboard?: boolean | undefined;
  isEvent?: boolean | undefined;
  isKYC?: boolean | undefined;
  children?: any;
};

const DefaultLayout = (props: LayoutProps) => {
  const commonStyle = useCommonStyle();
  const { isDashboard = false, children, isEvent = false } = props;

  return (
    <div className={commonStyle.DefaultLayout}>
      <div
        className={`${commonStyle.bgBody} ${
          isDashboard ? commonStyle.dashboardLayout : ""
        } ${isEvent ? commonStyle.eventLayout : ""}`}
      >
        <HeaderDefaultLayout />
        <MainDefaultLayout>{children}</MainDefaultLayout>
        <FooterDefaultLayout />
      </div>
    </div>
  );
};

export default DefaultLayout;
