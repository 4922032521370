import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
  return {
    sectionBuyTokenPoolDetails: {
      [theme?.breakpoints?.down("sm")]: {
        paddingTop: 12,
      },
    },

    topSection: {
      display: "flex",
      justifyContent: "space-between",

      [theme?.breakpoints?.down("sm")]: {
        flexDirection: "column",
        alignContent: "center",
      },
    },

    title: {
      fontWeight: "bold",
      fontSize: 16,
      lineHeight: "24px",
      marginBottom: 20,
      textTransform: "uppercase",

      [theme?.breakpoints?.down("sm")]: {
        textAlign: "center",
        marginBottom: 16,
      },
    },

    rightTopSection: {
      display: "flex",
    },

    itemSocsial: {
      width: 24,
      height: 24,
      borderRadius: "50%",
      marginRight: 10,

      "&:hover": {
        opacity: 0.8,
      },
    },

    midSection: {
      display: "Grid",
      gridTemplateColumns: "1fr 1fr",
      gap: 12,
      marginBottom: 20,
      [theme?.breakpoints?.down("sm")]: {
        gridTemplateColumns: "1fr",
      },
      [theme?.breakpoints?.down("xs")]: {
        gap: 0,
      },
    },

    socsialContainer: {
      margin: "auto",
      marginBottom: 10,
    },

    listContent: {
      borderRadius: 12,
      background: "#D5E3FF",
      padding: "12px 20px",

      [theme?.breakpoints?.down("sm")]: {
        width: "100%",
      },
      [theme?.breakpoints?.down("xs")]: {
        border: "1px solid #44454B",
        "&:first-child": {
          borderRadius: "8px 8px 0 0",
          borderBottom: 0,
          paddingBottom: 0,
        },
        "&:last-child": {
          borderRadius: "0 0 8px 8px",
          borderTop: 0,
          paddingTop: 0,
          "& li:last-child": {
            flexDirection: "column",
          },
        },
        "& .text-vesting": {
          textAlign: "left",
          marginLeft: 0,
        },
      },
    },

    itemListContent: {
      display: "flex",
      color: "#000B60",
      marginBottom: 6,

      "& .text-red": {
        fontWeight: "bold",
        color: "#0058FF",
      },
      "& .text-yellow": {
        fontWeight: "bold",
        color: "#0058FF",
      },
      "& .text-purple": {
        fontWeight: "bold",
        color: "#0058FF",
      },
      "& .text-schedule": {
        flex: 1,
      },
      "& span": {
        fontSize: "16px",
        lineHeight: "19px",
        fontWeight: 600,
      },
    },
    networkDisplay: {
      display: "flex",
      alignItems: "center",
      marginLeft: "auto",
      "& img": {
        width: 16,
        height: 16,
        marginRight: 4,
        borderRadius: "50%",
      },
    },
    networkIcon: {
      width: 16,
      height: 16,
      marginRight: 6,
    },

    iconBrank: {
      marginLeft: 5,
    },

    nameItemListContent: {
      width: 141,
      fontWeight: 400,
      marginRight: "20px",
    },
    detailItemListContent: {
      textAlign: "right",
      marginLeft: "auto",
    },

    titleBot: {
      fontSize: "24px",
      lineHeight: "24px",
      fontWeight: 600,
      color: "#000B60",
      marginBottom: 12,
    },

    botSection: {
      fontSize: "16px",
      lineHeight: "19px",
      color: "#000B60",
      whiteSpace: "pre-line",
      "& .view-more": {
        color: "#6788FF",
      },
      "& .hide": {
        color: "#D01F36",
      },
    },
    tooltip: {
      font: "normal normal 500 14px/24px ",
      color: "#FFFFFF",
      padding: "5px 10px",
      background: "#575757",
    },

    btnOpenModal: {
      color: "#6398FF",
      textDecoration: "underline",
      cursor: "pointer",
    },

    modalTiers: {
      "& .MuiDialog-paper": {
        width: 1200,
        maxWidth: "100%",
        background: "#38383F",
        borderRadius: "12px",
      },
    },

    headerModal: {
      padding: 40,
      paddingBottom: 0,
      position: "relative",
      fontFamily: "",
      fontWeight: "bold",
      fontSize: 28,
      lineHeight: "32px",
      color: "#FFFFFF",

      [theme?.breakpoints?.down("sm")]: {
        fontSize: 20,
        lineHeight: "24px",
        alignContent: "center",
        paddingTop: 36,
        paddingLeft: 20,
        paddingRight: 20,
      },
    },

    btnCloseModal: {
      position: "absolute",
      top: 12,
      right: 12,
      cursor: "pointer",

      "&:hover": {
        opacity: 0.8,
      },
    },

    modalContentTiers: {
      maxWidth: "100%",
    },

    contentModal: {
      padding: 40,
      paddingTop: 0,

      [theme?.breakpoints?.down("sm")]: {
        padding: 20,
        maxHeight: "calc(100vh - 150px)",
      },
    },

    table: {
      background: "#222228",
      border: "1px solid #37373D",
      borderRadius: 8,
      "& .MuiTableBody-root td": {
        font: "normal normal normal 14px/24px ",
      },
    },

    iconTable: {
      position: "relative",
      top: 5,
      marginLeft: 5,
      width: 20,
      height: 21,

      [theme?.breakpoints?.down("sm")]: {
        width: 15,
        height: 15,
      },
    },

    tableContainer: {
      maxWidth: "100%",
      width: "100%",
      background: "transparent",
      color: "#999999",
      marginTop: 20,

      [theme.breakpoints.down("sm")]: {
        marginTop: 0,
      },

      "& th, & td": {
        borderTop: "1px solid #37373D",
        borderBottom: 0,
        fontFamily: "",
        fontSize: 16,
        lineHeight: "24px",
        color: "#FFFFFF",
        paddingLeft: 40,
      },

      "& .MuiTableCell-root": {
        borderTop: "1px solid #37373D",
        borderBottom: 0,
      },
    },

    tableHeaderWrapper: {
      backgroundColor: "#191920",
      borderRadius: "8px 8px 0px 0px",

      "& th": {
        fontFamily: "",
        fontWeight: 500,
        fontSize: 16,
        lineHeight: "24px",
        color: "#FFFFFF",
      },
    },

    tableHeader: {
      color: "white !important" as any,
      fontWeight: 700,
      fontSize: 15,
      "& > span": {
        display: "inline-block",
      },
      [theme.breakpoints.down("xs")]: {
        "& > span": {
          display: "inline-block",
        },
      },
      [theme.breakpoints.down("md")]: {
        "& > span": {
          width: "120px",
        },
      },
    },

    boxTierMobile: {
      marginBottom: 20,
      borderBottom: "1px solid #44454B",

      "&:last-child": {
        borderBottom: 0,
      },
    },

    nameItemTierMobile: {
      fontFamily: "",
      fontWeight: "bold",
      fontSize: 14,
      lineHeight: "18px",
      color: "#AEAEAE",
      marginBottom: 8,
    },

    valueItemTierMobile: {
      fonFamily: "",
      fontSize: 14,
      lineHeight: "20px",
      color: "#FFFFFF",
    },

    itemTierMobile: {
      marginBottom: 20,
    },
  };
});

export default useStyles;
