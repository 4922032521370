import React from "react";
import Button from "../Button";
import { isMobile } from "react-device-detect";

function ApplyWhiteListButton(props: any) {
  const { joinPool } = props;

  return (
    <>
      <Button
        text={"Register Interest"}
        color={"white"}
        style={{
          width: "100%",
          height: 36,
          borderRadius: 60,
          font: "normal normal 500 14px/20px ",
          border: "none",
          background: "transparent",
          padding: 4,
          margin: isMobile ? "7px auto" : "unset",
        }}
        onClick={joinPool}
        disabled={false}
      />
    </>
  );
}

export default ApplyWhiteListButton;
