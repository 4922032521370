const ALLOWANCE_LOADING = 'ALLOWANCE_LOADING';
const ALLOWANCE_SUCCESS = 'ALLOWANCE_SUCCESS';
const ALLOWANCE_FAILURE = 'ALLOWANCE_FAILURE';

const APPROVE_LOADING = 'APPROVE_LOADING';
const APPROVE_SUCCESS = 'APPROVE_SUCCESS';
const APPROVE_FAILURE = 'APPROVE_FAILURE';

export const sotaTokenActions = {
  ALLOWANCE_LOADING,
  ALLOWANCE_SUCCESS,
  ALLOWANCE_FAILURE,
  APPROVE_LOADING,
  APPROVE_SUCCESS,
  APPROVE_FAILURE,
};
