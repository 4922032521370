import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
  return {
    modalWhitelistCancel: {
      "& .MuiDialog-paper": {
        width: 404,
        background: "#FFFFFF",
        borderRadius: 28,
        color: "#000B60",
      },
    },

    headModalWhitelistCancel: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      position: "relative",
      width: "100%",
      padding: "40px 22px 8px 22px",
      textAlign: "center",
    },

    btnColseModal: {
      position: "absolute",
      top: 12,
      right: 14,
      minWidth: "auto",
      padding: 0,
      background: "transparent",
      boxShadow: "none",
      borderRadius: "50%",
    },

    iconModal: {
      width: 40,
      height: 40,
    },

    titleModal: {
      marginTop: 18,
      fontFamily: "DM Sans",
      fontWeight: "bold",
      fontSize: 24,
      lineHeight: "28px",
      textAlign: "center",
    },

    comtentModalWhitelistCancel: {
      padding: "0 22px",
      fontFamily: "Helvetica",
      fontSize: 14,
      lineHeight: "20px",
      textAlign: "center",
    },

    footerModalWhitelistCancel: {
      padding: "32px 22px",
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      gridGap: 12,

      [theme.breakpoints.down("xs")]: {
        gridTemplateColumns: "1fr",
      },

      "& button": {
        marginLeft: 0,
        paddingBotton: "2px",
        height: 42,
        backgroundColor: "#0058FF",
        borderRadius: 60,
        boxShadow: "none",
        fontWeight: 600,
        fontSize: 16,
        lineHeight: "19px",
        textAlign: "center",
        color: "#FFFFFF",
        textTransform: "inherit",

        "&:hover": {
          backgroundColor: "#0058FF",
          boxShadow: "none",
        },
      },

      "& button:last-child": {
        marginLeft: 0,
        backgroundColor: "transparent",
        border: "1px solid #0058FF",
        color: "#0058FF",

        "&:hover": {
          backgroundColor: "transparent",
        },
      },
    },
  };
});

export default useStyles;
