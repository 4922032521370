import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import withWidth, { isWidthDown } from "@material-ui/core/withWidth";
import CloseIcon from "@material-ui/icons/Close";
import { useContext } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { AppContext } from "../../../../AppContext";
import {
  connectorsSupportByNetwork,
  SUPPORTED_WALLETS,
} from "../../../../constants/connectors";
import {
  appNetworkType,
  APP_NETWORKS,
  APP_NETWORKS_NAME,
  BSC_CHAIN_ID,
  ETH_CHAIN_ID,
  POLYGON_CHAIN_ID,
  AVALANCHE_CHAIN_ID,
  ARBITRUM_CHAIN_ID,
  BASE_CHAIN_ID,
} from "../../../../constants/network";
import ConnectWalletBox from "../ConnectWalletBox";
import { HeaderContext, HeaderContextType } from "../context/HeaderContext";
import useStyles from "./style";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
      background: "#020616",
      paddingTop: 0,
    },
    closeButton: {
      position: "absolute",
      right: 12,
      top: 12,
      color: "black",
      backgroundColor: "#D4D4D4",
      padding: 4,

      "&:hover": {
        backgroundColor: "#D4D4D4",
      },
    },
    svgIcon: {
      fontSize: 5,
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
  customClass: string;
}

export interface ComponentProps {
  opened: boolean;
  handleClose: () => void;
  width: any;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, customClass, onClose, ...other } = props;

  const customStyles = {
    color: "white",
  };

  return (
    <MuiDialogTitle
      disableTypography
      className={`${classes.root} ${customClass}`}
      {...other}
      style={customStyles}
    >
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
    color: "#999999",
  },
}))(MuiDialogContent);

const ConnectWalletModal: React.FC<ComponentProps> = (
  props: ComponentProps
) => {
  const styles = useStyles();
  const { opened, handleClose } = props;
  const { walletName, handleProviderChosen, connectWalletLoading } =
    useContext(AppContext);
  const { setAgreedTerms, agreedTerms } =
    useContext<HeaderContextType>(HeaderContext);
  const { appChainID } = useSelector((state: any) => state.appNetwork).data;
  const connectorsByNetwork = (() => {
    switch (appChainID) {
      case BSC_CHAIN_ID:
        return connectorsSupportByNetwork[APP_NETWORKS_NAME.BSC];

      case BASE_CHAIN_ID:
        return connectorsSupportByNetwork[APP_NETWORKS_NAME.BASE];

      case POLYGON_CHAIN_ID:
        return connectorsSupportByNetwork[APP_NETWORKS_NAME.POLYGON];

      case AVALANCHE_CHAIN_ID:
        return connectorsSupportByNetwork[APP_NETWORKS_NAME.AVALANCHE];

      case ARBITRUM_CHAIN_ID:
        return connectorsSupportByNetwork[APP_NETWORKS_NAME.ARBITRUM];

      case ETH_CHAIN_ID:
      default:
        return SUPPORTED_WALLETS;
    }
  })();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.target && setAgreedTerms(event.target.checked);
  };

  return (
    <Dialog open={opened} onClose={handleClose} className={styles.dialog}>
      <DialogTitle
        id="customized-dialog-title"
        onClose={handleClose}
        customClass={styles.dialogTitle}
      >
        <div className={styles.walletTitle}>
          <img
            src="/images/landing/wallet.svg"
            width={68}
            height={68}
            alt="wallet"
          />
          Connect Wallet
        </div>
      </DialogTitle>
      <DialogContent className={styles.dialogContentText}>
        <div className={`${styles.dialogContentBlock} ${styles.dialogPrivacy}`}>
          <Checkbox
            checked={agreedTerms}
            onChange={handleChange}
            inputProps={{ "aria-label": "secondary checkbox" }}
            className={styles.dialogCheckbox}
            icon={<div />}
            checkedIcon={<img src="/images/icons/checked-box.svg" />}
          />
          <span className={styles.dialogPrivacyText}>
            I read and accept the
            <a
              className={styles.dialogPrivacyHighlight}
              href="https://cdn.prod.website-files.com/65dae99a3d4f02927b6fb715/6687885118d8067532b32b76_WEBSITE%20DISCLAIMER%20%26%20Privacy%20Policy-%20RWA%20.pdf"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              Terms of Service
            </a>{" "}
            and&nbsp;
            <a
              className={styles.dialogPrivacyHighlight}
              href="https://cdn.prod.website-files.com/65dae99a3d4f02927b6fb715/6687885118d8067532b32b76_WEBSITE%20DISCLAIMER%20%26%20Privacy%20Policy-%20RWA%20.pdf"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
          </span>
        </div>
        <Typography gutterBottom className={styles.dialogContentTypo}>
          Choose Network
        </Typography>
        <div
          className={`${styles.dialogContentBlock} ${styles.dialogNetworks}`}
        >
          {Object.keys(APP_NETWORKS).map((key: string) => {
            const network = APP_NETWORKS[key as appNetworkType];
            return (
              <ConnectWalletBox key={key} appNetwork={network} isAppNetwork />
            );
          })}
        </div>
        <Typography gutterBottom className={styles.dialogContentTypo}>
          Choose Wallet
        </Typography>
        <div className={` ${styles.dialogNetworks}`}>
          {Object.keys(connectorsByNetwork).map((key: string) => {
            const network = connectorsByNetwork[key];
            const isMobile = isWidthDown("sm", props.width);
            const showConnectorInMobile = isMobile ? network?.mobile : true;
            return (
              showConnectorInMobile && (
                <ConnectWalletBox
                  key={key}
                  wallet={network}
                  isAppNetwork={false}
                  handleProviderChosen={handleProviderChosen}
                  connectWalletLoading={connectWalletLoading}
                  walletName={walletName}
                />
              )
            );
          })}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default withWidth()(ConnectWalletModal);
