import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
  return {
    winnerGrid: {
      display: "grid",
      gap: "20px",
      gridTemplateColumns: "repeat(2, minmax(0, 1fr))",

      [theme?.breakpoints?.down("sm")]: {
        gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
      },
    },
    winnerBorder: {
      padding: "20px",
      background: "#D5E3FF",
      borderRadius: "12px",
    },
    winnersNumber: {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
      marginBottom: 6,

      "& span": {
        fontSize: "16px",
        lineHeight: "19px",
        fontWeight: 600,
        color: "#000B60",
      },
      "& .sub-title": {
        fontWeight: 400,
      },
    },
    buyLimit: {
      color: "#00A82F",
      fontWeight: 600,
    },
    normalText: {
      fontSize: "16px",
      lineHeight: "19px",
      fontWeight: 600,
      color: "#000B60",
    },
    LotteryWinners: {
    },
    allocation: {
      listStyle: "disc",
      paddingLeft: 20,
    },
    searchBar: {
      display: "flex",

      [theme?.breakpoints?.down("sm")]: {
        flexDirection: "column",
      },
    },
    tabsRound: {
      height: 36,
      display: "Grid",
      gridTemplateColumns: "1fr 1fr",
      gap: 4,
      background: "#2E2E2E",
      borderRadius: 6,
      padding: 4,

      "& li": {
        font: "14px/24px ",
        padding: "2px 8px",
        cursor: "pointer",
      },
      "& .active": {
        background: "#606060",
        borderRadius: 4,
      },
    },

    title: {
      fontFamily: "",
      fontWeight: "bold",
      fontSize: 16,
      lineHeight: "24px",
      marginBottom: 20,

      [theme?.breakpoints?.down("sm")]: {
        textAlign: "center",
        fontSize: 16,
        lineHeight: "24px",
      },
    },

    title2: {
      fontFamily: "",
      fontSeight: "500",
      fontSize: 16,
      lineHeight: "24px",
      marginBottom: 6,
    },

    LotteryWinnersDesc: {
      marginTop: 15,
      marginBottom: 16,
      font: "normal normal normal 14px/24px ",
    },

    LotteryWinnersMessage: {
      marginBottom: 16,
      font: "normal normal normal 14px/24px ",
      fontWeight: "bold",
      fontSize: 15,
      color: "#8db4ff",
    },

    table: {
      "& .MuiTableBody-root td": {
        font: "normal normal normal 14px/24px ",
      },
      borderCollapse: "separate",
      borderSpacing: "0 6px",
    },

    tableContainer: {
      width: "100%",
      background: "transparent",
      marginTop: 12,

      "& th, & td": {
        fontFamily: "",
        fontSize: 16,
        lineHeight: "24px",
        padding: "10px 16px",
        color: "#FFFFFF",
      },
      "& th:first-child": {
        borderTopLeftRadius: 6,
        borderBottomLeftRadius: 6,
      },
      "& th:last-child": {
        borderTopRightRadius: 6,
        borderBottomRightRadius: 6,
      },

      "& th": {
        border: 0,
        [theme?.breakpoints?.down("sm")]: {
          fontSize: 14,
          lineHeight: "20px",
        },
      },

      "& tr": {
        border: 0,
        background: "#2E2E2E",
      },
    },

    tableHeaderWrapper: {
      "& th": {
        font: "normal normal bold 14px/18px ",
        color: "#AEAEAE",
        [theme?.breakpoints?.down("sm")]: {
          fontSize: 14,
          fontFamily: "",
          fontWeight: "bold",
        },
      },

      "& tr th:first-child": {
        width: 100,
      },
      "& tr": {
        background: "transparent",
      },
    },

    tableSearchWrapper: {
      maxWidth: 360,
      width: "100%",
      marginLeft: "auto",
      position: "relative",
      background: "#292929",
      border: "1px solid #44454B",
      borderRadius: 4,
    },

    tableSearchIcon: {
      position: "absolute",
      right: 10,
      top: "50%",
      transform: "translateY(-50%)",
    },

    tableSearch: {
      font: "normal normal 400 14px/24px ",
      background: "transparent",
      padding: "6px 0px 6px 12px",
      border: "none",
      color: "white",
      width: "90%",

      "&:focus": {
        outline: "none",
      },

      "&::placeholder": {
        color: "#AEAEAE",
        fontWeight: "normal",
      },
    },

    pagination: {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: "12px !important",
      "& *": {
        color: "white",
      },
      "& li": {
        "& .pagination-item": {
          backgroundColor: "transparent !important",
          color: "#AEAEAE",
          "&.Mui-selected": {
            color: "#D01F36",
          },
        },
        "&:first-child .pagination-item": {
          backgroundColor: "#2E2E2E !important",
          borderRadius: 6,
          color: "#FFFFFF",
        },
        "&:last-child .pagination-item": {
          backgroundColor: "#2E2E2E !important",
          borderRadius: 6,
          color: "#FFFFFF",
        },
      },
    },
  };
});

export default useStyles;
