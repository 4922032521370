import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
  return {
    tierTitle: {
      fontSize: "32px",
      lineHeight: "32px",
      fontWeight: 600,
      color: "#000B60",
    },

    listInfo: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr 1fr",
      textAlign: "center",
      background: "#222228",
      borderRadius: 8,
      marginBottom: 12,

      [theme.breakpoints.down("sm")]: {
        gridTemplateColumns: "1fr",
      },
    },

    itemInfo: {
      padding: 16,
      borderRight: "1px solid rgb(255 255 255 / 10%)",
      fontFamily: "",
      fontWeight: "bold",
      minHeight: 90,

      [theme.breakpoints.down("sm")]: {
        minHeight: 90,
        borderBottom: "1px solid rgb(255 255 255 / 10%)",
        borderRight: "none",

        "&:last-child": {
          borderBottom: "none",
        },
      },

      "&:last-child": {
        borderRight: "none",
      },
    },

    nameItemInfo: {
      fontSize: 14,
      lineHeight: "18px",
      color: "#FFFFFF",
      marginBottom: 10,
    },

    valueItemInfo: {
      fontSize: 22,
      lineHeight: "24px",
      minHeight: 24,
      color: "#6398FF",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "center",
      wordBreak: "break-word",
      display: "flex",
    },

    iconUserTier: {
      height: 20,
      marginRight: 5,
    },

    message: {
      background: "rgb(255 255 255 / 10%)",
      borderRadius: 8,
      fontFamily: "",
      fontSize: 14,
      lineHeight: "20px",
      color: "#FFFFFF",
      padding: "11px 12px",
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
      marginBottom: 35,

      [theme.breakpoints.down("sm")]: {
        padding: "12px 20px",
        marginBottom: 30,
      },

      "& img": {
        marginRight: 6,

        [theme.breakpoints.down("sm")]: {
          display: "none",
        },
      },
    },

    menuTier: {
      display: "flex",
      gap: "8px",
      paddingTop: "12px",
      listStyle: "none",
    },

    itemTabMyTier: {
      padding: "10px 20px 12px",
      borderRadius: 6,
      fontSize: "16px",
      lineHeight: "19px",
      fontWeight: 600,
      color: "#000B60",
      cursor: "pointer",
      "&.active": {
        background: "#D5E3FF",
        color: "#0058FF",
      },
    },

    tierComponent: {
      transition: "1s",
      color: "#fff",
      borderRadius: "8px",
      "&.inactive": {
        opacity: 0,
      },
      "&.active": {
        opacity: 1,
      },
      "&.bg-none": {
        background: "none",
        padding: "0",
      },
    },

    bodyPage: {
      [theme.breakpoints.down("sm")]: {
        padding: "25px 0px",
        paddingBottom: 0,
      },
      [theme.breakpoints.only("xs")]: {
        paddingTop: 4,
      },
    },

    btnHow: {
      marginTop: 20,
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      width: "100%",
      background: "#444449",
      border: "1px solid #58585A",
      borderRadius: 8,
      minHeight: 46,
      padding: "10px 16px",
      fontFamily: "",
      fontWeight: "bold",
      fontSize: 14,
      lineHeight: "18px",
      color: "#FFFFFF",
      cursor: "pointer",

      "&:hover": {
        color: "#FFFFFF",
        opacity: 0.85,
        textDecoration: "inherit",
      },
    },
    lineLearMore: {
      borderTop: "1px solid rgba(255, 255, 255, 0.1)",
      marginBottom: -12,
      marginTop: 32,
    },

    iconBtnHow: {
      marginRight: 8,
    },

    iconArrowRight: {
      marginLeft: "auto",
    },
    [theme.breakpoints.only("xs")]: {
      tierTitle: {
        display: "none",
      },
    },
  };
});

export default useStyles;
