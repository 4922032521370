import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  return {
    refundDialog: {
      "& .MuiPaper-root": {
        padding: 40,
        paddingBottom: 28,
        backgroundColor: "#FFFFFF",
        borderRadius: 28,
        border: "none",
        maxWidth: 400,
        width: 400,
        textAlign: "center",

        [theme?.breakpoints?.down("sm")]: {
          padding: "30px 20px",
        },
      },
    },
    btnClose: {
      position: "absolute",
      width: 24,
      height: 24,
      cursor: "pointer",
      top: 12,
      right: 12,
    },
    iconRefund: {
      width: 60,
      height: 60,
      margin: "0 auto",
    },
    titleRefund: {
      marginTop: 12,
      color: "#000B60",
      textAlign: "center",
      fontSize: "32px",
      lineHeight: "32px",
      fontWeight: 600,
    },
    contentRefund: {
      maxWidth: "220px",
      margin: "4px auto 0",
      color: "#000B60",
      fontSize: "16px",
      lineHeight: "19px",
      textAlign: "center",
    },
    groupButton: {
      display: "Grid",
      gridTemplateColumns: "1fr 1fr",
      gap: 8,
      marginTop: 20,
      "& .btn": {
        padding: "12px 40px 14px",
        borderRadius: 60,
        border: "none",
        cursor: "pointer",
        outline: "none",
        fontSize: "14px",
        lineHeight: "16px",
        transition: "0.25s all",
        fontWeight: 600,

        "&:hover": {
          opacity: "0.85",
        },
      },
      [theme.breakpoints.down("xs")]: {
        gridTemplateColumns: "1fr",
        gap: 12,
      },
    },
    btnConfirm: {
      background: "#0058FF",
      color: "#fff",
    },
    btnCancel: {
      background: "#D5E3FF",
      color: "#0058FF",
    },
  };
});

export default useStyles;
