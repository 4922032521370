export const TRANSACTION_ERROR =
  "Transaction failed. Please check blockchain to know more error.";
export const API_URL_PREFIX = "user";
export const ADMIN_URL_PREFIX = "dashboard";
export const IMAGE_URL_PREFIX = "image";
export const MAX_BUY_CAMPAIGN = 1000;
export const WHITELIST_LINK = "https://www.forms.gle/HiQkhaRM8mujeryq8";
export const INSTRUCTION_WHITELIST_LINK =
  "https://www.medium.com/polkafoundry/nftify-whitelist-on-red-kite-launchpad-on-june-4-2021-26cd4b8ebc8d";
export const APPLY_TO_LAUNCH_URL = "";
export const DATETIME_FORMAT = "YYYY-MM-DD HH:mm:ss";

export const POOL_STATUS = {
  TBA: 0,
  UPCOMING: 1,
  JOINING: 2,
  IN_PROGRESS: 3,
  FILLED: 4,
  CLOSED: 5,
  CLAIMABLE: 6,
};
export const POOL_STATUS_TEXT = {
  [POOL_STATUS.TBA]: "TBA",
  [POOL_STATUS.UPCOMING]: "Upcoming",
  [POOL_STATUS.FILLED]: "Filled",
  [POOL_STATUS.IN_PROGRESS]: "Swap",
  [POOL_STATUS.CLAIMABLE]: "Claimable",
  [POOL_STATUS.CLOSED]: "Ended",
};

export const NULL_AMOUNT = "N/A";
export const POOL_STATUS_JOINED = {
  NONE: "NONE",
  APPLIED_WHITELIST: "APPLIED_WHITELIST",
  WIN_WHITELIST: "WIN_WHITELIST",
  NOT_WIN_WHITELIST: "NOT_WIN_WHITELIST",
  CANCELED_WHITELIST: "CANCELED_WHITELIST",
  SWAPPING: "SWAPPING",
  CLAIMABLE: "CLAIMABLE",
  COMPLETED: "COMPLETED",
};

export const NETWORK = {
  ETHEREUM: "eth",
  BSC: "bsc",
  BASE: "base",
  POLYGON: "polygon",
  SOLANA: "solana",
  TERRA: "terra",
  FANTOM: "fantom",
  AVALANCHE: "avalanche",
  ARBITRUM: "arbitrum",
};
export const NETWORK_TEXT = {
  [NETWORK.ETHEREUM]: "Ethereum",
  [NETWORK.BSC]: "BNB Chain",
  [NETWORK.POLYGON]: "Polygon",
  [NETWORK.SOLANA]: "Solana",
  [NETWORK.TERRA]: "Terra",
  [NETWORK.FANTOM]: "Fantom",
  [NETWORK.AVALANCHE]: "Avalanche",
  [NETWORK.ARBITRUM]: "Arbitrum",
  [NETWORK.BASE]: "Base",
};
export const NETWORK_TEXT_SHORT = {
  [NETWORK.ETHEREUM]: "ETH",
  [NETWORK.BSC]: "BSC",
  [NETWORK.POLYGON]: "MATIC",
  [NETWORK.SOLANA]: "SOL",
  [NETWORK.TERRA]: "LUNA",
  [NETWORK.FANTOM]: "FTM",
  [NETWORK.AVALANCHE]: "AVAX",
  [NETWORK.ARBITRUM]: "ARBI",
  [NETWORK.BASE]: "BASE",
};
export const NETWORK_SRC_ICON = {
  [NETWORK.ETHEREUM]: "/images/network/ETH.svg",
  [NETWORK.BSC]: "/images/network/BSC.svg",
  [NETWORK.POLYGON]: "/images/network/polygon.svg",
  [NETWORK.SOLANA]: "/images/network/solana.svg",
  [NETWORK.TERRA]: "/images/network/terra.svg",
  [NETWORK.FANTOM]: "/images/network/fantom.svg",
  [NETWORK.AVALANCHE]: "/images/network/avalanche.svg",
  [NETWORK.ARBITRUM]: "/images/network/arbitrum.svg",
};

export const ACCEPT_CURRENCY = {
  ETH: "eth",
  BUSD: "busd",
  USDT: "usdt",
  USDC: "usdc",
};

export const BUY_TYPE = {
  WHITELIST_LOTTERY: "whitelist",
  FCFS: "fcfs",
};

export const POOL_TYPE = {
  SWAP: "swap",
  CLAIMABLE: "claimable",
};
export const CLAIM_TYPE = {
  CLAIM_ON_LAUNCHPAD: "claim-on-launchpad",
  AIRDROP_TO_PARTICIPANTS_WALLETS: "airdrop-to-participants-wallet",
  CLAIM_A_PART_OF_TOKENS_ON_LAUNCHPAD: "claim-a-part-of-tokens-on-launchpad",
  CLAIM_ON_THE_PROJECT_WEBSITE: "claim-on-the-project-website",
};
export const CLAIM_TYPE_TEXT = {
  [CLAIM_TYPE.CLAIM_ON_LAUNCHPAD]: "Claim on RWA Launchpad",
  [CLAIM_TYPE.AIRDROP_TO_PARTICIPANTS_WALLETS]: `Airdrop to participants' wallets`,
  [CLAIM_TYPE.CLAIM_A_PART_OF_TOKENS_ON_LAUNCHPAD]:
    "Claim a part of tokens on RWA",
  [CLAIM_TYPE.CLAIM_ON_THE_PROJECT_WEBSITE]: "Claim on the project website",
};
export const CLAIM_TYPE_DESCRIPTION = {
  [CLAIM_TYPE.CLAIM_ON_LAUNCHPAD]:
    "You claim your purchased tokens via RWA Launchpad.",
  [CLAIM_TYPE.AIRDROP_TO_PARTICIPANTS_WALLETS]: `The tokens will be airdropped straight to the wallet you used to swap tokens.`,
  [CLAIM_TYPE.CLAIM_A_PART_OF_TOKENS_ON_LAUNCHPAD]:
    "You can claim a portion of your tokens on RWA, and the rest will be claimed via the project website or vesting portal.",
  [CLAIM_TYPE.CLAIM_ON_THE_PROJECT_WEBSITE]:
    "Instead of the RWA website, 100% of tokens are claimed on the project website.",
};

export const PUBLIC_WINNER_STATUS = {
  PUBLIC: 1,
  PRIVATE: 0,
};
export const POOL_IS_PRIVATE = {
  PUBLIC: 0,
  PRIVATE: 1,
  SEED: 2,
  COMMUNITY: 3,
  EVENT: 4,
};

export const USER_STATUS = {
  UNVERIFIED: 0,
  ACTIVE: 1,
  BLOCKED: 2,
  DELETED: 3,
};

export const TOKEN_STAKE_SYMBOLS = {
  RWA: "RWA",
  LP_RWA: "LP-RWA",
};

export const TOKEN_STAKE_NAMES = {
  RWA: "RWA",
  LP_RWA: "RWA-ETH LP",
};

export const TIERS = [
  {
    name: "Base",
    icon: "/images/account_v3/base_1.jpg",
    bg: "/images/icons/red-kite-bg.png",
    bgColor: "#666666",
  },
  {
    name: "Bronze",
    bg: "/images/icons/bronze-bg.png",
    bgColor: "#666666",
    icon: "/images/account_v3/bronze_1.jpg",
  },
  {
    name: "Silver",
    bg: "/images/icons/silver-bg.png",
    bgColor: "#666666",
    icon: "/images/account_v3/silver_1.jpg",
  },
  {
    name: "Gold",
    bg: "/images/icons/gold-bg.png",
    bgColor: "#666666",
    icon: "/images/account_v3/gold_1.jpg",
  },
  {
    name: "Platinum",
    bg: "/images/icons/gold-bg.png",
    bgColor: "#666666",
    icon: "/images/account_v3/platinum_1.jpg",
  },
  {
    name: "Diamond",
    bg: "/images/icons/diamond-bg.png",
    bgColor: "",
    icon: "/images/account_v3/diamond_1.jpg",
  },
];

export const TIER_LEVELS = {
  NONE: 0,
  BRONZE: 1,
  SLIVER: 2,
  GOLD: 3,
  PLATINUM: 4,
  DIAMOND: 5,
};

export const TIER_NAMES = {
  0: "--",
  1: "Bronze",
  2: "Silver",
  3: "Gold",
  4: "Platinum",
  5: "Diamond"
};

export const KYC_STATUS = {
  INCOMPLETE: 0,
  APPROVED: 1,
  RESUBMIT: 2,
  WAITING: 3,
  INREVIEW: 4,
};

export const GAS_LIMIT_CONFIGS = {
  APPROVE: "80000",
  DEPOSIT: "250000",
  CLAIM: "120000", 
  APPROVE_SOTA_TOKEN: "200000",
  STAKE_SOTA_TIERS: "120000",
  UNSTAKE_SOTA_TIERS: "100000",
};

export const NETWORK_AVAILABLE = {
  ETH: "eth",
  BASE: "base",
  BSC: "bsc",
  POLYGON: "polygon",
  AVALANCHE: "avalanche",
  ARBITRUM: "arbitrum",
};

export const ETHERSCAN_BASE_URL: any = {
  "1": "https://etherscan.io",
  "4": "https://rinkeby.etherscan.io",
  "5": "https://goerli.etherscan.io",
  "56": "https://bscscan.com",
  "97": "https://testnet.bscscan.com",
  "137": "https://polygonscan.com",
  "8453": "https://basescan.org",
  "80001": "https://mumbai.polygonscan.com/",
  "84532": "https://base-sepolia.blockscout.com",
};

export const REFUND_TOKEN_TYPE = {
  REFUND: "refund",
  CLAIM: "claim",
};

export const breadcrumbType: any = {
  my_profile: { name: "My Profile", url: "my_profile", isDisable: false },
  my_tier: { name: "My Tier", url: "my_tier", isDisable: false },
  my_pools: { name: "My IDO Pools", url: "my_pools", isDisable: false },
  help: { name: "Need Help", url: "help", isDisable: false },
  "staking-pools": {
    name: "Staking Pools",
    url: "staking-pools",
    isDisable: false,
  },
  "buy-token": { name: "Pool", url: "pools", isDisable: false },
  dashboard: { name: "dashboard", url: "dashboard", isDisable: false },
  "confirm-email": {
    name: "Confirm Email",
    url: "confirm-email",
    isDisable: false,
  },
  account: { name: "account", url: "account", isDisable: true },
  pools: { name: "pools", url: "pools", isDisable: false },
  terms: { name: "terms", url: "terms", isDisable: false },
  privacy: { name: "privacy", url: "privacy", isDisable: false },
  calendar: { name: "calendar", url: "calendar", isDisable: false },
};
