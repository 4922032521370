import {
  Button,
  ButtonGroup,
  FormControlLabel,
  NativeSelect,
  Switch,
} from "@material-ui/core";
import { useEffect } from "react";
import useCommonStyle from "../../../styles/CommonStyle";
import { useButtonGroupStyle, useSwitchStyle } from "./style";

const iconSearch = "images/icons/search.svg";

export const DURATION_LIVE = "live";
export const DURATION_FINISHED = "finished";

export const POOL_TYPE_ALLOC = "alloc";
export const POOL_TYPE_LINEAR = "linear";

export const BENEFIT_ALL = "all";
export const BENEFIT_IDO_ONLY = "ido-only";
export const BENEFIT_REWARD_ONLY = "reward-only";

const StakingHeader = (props: any) => {
  const {
    durationType,
    setDurationType,
    poolType,
    setPoolType,
    stakedOnly,
    setStakedOnly,
    benefitType,
    setBenefitType,
    searchString,
    setSearchString,
  } = props;
  const commonStyles = useCommonStyle();
  const switchStyle = useSwitchStyle();
  const btnGroupStyle = useButtonGroupStyle();

  useEffect(() => {
    const query = new URLSearchParams(props.location.search);
    const queryBenefit = query.get("benefit");
    switch (queryBenefit) {
      case BENEFIT_ALL:
      case BENEFIT_IDO_ONLY:
      case BENEFIT_REWARD_ONLY:
        setBenefitType(queryBenefit);
        break;
    }
  }, []);

  const selectBenefitType = (type: string) => {
    setBenefitType(type);
    props.history.push("/staking-pools?benefit=" + type);
  };

  const getClassActive = (type: "duration" | "alloc", currentType: string) => {
    let className = "";
    if (type === "duration") {
      className =
        durationType === currentType
          ? btnGroupStyle.btnActive
          : btnGroupStyle.btnDisabled;
    }
    if (type === "alloc") {
      className =
        poolType === currentType
          ? btnGroupStyle.btnActive
          : btnGroupStyle.btnDisabled;
    }
    return className;
  };

  return (
    <div className="controller-area">
      <div className={btnGroupStyle.btnHeaderGroup}>
        <ButtonGroup
          color="primary"
          className={btnGroupStyle.group + " " + btnGroupStyle.btnDurationType}
          aria-label="outlined primary button group"
        >
          <Button
            className={getClassActive("duration", DURATION_LIVE)}
            onClick={() => {
              setDurationType(DURATION_LIVE);
            }}
          >
            Live
          </Button>
          <Button
            className={getClassActive("duration", DURATION_FINISHED)}
            onClick={() => {
              setDurationType(DURATION_FINISHED);
            }}
          >
            Finished
          </Button>
        </ButtonGroup>

        <ButtonGroup
          color="primary"
          style={{ display: "none" }}
          className={btnGroupStyle.group + " " + btnGroupStyle.btnStakingType}
          aria-label="outlined primary button group"
        >
          <Button
            className={getClassActive("alloc", POOL_TYPE_ALLOC)}
            onClick={() => {
              setPoolType(POOL_TYPE_ALLOC);
            }}
          >
            Allocation
          </Button>
          <Button
            className={getClassActive("alloc", POOL_TYPE_LINEAR)}
            onClick={() => {
              setPoolType(POOL_TYPE_LINEAR);
            }}
          >
            Linear Rate
          </Button>
        </ButtonGroup>
      </div>

      <FormControlLabel
        control={
          <Switch
            name="checkedB"
            checked={stakedOnly}
            onChange={(event) => {
              setStakedOnly(event.target.checked);
            }}
            classes={{
              root: switchStyle.root,
              switchBase: switchStyle.switchBase,
              thumb: switchStyle.thumb,
              track: switchStyle.track,
              checked: switchStyle.checked,
            }}
          />
        }
        label="My Staking Pools"
        className={switchStyle.switchGroup}
      />

      <div className="controller-area__right">
        {/* <div className="form-control-label">
          <NativeSelect
            defaultValue="all"
            className={btnGroupStyle.poolTypeSelect}
            inputProps={{
              name: "select_benefit",
              id: "select-benefit",
            }}
            value={benefitType}
            onChange={(e) => selectBenefitType(e.target.value)}
          >
            <option value="all">All pools</option>
            <option value="ido-only">With IDO pools</option>
            <option value="reward-only">Without IDO pools</option>
          </NativeSelect>
        </div> */}
        <div className="form-control-label">
          <div className="controller-area__search">
            <input
              value={searchString}
              onChange={(e) => setSearchString(e.target.value)}
              type="text"
              placeholder="Search by Pool name"
              className={`${commonStyles.nnn1424h} ${btnGroupStyle.controleForm}`}
            />
            <img src={iconSearch} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StakingHeader;
