import configureStore from "../store/configureStore";
import { WalletConnectionState } from "../store/reducers/wallet";

export const getConnectedAddress = () => {
  let currentWallet = null;
  const walletsInfo = configureStore().store.getState().wallet.entities;
  if (walletsInfo) {
    let isFound = false;

    Object.keys(walletsInfo).forEach((key) => {
      const wallet = walletsInfo[key];

      if (
        wallet.addresses.length > 0 &&
        wallet.connectionState === WalletConnectionState.CONNECTED &&
        !isFound
      ) {
        isFound = true;
        currentWallet = wallet.addresses[0];
      }
    });
  }
  return currentWallet;
};
