import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
  return {
    cardImage: {
      width: "52px",
      height: "52px",
      background: "#D01F36",
      borderRadius: 18,
      marginBottom: 32,
      display: "flex",
      justifyContent: "center",
      boxShadow: "2px 2px 2px #D01F36",
      position: "relative",

      "&:before": {
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        borderRadius: "18px",
        background: "#D01F36",
        filter: "blur(32px)",
      },

      "& img": {
        width: "32px",
        zIndex: 2,
      },
    },
    mainContent: {
      "& span.card__title": {
        color: "#FFFFFF",
        marginTop: 0,
      },

      "& p": {
        marginTop: 12,
        color: "rgba(255, 255, 255, 0.6)",
      },
    },
    cardContainer: {
      cursor: "pointer",
      display: "flex",
      flexDirection: "column",
      border: "1px solid #A5CCFF",
      borderRadius: "20px",
      transition: ".2s all ease-in",
      background: "linear-gradient(91.5deg, #ECF5FF 1.28%, #FFFFFF 135.26%)",
      "& span": {
        font: "14px/24px ",
      },
      "&:hover": {
        background:
          "linear-gradient(142.64deg, #0058FF 24.84%, #FFFFFF 161.73%)",
        "& .number-day": {
          background: "#FFFFFF",
        },
        "& div, p, span": {
          color: "#FFFFFF !important",
        },
        "& button": {
          background: "#FFFFFF",
          color: "#0058FF",
        },
      },
      "& .sub-title": {
        color: "#AEAEAE",
        opacity: 0.8,
        textAlign: "left",
      },
      "&:last-child": {
        marginRight: 0,
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    tooltip: {
      font: "normal normal 500 14px/24px ",
      color: "#FFFFFF",
      padding: "5px 10px",
    },
    cardTitle: {
      fontSize: "24px",
      lineHeight: "24px",
      fontWeight: 600,
      color: "#0055D6",
    },
    cardSubTitle: {
      fontSize: "16px",
      lineHeight: "19px",
      color: "#000B60",
    },
    subInfoContent: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginTop: 20,
      paddingBottom: "8px",
    },
    subInfo: {
      "& div:first-child": {
        fontSize: "16px",
        lineHeight: "19px",
        color: "#000B60",
      },
      "& div:last-child": {
        fontSize: "18px",
        lineHeight: "21px",
        color: "#0055D6",
        fontWeight: 600,
      },
    },
    btn: {
      "& button": {
        width: "100%",
        height: "44px",
        marginTop: "14px",
        padding: "0 80px 4px",
        borderRadius: "60px",
        background: "#0058FF",
        border: "none",
        fontSize: "14px",
        lineHeight: "16px",
        fontWeight: 600,
        color: "#FFFFFF",
      },
    },
    layerContainer: {
      padding: "21px 20px 16px",
      position: "relative",
    },
    layerTitle: {
      display: "flex",
      gap: "8px",
    },
    numberDay: {
      display: "flex",
      width: "40px",
      minWidth: "40px",
      height: "40px",
      alignItems: "center",
      justifyContent: "center",
      background: "#FFFFFF",
      borderRadius: "50%",
      fontSize: "18px",
      lineHeight: "21px",
      color: "#FFFFFF",
      fontWeight: 900,
    },
    [theme.breakpoints.down("sm")]: {
      btn: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "& button": {
          width: "256px",
          height: "44px",
          marginTop: "14px",
          padding: "0 80px 4px",
          borderRadius: "60px",
          background: "#0058FF",
          border: "none",
          fontSize: "16px",
          lineHeight: "20px",
          fontWeight: 600,
          color: "#FFFFFF",
        },
      },
    },
  };
});

export default useStyles;
