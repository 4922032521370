import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
  return {
    messageUpdateSuccess: {
      position: "relative",
      width: "100%",
      padding: "11px 9px 10px 9px",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      background: "#244A9C",
      marginBottom: 15,
      borderRadius: "8px",
      minHeight: 42,
      font: "14px/20px ",
      color: "#FFFFFF",

      [theme.breakpoints.down("sm")]: {
        alignItems: "flex-start",
      },

      "& img": {
        marginRight: 6,
      },
    },

    alertVerifyEmail: {
      position: "relative",
      width: "100%",
      padding: 9,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      background: "#591425",
      marginBottom: 15,
      borderRadius: "8px",
      minHeight: 42,

      [theme.breakpoints.down("sm")]: {
        alignItems: "flex-start",
      },

      "& img": {
        marginTop: 3,
        [theme.breakpoints.down("sm")]: {
          marginTop: 6,
        },
      },

      "& .btn-close": {
        position: "absolute",
        top: "50%",
        right: "15px",
        transform: "translateY(-50%)",
      },

      "& span": {
        font: "normal normal 400 14px/24px ",
        color: "#FFFFFF",
      },

      "& a": {
        color: "#6398FF",
      },
    },

    errorSwich: {
      marginBottom: 20,
    },

    errorBanner: {
      color: "white",
      backgroundColor: "#FF4C00",
      textAlign: "center",
      padding: 12,
      marginBottom: 0,
      flex: 1,
    },
    title: {
      font: "normal normal bold 28px/32px ",
      color: "#FFF",
      position: "relative",

      "&:after": {
        content: '""',
        display: "block",
        width: "100%",
        height: "1px",
        backgroundColor: "rgba(255, 255, 255, 0.1)",
        position: "absolute",
        bottom: "-10px",
      },
    },
    mainContent: {
      display: "grid",
      gridTemplateColumns: "5fr 4fr",
      gap: "100px",
      marginTop: "10px",
      marginBottom: "120px",
      position: "relative",
      [theme.breakpoints.down("md")]: {
        gridTemplateColumns: "1fr",
        marginBottom: "60px",
      },
      [theme.breakpoints.down("sm")]: {
        gridTemplateColumns: "1fr",
        padding: "40px",
        paddingTop: "150px",
      },
      [theme.breakpoints.only("xs")]: {
        gridTemplateColumns: "1fr",
        padding: "20px",
        paddingTop: "100px",
      },
    },
    leftPanel: {},
    rightPanel: {
      maxWidth: "100%",
      width: "100%",
    },
    accountContainer: {
      padding: "18px 20px 60px",
      minHeight: "calc(100vh - 400px)",
      maxWidth: 1320,
      margin: "auto",

      [theme.breakpoints.down("xs")]: {
        paddingTop: 16,
        paddingBottom: 40,
        margin: "auto",
      },
    },
    [theme.breakpoints.down("xs")]: {
      mainContent: {
        padding: "20px 0",
        marginBottom: "80px",
        gap: "60px",
      },
    },

    tier: {
      padding: "20px 40px 24px 40px",
      [theme.breakpoints.down("sm")]: {
        padding: "20px 20px",
        border: "none",
      },
      [theme.breakpoints.only("xs")]: {
        padding: "10px",
        background: "none",
      },
    },
    bodyContentMyAccount: {
      display: "grid",
      gridTemplateColumns: "240px 1fr",
      color: "#FFFFFF",

      [theme.breakpoints.down("sm")]: {
        gridTemplateColumns: "1fr",
      },
    },

    leftAccount: {
      padding: "0 30px 0 0",

      [theme.breakpoints.down("sm")]: {
        padding: 0,
      },
    },

    titlLeft: {
      fontFamily: "",
      fontWeight: 600,
      fontSize: 20,
      lineHeight: "24px",
      color: "#FFFFFF",
      marginBottom: 27,

      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },

    tabAccount: {
      display: "flex",
      flexDirection: "column",
      gap: "6px",

      [theme.breakpoints.down("sm")]: {
        flexDirection: "row",
        paddingBottom: 12,
        whiteSpace: "nowrap",
        width: "calc(100vw - 56px)",
      },
    },

    itemTabAccount: {
      display: "flex",
      padding: "1px",
      borderRadius: 12,
      "& div": {
        display: "flex",
        width: "100%",
        padding: "12px 8px",
        flexWrap: "wrap",
        alignItems: "center",
        fontFamily: "",
        fontWeight: 600,
        cursor: "pointer",
        fontSize: "16px",
        lineHeight: "19px",
        color: "#000B60",
        borderRadius: 11,
      },
      "& img": {
        width: 20,
        height: 20,
        marginRight: 8,
      },

      [theme.breakpoints.down("sm")]: {
        margin: 0,
        flexWrap: "unset",

        "&:last-child": {
          marginRight: 0,
        },
        "& div": {
          padding: "12px 4px",
        },
      },

      "&.active": {
        padding: "1px",
        borderRadius: 12,
        background: "linear-gradient(90deg, #A5CCFF 0%, #E7F2FF 100%)",
        "& div": {
          background: "#D5E3FF",
        },
      },
    },

    iconItemTabAccount: {
      marginRight: 8,
      width: 16,
      height: 16,
      maskPositionX: "center",
      maskPositionY: "center",
      maskSize: "contain",
      maskRepeatX: "no-repeat",
      maskRepeatY: "no-repeat",
      maskOrigin: "initial",
      maskClip: "initial",
      background: "#AEAEAE",

      "&.active": {
        background: "#6398FF",
      },

      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },

    rightAccount: {
      padding: "1px",
      borderRadius: 20,
      background: "linear-gradient(90deg, #A5CCFF 0%, #E7F2FF 100%)",
    },
    rightAccountBack: {
      overflow: "hidden",
      borderRadius: 19,
      background: "linear-gradient(235.66deg, #ECF5FF 29.71%, #FFFFFF 118.81%)",
    },

    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  };
});

export default useStyles;
