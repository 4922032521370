import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
  return {
    modalStake: {
      "& .MuiPaper-root": {
        [theme.breakpoints.down("xs")]: {
          margin: "10px",
        },
      },
      "& .icon-fire": {
        top: "-6px",
        right: "-2px",
      },
    },

    warning: {
      background: "rgba(208, 31, 54, 0.15)",
      borderRadius: 4,
      marginBottom: 27,
      padding: 12,
      paddingLeft: 0,
      font: "normal normal normal 12px/16px ",
      display: "flex",
      color: "red",

      "& img": {
        margin: "2px 6px auto 8px",
      },
    },

    notice: {
      font: "normal normal bold 14px/18px ",
      color: "#FFF",
      marginTop: "30px",
    },
    [theme.breakpoints.down("xs")]: {
      modalTransaction: {
        "& .modal-content__body": {
          padding: "0",
        },
      },
    },
  };
});

export default useStyles;
