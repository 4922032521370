import { makeStyles } from "@material-ui/core";
import zIndex from "@material-ui/core/styles/zIndex";

const useStyles = makeStyles((theme: any) => {
  return {
    card: {
      borderRadius: "20px",
      overflow: "hidden",
    },
    cardContentTop: {
      position: "relative",
      display: "grid",
      background: "#ECF5FF",
      borderRadius: "20px",
      overflow: "hidden",
      zIndex: 1,
    },
    poolStatusWarning: {
      position: "absolute",
      top: "8px",
      left: "8px",
      padding: "4px 12px 5px",
      background: "#ECF5FF",
      borderRadius: "20px",
      fontSize: "12px",
      lineHeight: "14px",
      fontWeight: 600,
      color: "#0058FF",
      textTransform: "uppercase",
    },
    cardContentBot: {
      borderRadius: "0 20px",
    },
    imgProject: {
      width: "100%",
      height: "210px",
      objectFit: "cover",
    },
    runTimeTitle: {
      position: "relative",
      width: "100%",
      "& div": {
        position: "absolute",
        top: "-24px",
        left: 0,
        padding: "0px 12px 1px 12px",
        background: "#0058FF",
        borderRadius: "19.37px",
        fontSize: "12px",
        lineHeight: "14px",
        fontWeight: 600,
        color: "#ECF5FF",
        transitionDuration: "150ms",
      },
    },
    runTime: {
      height: "4px",
      background: "#ffffff",
      overflow: "hidden",
      "& div": {
        width: "0%",
        height: "100%",
        background: "#0055D6",
        transitionDuration: "150ms",
      },
    },
    projectInfo: {
      padding: "12px 15px 15px",
    },
    projectName: {
      display: "flex",
      gap: "8px",
      alignItems: "center",
      "& img": {
        borderRadius: "12px",
      },
      "& h6": {
        fontSize: "24px",
        lineHeight: "24px",
        fontWeight: 600,
        color: "#0055D6",
      },
      "& p": {
        fontSize: "16px",
        lineHeight: "19px",
        fontWeight: 400,
        color: "#000000",
      },
    },
    projectData: {
      display: "flex",
      paddingTop: "11px",
      justifyContent: "space-between",
      "& p": {
        fontSize: "16px",
        lineHeight: "19px",
        fontWeight: 400,
        color: "#000000",
      },
      "& h6": {
        fontSize: "18px",
        lineHeight: "18px",
        fontWeight: 700,
        color: "#0055D6",
      },
      "& div:last-child": {
        textAlign: "right",
      },
    },
    cardBotData: {
      display: "grid",
      gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
      height: "47px",
    },
    cardBotLeft: {
      position: "relative",
      display: "flex",
      paddingTop: "8px",
      alignItems: "center",
      "& p": {
        position: "relative",
        padding: "0 15px",
        fontSize: "16px",
        lineHeight: "16px",
        fontWeight: 700,
        color: "#0055D6",
        zIndex: 1,
      },
      "& div": {
        position: "absolute",
        width: "150%",
        height: "200%",
        top: "-100%",
        right: "0",
        transform: "skew(-25deg)",
        background: "#ECF5FF",
        borderRadius: "0 0 15px 0",
        zIndex: 0,
      },
    },
    cardBotRight: {
      display: "flex",
      paddingTop: "4px",
      paddingRight: "2px",
      justifyContent: "end",
      "& button": {
        padding: "12px 25px 14px",
        background: "#0058FF",
        border: "none",
        borderRadius: "45px",
        fontSize: "16px",
        lineHeight: "16px",
        color: "#FFFFFF",
      },
    },
  };
});

export default useStyles;
