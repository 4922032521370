import React from 'react';
import InvestorLayout from "../InvestorLayout/InvestorLayout";
import TextTitle from "../InvestorLayout/TextTitle";

const NotFoundPage = () => {

  return (
    <InvestorLayout>
      <TextTitle>
        <h1>Not Found !!!</h1>
      </TextTitle>
    </InvestorLayout>
  );
};

export default NotFoundPage;
