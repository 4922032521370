import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  return {
    dialog: {
      position: "relative",
      "& img": {
        width: 60,
        height: 60,
        margin: "0 auto",
      },
      "& .MuiPaper-root": {
        padding: "40px 0 32px",
        minWidth: 400,
        width: "100%",
        textAlign: "center",
        font: "14px/24px ",
        backgroundColor: "#FFFFFF",
        borderRadius: 28,
        [theme.breakpoints.down("xs")]: {
          minWidth: "unset",
          width: "calc(100vw - 56px)",
        },
      },
    },
    dialogTitle: {
      "& h6": {
        textAlign: "center",
        margin: "0 auto",
        padding: "12px 0 8px",
        fontSize: "32px",
        lineHeight: "32px",
        fontWeight: 600,
        color: "#000B60 !important",
      },
    },
    closeButton: {},
    dialogContent: {
      padding: "0 !important" as any,
      position: "relative",
      fontSize: "16px",
      lineHeight: "19px",
      color: "#000B60",
    },
  };
});

export default useStyles;
