// @ts-nocheck
import { useContext, useEffect, useState } from "react";
import {
  Fee,
  LCDClient,
  MsgSend,
  SyncTxBroadcastResult,
} from "@terra-money/terra.js";
import {
  useConnectedWallet,
  useWallet,
  WalletStatus,
} from "@terra-money/wallet-provider";
import useAuth from "../../../hooks/useAuth";
import ModalVerifyEmail from "../ModalVerifyEmail";
import { Button, withWidth, TextField, Hidden } from "@material-ui/core";
import { KYC_STATUS } from "../../../constants";
import useWalletSignature from "../../../hooks/useWalletSignature";
import axios from "../../../services/axios";
import { useWeb3React } from "@web3-react/core";
import { useDispatch } from "react-redux";
import { alertFailure } from "../../../store/actions/alert";
import { useForm } from "react-hook-form";
import useStyles from "./style";
import { Link, useHistory } from "react-router-dom";
import { ConnectorNames } from "../../../constants/connectors";
import { AppContext } from "../../../AppContext";
import useCommonStyles from "../../../styles/CommonStyle";

const envLocal = localStorage?.getItem("env");
const env = envLocal ? JSON?.parse(envLocal) : {};

const allWallets = [
  {
    icon: "/images/mainWalletImg.png",
    title: "Main Wallet Address",
    key: "MetaMask",
  },
];

export const startedSteps = [
  {
    id: 1,
    title: "Stake $RWA for a Tier",
    content:
      "Stake RWA to achieve a Tier (Bronze, Silver, Gold, Platinum, Diamond)",
    actionTitle: "Stake Now",
    img: "/images/landing/user_process.svg",
    url: "/staking-pools?benefit=ido-only",
    completedText: "Stake for a tier",
    isInPage: true,
  },
  {
    id: 2,
    title: "Complete a KYC",
    content: "Complete a KYC with RWA Pad via Blockpass to access IDOs",
    actionTitle: "Enter KYC",
    img: "/images/landing/user_process.svg",
    url: "https://verify-with.blockpass.org/?clientId=rwa_launchpad_kycaml_a4a02",
    completedText: "KYC",
    isInPage: false,
  },
  {
    id: 3,
    title: "Register Interest For IDOs",
    content:
      "To participate in the first round (guaranteed) of any IDO you have to Register Interest, if you have a Base or Bronze Tier you must participate in a Galxe event to earn allocation in a guaranteed round.",
    actionTitle: "How to join",
    img: "/images/landing/user_process.svg",
    url: "/pools",
    isInPage: true,
  },
];

const AccountInformation = (props: any) => {
  const styles = useStyles();
  const common = useCommonStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [openModalVerifyEmail, setOpenModalVerifyEmail] = useState(false);
  const { connectedAccount } = useAuth();
  const [onEditProfile, setOnEditProfile] = useState(false);
  const { signature, signMessage } = useWalletSignature();
  const { account } = useWeb3React();

  const { connector } = useWeb3React();
  const { logout: disconnectWallet } = useContext(AppContext);

  const connectedWallet = useConnectedWallet();

  const handleKYC = () => {
    window.open("https://verify-with.blockpass.org/?clientId=rwa_launchpad_kycaml_a4a02", "_blank");
  };

  const { status, wallets, availableConnections, connect, disconnect } =
    useWallet();

  const {
    email,
    setEmail,
    setEmailVeryfied,
    kycStatus,
    twitter,
    userInfo,
    telegram,
    setUpdatedSuccess,
    notEth,
    userTier,
    solanaWallet,
    terraWallet,
    isKYC,
    isLoadingKYC,
  } = props;

  const [solanaAddress, setSolanaAddress] = useState(solanaWallet);
  const [terraAddress, setTerraAddress] = useState(terraWallet);

  useEffect(() => {
    setSolanaAddress(solanaWallet);
  }, [solanaWallet]);

  useEffect(() => {
    setTerraAddress(terraWallet);
    if (!terraWallet) {
      disconnect();
    }
  }, [terraWallet, disconnect]);

  useEffect(() => {
    if (!connectedAccount) {
      setOnEditProfile(false);
    }
  }, [connectedAccount]);

  const { register, errors, handleSubmit } = useForm({
    mode: "onChange",
  });

  const onSetEditProfile = async () => {
    if (!signature) {
      await signMessage();
    } else {
      setOnEditProfile(true);
      setUpdatedSuccess(false);
    }
  };

  useEffect(() => {
    if (signature && connectedAccount) {
      setOnEditProfile(true);
      setUpdatedSuccess(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signature, connectedAccount]);

  useEffect(() => {
    if (wallets[0]) {
      setTerraAddress(wallets[0]?.terraAddress);
    }
  }, [wallets]);

  const handleFormSubmit = async (data: any) => {
    if (signature) {
      const config = {
        headers: {
          msgSignature: env.REACT_APP_MESSAGE_INVESTOR_SIGNATURE,
        },
      };

      /*let solanaSignature;
      if (!!solanaAddress) {
        solanaSignature = await solanaSign();
      }

      let terraSignature;
      if (!!terraAddress) {
        terraSignature = await terraSign();
      }*/

      const response = (await axios.put(
        `/user/update-profile`,
        {
          signature,
          wallet_address: account,
          user_twitter: data?.twitter,
          user_telegram: data?.telegram,
          solana_address: solanaAddress,
          terra_address: terraAddress,
        },
        config as any
      )) as any;

      if (response.data) {
        if (response.data.status === 200) {
          setOnEditProfile(false);
          setUpdatedSuccess(true);
        }

        if (response.data.status !== 200) {
          dispatch(alertFailure(response.data.message));
        }
      }
    }
  };

  const solanaSign = async () => {
    const encodedMessage = new TextEncoder().encode(
      env.REACT_APP_MESSAGE_INVESTOR_SIGNATURE
    );
    // @ts-ignore
    const signedMessage = await window.solana.request({
      method: "signMessage",
      params: {
        message: encodedMessage,
        display: "utf8",
      },
    });
    return signedMessage;
  };

  const handleConnectWallet = (key: string) => {
    if (key === "Solana") {
      handleSolanaConnect(key);
    }
    if (key === "Terra") {
      handleTerraConnect(key);
    }
  };

  const handleDisconnectWallet = (key: string) => {
    if (key === "Solana") {
      handleSolanaDisconnect();
    }
    if (key === "Terra") {
      handleTerraDisconnect();
    }
  };

  const handleSolanaDisconnect = () => {
    // @ts-ignore
    if (!window.solana) {
      return;
    }
    // @ts-ignore
    window.solana.request({ method: "disconnect" });
    setSolanaAddress("");
  };

  const handleSolanaConnect = async (key: string) => {
    const provider = getSolanaProvider();
    if (!provider) {
      dispatch(alertFailure("Phantom extension is not installed!"));
      return;
    }
    try {
      let resp;
      resp = await provider.connect();
      if (!resp) {
        resp = await provider.request({ method: "connect" });
      }
      setSolanaAddress(resp.publicKey.toString());
    } catch (err) {
      dispatch(alertFailure("User rejected the request!"));
    }
  };

  const getSolanaProvider = () => {
    if ("solana" in window) {
      // @ts-ignore
      const provider = window?.solana;
      if (provider.isPhantom) {
        return provider;
      }
    }
  };

  const handleTerraDisconnect = () => {
    if (!terraAddress) {
      return;
    }
    disconnect();
    setTerraAddress("");
  };

  const handleTerraConnect = async (key: string) => {
    if (terraAddress) {
      return;
    }
    if (status === WalletStatus.WALLET_NOT_CONNECTED) {
      const type = availableConnections[0]?.type;
      await connect(type, "");
    }
  };

  const renderErrorRequired = (errors: any, prop: string) => {
    if (errors[prop]) {
      if (errors[prop].type === "required") {
        return "This field is required";
      }
    }
  };

  const changeSolanaInput = (e: any) => {
    setSolanaAddress(e.target.value);
  };
  const changeTerraInput = (e: any) => {
    setTerraAddress(e.target.value);
  };

  const renderWalletAddress = (key: string) => {
    let currentAddress;
    if (key === "MetaMask") {
      currentAddress = connectedAccount;
    } else {
      if (!validConnected(key) && !onEditProfile)
        return (
          <span>
            <span data-role="dot" style={{ marginRight: 4 }}>
              &bull;
            </span>
            Not Connected
          </span>
        );
    }
    if (key === "Solana") {
      currentAddress = solanaAddress;
      if (onEditProfile)
        return (
          <TextField
            className={styles.smallInputNewValue}
            value={solanaAddress ? solanaAddress : ""}
            placeholder="Enter Solana Address"
            onInput={changeSolanaInput}
            inputRef={register({
              required: true,
              maxLength: {
                value: 60,
                message: "max 60 characters",
              },
            })}
          />
        );
    }
    if (key === "Terra") {
      currentAddress = terraAddress;
      if (onEditProfile)
        return (
          <TextField
            className={styles.smallInputNewValue}
            value={terraAddress ? terraAddress : ""}
            placeholder="Enter Terra Address"
            onInput={changeTerraInput}
            inputRef={register({
              maxLength: {
                value: 60,
                message: "max 60 characters",
              },
            })}
          />
        );
    }
    if (!currentAddress) {
      return null;
    }
    const firstSubString = currentAddress?.substring(0, 8);
    const lastSubString = currentAddress?.substring(
      currentAddress.length - 8,
      currentAddress.length
    );
    return firstSubString + "********" + lastSubString;
  };

  const validConnected = (key: string) => {
    if (key === "Solana") {
      return !!solanaAddress;
    }
    if (key === "Terra") {
      return !!terraAddress;
    }
    return false;
  };

  const handleAccountLogout = async () => {
    if (
      connectedAccount === ConnectorNames.WalletConnect &&
      localStorage.getItem("walletconnect")
    ) {
      localStorage.removeItem("walletconnect");
    }
    localStorage.removeItem("access_token");

    disconnectWallet && disconnectWallet();
    history.push("/");
  };

  const getWallet = () => {
    const root = localStorage.getItem("persist:root");
    const dataRoot = root ? JSON.parse(root) : {};
    const connect = dataRoot?.connector ? JSON.parse(dataRoot.connector) : {};
    return connect?.data;
  };

  const validKYC = userInfo?.totalStaked && userInfo?.totalStaked !== "0";

  const terraSign = async () => {
    if (!connectedWallet) {
      return;
    }

    const msgSign = env.REACT_APP_MESSAGE_INVESTOR_SIGNATURE as string;
    let terraSignature;

    await connectedWallet
      .sign({
        fee: new Fee(1000000, "0ust"),
        msgs: [
          new MsgSend(connectedWallet.walletAddress, msgSign, {
            ust: 1000000,
          }),
        ],
      })
      .then((nextSignResult: any) => {
        if (nextSignResult?.result?.signatures.length > 0) {
          terraSignature = nextSignResult?.result?.signatures[0];
        }
      })
      .catch((error: unknown) => {
        console.error("terraSign", error);
      });

    return terraSignature;
  };

  const renderProfileHeader = () => {
    return (
      <div className={styles.headPage}>
        <h1 className={styles.title}>My profile</h1>
        {connectedAccount && !onEditProfile && isKYC && (
          <Hidden smDown>
            <Button
              variant="contained"
              className={styles.btnEditProfile}
              onClick={() => onSetEditProfile()}
            >
              Edit Profile
            </Button>
          </Hidden>
        )}
      </div>
    );
  };

  const renderWallets = () => {
    return (
      <div className={styles.bodyPageLeft}>
        <div className={styles.titleLeft}>Wallet Addresses</div>
        {allWallets.map((wallet, index) => {
          return (
            <div key={index} className={styles.walletForm}>
              <img src={wallet.icon} alt="" />
              <div className="wallet-address">
                <span className={styles.walletFormTop}>
                  {wallet.title}
                  {wallet.key === "MetaMask" ? (
                    <button onClick={() => handleAccountLogout()}>
                      Disconnect
                    </button>
                  ) : validConnected(wallet.key) ? (
                    <button onClick={() => handleDisconnectWallet(wallet.key)}>
                      Disconnect
                    </button>
                  ) : (
                    <button
                      data-role="connect"
                      onClick={() => handleConnectWallet(wallet.key)}
                    >
                      Connect
                    </button>
                  )}
                </span>
                {wallet.key === "MetaMask" ? (
                  <span>{renderWalletAddress(wallet.key)}</span>
                ) : (
                  <span>{renderWalletAddress(wallet.key)}</span>
                )}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const renderInfomation = () => {
    return (
      <div className={styles.mainInfomation}>
        {connectedAccount && !onEditProfile && isKYC && (
          <Hidden smUp>
            <Button
              variant="contained"
              className={styles.btnEditProfile}
              onClick={() => onSetEditProfile()}
            >
              Edit Profile
            </Button>
          </Hidden>
        )}

        {connectedAccount &&
          onEditProfile &&
          kycStatus === KYC_STATUS.APPROVED && (
            <Hidden smUp>
              <div className={styles.footerPage}>
                <Button
                  form="hook-form"
                  type="submit"
                  variant="contained"
                  className={styles.btnUpdateProfile}
                  onClick={() => handleFormSubmit}
                >
                  Update Profile
                </Button>
              </div>
            </Hidden>
          )}
        <div className={styles.titleLeft}>Account Information</div>

        <div className={styles.inputGroup}>
          <span className="title">KYC Status</span>
          {connectedAccount && (
            <div className="flex">
              {isLoadingKYC ? (
                <span>Loading...</span>
              ) : (
                <div>
                  {kycStatus === KYC_STATUS.APPROVED ? (
                    <span className="verified">Verified</span>
                  ) : (
                    <>
                      <span className="unverified">
                        Unverified
                        {validKYC && (
                          <button onClick={() => handleKYC()}>KYC Now</button>
                        )}
                      </span>
                    </>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
        {connectedAccount &&
          !isLoadingKYC &&
          kycStatus !== KYC_STATUS.APPROVED &&
          !validKYC && (
            <span className={styles.nnn1424h}>
              You must complete KYC to attend any IDO pools
            </span>
          )}
        {connectedAccount && kycStatus === KYC_STATUS.APPROVED && (
          <form
            id="hook-form"
            className={styles.form}
            onSubmit={handleSubmit(handleFormSubmit)}
          >
            <div className={styles.inputGroup}>
              <span className="title">Twitter ID</span>
              {connectedAccount && (
                <div className="accountContent">
                  {onEditProfile ? (
                    <div className={styles.groupInput}>
                      <TextField
                        className={styles.inputNewValue}
                        defaultValue={twitter}
                        placeholder="Enter Twitter ID. EX: RWA"
                        name="twitter"
                        inputRef={register({
                          required: true,
                          maxLength: {
                            value: 60,
                            message: "max 60 characters",
                          },
                        })}
                      />
                    </div>
                  ) : (
                    <span className={styles.nameSocial}>{twitter ?? "-"}</span>
                  )}
                </div>
              )}
            </div>
            {onEditProfile && (
              <div className={styles.inputGroup}>
                <span style={{ opacity: 0 }} className="title"></span>
                <span className={styles.errorInput}>
                  {errors.twitter && errors.twitter.type !== "required"
                    ? errors.twitter.message
                    : renderErrorRequired(errors, "twitter")}
                </span>
              </div>
            )}

            <div className={styles.inputGroup}>
              <span className="title">Telegram ID</span>
              {connectedAccount && (
                <div className="accountContent">
                  {onEditProfile ? (
                    <div className={styles.groupInput}>
                      <TextField
                        className={styles.inputNewValue}
                        defaultValue={telegram}
                        placeholder="Enter telegram ID. EX: RWA"
                        name="telegram"
                        inputRef={register({
                          required: true,
                          maxLength: {
                            value: 60,
                            message: "max 60 characters",
                          },
                        })}
                      />
                    </div>
                  ) : (
                    <span>{telegram ?? "-"}</span>
                  )}
                </div>
              )}
            </div>
            {connectedAccount && (
              <div className={styles.inputGroup}>
                <span style={{ opacity: 0 }} className="title"></span>
                <span className={styles.errorInput}>
                  {errors.telegram && errors.telegram.type !== "required"
                    ? errors.telegram.message
                    : renderErrorRequired(errors, "telegram")}
                </span>
              </div>
            )}
          </form>
        )}

        <div className={styles.inputGroup} style={{ marginBottom: 5 }}>
          <span></span>
          {connectedAccount && (
            <>
              <span style={{ color: "red", display: "inline-block" }}>
                {kycStatus === KYC_STATUS.RESUBMIT &&
                  "Please send information to info@rwa.org to resubmit KYC."}
              </span>
            </>
          )}
        </div>

        {connectedAccount &&
          onEditProfile &&
          kycStatus === KYC_STATUS.APPROVED && (
            <Hidden smDown>
              <div className={styles.footerPage}>
                <Button
                  form="hook-form"
                  type="submit"
                  variant="contained"
                  className={styles.btnUpdateProfile}
                  onClick={() => handleFormSubmit}
                >
                  UPDATE PROFILE
                </Button>
              </div>
            </Hidden>
          )}
      </div>
    );
  };

  const renderGettingStarted = () => {
    return (
      <>
        <div className={styles.title}>Getting Started</div>
        <div className={styles.footerUnderTitle}>
          Here are 3 steps for you to start on RWA Launchpad
        </div>
        <div className={styles.startSteps}>
          {startedSteps.map((step) => {
            return (
              <div
                key={step.id}
                data-role="step"
                style={{ position: "relative" }}
              >
                <div className="steps-top">
                  <img src={step.img} alt="" />
                  <div className="steps-title">
                    <div className="idStep">STEP {step.id}</div>
                    <div className="titleStep">{step.title}</div>
                  </div>
                </div>
                <div className="contentStep">{step.content}</div>
                <div className="actionStep">
                  {!step?.isInPage ? (
                    <a href={step.url} target="_blank" rel="noreferrer">
                      <div>{step.actionTitle}</div>
                    </a>
                  ) : (
                    <Link to={step.url}>
                      <div>{step.actionTitle}</div>
                    </Link>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </>
    );
  };

  return (
    <div className={styles.wrapper}>
      {renderProfileHeader()}

      <div className={styles.bodyPage}>
        {renderWallets()}
        {renderInfomation()}
      </div>

      <div className={styles.lineBottom} />

      {renderGettingStarted()}

      <ModalVerifyEmail
        setOpenModalVerifyEmail={setOpenModalVerifyEmail}
        email={email}
        setEmail={setEmail}
        open={openModalVerifyEmail}
        setEmailVeryfied={setEmailVeryfied}
      />
    </div>
  );
};

export default withWidth()(AccountInformation);
