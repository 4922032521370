import { useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import axios from '../../../services/axios';
import { alertFailure } from '../../../store/actions/alert';

const envLocal = localStorage?.getItem("env");
const env = envLocal ? JSON?.parse(envLocal) : {};

const MESSAGE_INVESTOR_SIGNATURE = env.REACT_APP_MESSAGE_INVESTOR_SIGNATURE || "";

const useUserRefundSignature = (connectedAccount: string | undefined | null, campaignId: number | undefined, authSignature: string | undefined, type: string | 'refund') => {
  const dispatch = useDispatch();
  const [signature, setSignature] = useState<string | undefined>(undefined);
  const [currency, setCurrency] = useState<string | undefined>(undefined);
  const [deadline, setDeadline] = useState<string | undefined>(undefined);
  const [error, setError] = useState<string | undefined>("");
  const [loadingRefund, setLoadingRefund] = useState<boolean | undefined>(false);

  useEffect(() => {
      const getUserSignature = async () => {
        setError("");
        setSignature("");
        setLoadingRefund(true);

        try {
          const config = {
            headers: {
              msgSignature: MESSAGE_INVESTOR_SIGNATURE
            }
          }
          const response = await axios.post('/user/refund', {
            campaign_id: campaignId,
            wallet_address: connectedAccount,
            signature: authSignature,
            type
          }, config);

          if (response.data && response.status && response.status === 200) {
            const { data, message, status } = response.data;
            if (data && status === 200) {
              setSignature(data.signature);
              setCurrency(data.currency);
              setDeadline(data.deadline);
            }

            if (message && status !== 200) {
              dispatch(alertFailure(message));
              setError(message);
              setSignature("");
              setCurrency("");
            }
          }
          setLoadingRefund(false);
        } catch (err: any) {
          setError(err.message);
          setSignature("");
          setCurrency("");
          setLoadingRefund(false);
        }
      }
    connectedAccount && campaignId && authSignature && getUserSignature();
  }, [connectedAccount, campaignId, authSignature]);

  return {
    signature,
    currency,
    deadline,
    setSignature,
    error,
    loadingRefund,
  }
}

export default useUserRefundSignature;

