import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
  return {
    modalStake: {
      "& .MuiPaper-root": {
        [theme.breakpoints.down("xs")]: {
          margin: "10px",
        },
      },
      "& .modal-content": {
        backgroundColor: "#FFFFFF",
        borderRadius: 28,
        width: "400px",
        padding: "32px 40px 20px",
        [theme.breakpoints.down("xs")]: {
          padding: "40px 0px 20px",
        },
        color: "#FFFFFF",
      },
      "& .modal-content__head .title": {
        fontSize: "32px",
        lineHeight: "32px",
        color: "#000B60",
        fontWeight: 600,
      },
      "& .modal-content__body": {
        margin: "20px 0",
        [theme.breakpoints.down("xs")]: {
          padding: "0px 20px",
        },

        "& .token-type": {
          display: "flex",
          justifyContent: "space-between",
          fontSize: "16px",
          lineHeight: "18px",
          alignItems: "center",
          marginBottom: "14px",
          fontFamily: "",

          "& .token-type-title": {
            color: "#000B60",
          },
        },
        "& .token-detail": {
          display: "flex",
          alignItems: "center",
          color: "#000B60",
          fontWeight: 600,
        },
        "& .token-logo": {
          width: "22px",
          height: "22px",
          borderRadius: "28px",
          margin: "0 5px",
        },

        "& .input-group": {
          background: "#222228",
          padding: "0 10px",
          borderRadius: "4px",
        },
        "& .input-group input": {
          cursor: "default",
          padding: "0",
        },
        "& .token-balance": {
          color: "#AEAEAE",
          marginTop: "5px",
        },
        "& .subtitle": {
          marginBottom: "5px",
          fontWeight: 700,
          color: "#FFFFFF",
        },
      },
      "& .modal-content__foot": {
        textAlign: "center",
        display: "block",
      },
    },
    modalFooter: {
      width: "100%",
      "& button": {
        width: "100%",
        padding: "10px 40px 12px",
        fontSize: "16px",
        borderRadius: "60px",
        background: "#0058FF",
        color: "#fff",
        lineHeight: "19px",
        fontWeight: 600,
        border: "none",
      },
      [theme.breakpoints.down("xs")]: {
        padding: "0 20px",
      },
    },
    notice: {
      font: "normal normal bold 14px/18px ",
      color: "#FFF",
      marginTop: "30px",
    },
    [theme.breakpoints.down("xs")]: {
      modalTransaction: {
        "& .modal-content__body": {
          padding: "0",
        },
      },
    },
  };
});

export default useStyles;
