import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
  return {
    modalStake: {
      "& .MuiDialog-paper": {
        overflowX: "hidden",
      },
      "& .MuiPaper-root": {
        [theme.breakpoints.down("xs")]: {
          margin: "28px",
        },
      },
      "& .subtitle": {
        font: "normal normal 500 14px/18px ",
      },
    },
    modalSwitch: {
      maxWidth: 420,
      "& .MuiDialogTitle-root": {
        paddingTop: 8,
        paddingBottom: 4,
      },
    },
    modalSwitchFooter: {
      borderTop: "none",
      paddingTop: 0,
    },
    textContent: {
      display: "flex",
      flexDirection: "column",
      font: "14px/24px ",
      textAlign: "center",
      "& p": {
        margin: 0,
      },
    },
    listPools: {
      display: "grid",
      flexDirection: "column",
      gap: 6,
      marginTop: 12,
    },
    poolDetail: {
      height: 64,
      position: "relative",
      display: "grid",
      gridTemplateColumns: "48px 1fr 50px",
      gap: 8,
      padding: "8px 20px 8px 12px",
      cursor: "pointer",
      background: "#363636",
      borderRadius: 6,
      border: "1px solid transparent",

      "& .pool-selected-icon": {
        position: "absolute",
        top: 4,
        right: 4,
      },
      "& .pool-logo": {
        width: 48,
        height: 48,
        borderRadius: 4,
      },
      "& .pool-label": {
        display: "flex",
        font: "normal normal 500 16px/24px ",
        "& img": {
          marginLeft: "auto",
        },
      },
      "& .pool-text": {
        color: "#AEAEAE",
        font: "14px/24px ",
      },
    },
    poolSeleted: {
      border: "1px solid #5EFF8B",
    },

    [theme.breakpoints.down("xs")]: {
      modalTransaction: {
        "& .modal-content__body": {
          padding: "0",
        },
        "& .modal-content__foot": {
          gridTemplateColumns: "1fr",
        },
      },
    },
  };
});

export default useStyles;
