import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { BigNumber, utils } from "ethers";
import useCommonStyle from "../../../styles/CommonStyle";
import usePoolStyles from "../Pool/style";
import useStyles from "./style";

const closeIcon = "/images/icons/close.svg";

const ModalClaim = (props: any) => {
  const styles = useStyles();
  const poolStyles = usePoolStyles();
  const commonStyles = useCommonStyle();

  const { open, onConfirm, onClose, pendingReward, tokenDetails } = props;

  return (
    <Dialog
      open={open}
      keepMounted
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      className={commonStyles.modal + " " + styles.modalStake}
    >
      <div className="modal-content">
        <DialogTitle
          id="alert-dialog-slide-title"
          className="modal-content__head"
        >
          <img src={closeIcon} alt="" onClick={onClose} className="btn-close" />
          <div className="title">Claim</div>
        </DialogTitle>
        <DialogContent className="modal-content__body">
          <div className="token-type">
            <div className="token-type-title">Token</div>
            <div className="token-detail">
              <div>{tokenDetails?.symbol}</div>
            </div>
          </div>
          <div className="token-type">
            <div className="token-type-title">Current Profit</div>
            <div className="token-detail">
              <div>
                {!BigNumber.from(pendingReward || "0").eq(
                  BigNumber.from("0")
                ) && Number(utils.formatEther(pendingReward)).toFixed(2)}
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions className={styles.modalFooter}>
          <button onClick={onConfirm}>Claim</button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default ModalClaim;
