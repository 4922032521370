import type { AddEthereumChainParameter } from "@web3-react/types";

const envLocal = localStorage?.getItem("env");
const env = envLocal ? JSON?.parse(envLocal) : {};

export const ETH_CHAIN_ID = env.REACT_APP_ETH_CHAIN_ID as string;
export const BSC_CHAIN_ID = env.REACT_APP_BSC_CHAIN_ID as string;
export const BASE_CHAIN_ID = env.REACT_APP_BASE_CHAIN_ID as string;
export const POLYGON_CHAIN_ID = env.REACT_APP_POLYGON_CHAIN_ID as string;
export const AVALANCHE_CHAIN_ID = env.REACT_APP_AVALANCHE_CHAIN_ID as string;
export const ARBITRUM_CHAIN_ID = env.REACT_APP_ARBITRUM_CHAIN_ID as string;

export const DEFAULT_CHAIN_ID = BASE_CHAIN_ID;

export const USDT_ADDRESS = env.REACT_APP_USDT_SMART_CONTRACT;
export const USDC_ADDRESS = env.REACT_APP_USDC_SMART_CONTRACT;

export const USDC_POLYGON_ADDRESS = env.REACT_APP_USDC_POLYGON_SMART_CONTRACT;
export const USDT_POLYGON_ADDRESS = env.REACT_APP_USDT_POLYGON_SMART_CONTRACT;
export const USDT_AVALANCHE_ADDRESS =
  env.REACT_APP_USDT_AVALANCHE_SMART_CONTRACT;
export const USDT_ARBITRUM_ADDRESS = env.REACT_APP_USDT_ARBITRUM_SMART_CONTRACT;

export const USDC_BSC_ADDRESS = env.REACT_APP_USDC_BSC_SMART_CONTRACT;
export const USDC_BASE_ADDRESS = env.REACT_APP_USDC_BASE_SMART_CONTRACT;
export const USDT_BSC_ADDRESS = env.REACT_APP_USDT_BSC_SMART_CONTRACT;
export const BUSD_BSC_ADDRESS = env.REACT_APP_BUSD_BSC_SMART_CONTRACT;

export const ETHERSCAN_URL = env.REACT_APP_ETHERSCAN_BASE_URL || "";
export const BCSSCAN_URL = env.REACT_APP_BSCSCAN_BASE_URL || "";
export const BASESCAN_URL = env.REACT_APP_BASESCAN_BASE_URL || "";
export const POLYGONSCAN_URL = env.REACT_APP_POLSCAN_BASE_URL || "";
export const AVALANCHESCAN_URL = env.REACT_APP_AVALANCHE_SCAN_BASE_URL || "";
export const ARBITRUMSCAN_URL = env.REACT_APP_ARBITRUMSCAN_BASE_URL || "";

export enum ChainId {
  MAINNET = 1,
  ROPSTEN = 3,
  RINKEBY = 4,
  GOERLI = 5,
  KOVAN = 42,
  BSC_TESTNET = 97,
  BSC_MAINNET = 56,
  POLYGON_MAINNET = 137,
  MUMBAI_POLYGON_TESTNET = 80001,
  AVALANCHE = 43114,
  AVALANCHE_TESTNET = 43113,
  ARBITRUM = 42161,
  ARBITRUM_TESNET = 421613,
}

export type chainId = Extract<
  ChainId,
  | ChainId.MAINNET
  | ChainId.ROPSTEN
  | ChainId.RINKEBY
  | ChainId.GOERLI
  | ChainId.KOVAN
  | ChainId.BSC_MAINNET
  | ChainId.BSC_TESTNET
  | ChainId.POLYGON_MAINNET
  | ChainId.MUMBAI_POLYGON_TESTNET
  | ChainId.AVALANCHE
  | ChainId.AVALANCHE_TESTNET
  | ChainId.ARBITRUM
  | ChainId.ARBITRUM_TESNET
>;

export const ChainIdNameMapping: { [key in ChainId]: string } = {
  [ChainId.MAINNET]: "Mainnet",
  [ChainId.ROPSTEN]: "Ropsten",
  [ChainId.GOERLI]: "Goerli",
  [ChainId.KOVAN]: "Kovan",
  [ChainId.RINKEBY]: "Rinkeby",
  [ChainId.BSC_MAINNET]: "BSC Mainnet",
  [ChainId.BSC_TESTNET]: "BSC Testnet",
  [ChainId.POLYGON_MAINNET]: "Polygon Mainnet",
  [ChainId.MUMBAI_POLYGON_TESTNET]: "Mumbai Testnet",
  [ChainId.AVALANCHE]: "Avalanche Network",
  [ChainId.AVALANCHE_TESTNET]: "Avalanche FUJI C-Chain",
  [ChainId.ARBITRUM]: "Arbitrum Mainnet",
  [ChainId.ARBITRUM_TESNET]: "Arbitrum Testnet",
};

export const NETWORK_NAME_MAPPINGS: { [key: string]: string } = {
  "1": "Mainnet",
  "3": "Ropsten",
  "5": "Goerli",
  "42": "Kovan",
  "4": "Rinkeby",
  "56": "BSC Mainnet",
  "97": "BSC Testnet",
  "8453": "Base Mainnet",
  "84532": "Base Testnet",
  "137": "Polygon Mainnet",
  "80001": "Mumbai Testnet",
  "43114": "Avalanche Network",
  "43113": "Avalanche FUJI C-Chain",
  "42161": "Arbitrum Mainnet",
  "421613": "Arbitrum Goerli Testnet",
};

export interface NetworkInfo {
  name: string;
  id?: string | undefined;
  icon: string;
  disableIcon: string;
  currency?: string;
  [k: string]: any;
}

export enum APP_NETWORKS_NAME {
  METAMASK = "METAMASK",
  BSC = "BSC",
  BASE = "BASE",
  POLYGON = "POLYGON",
  AVALANCHE = "AVALANCHE",
  ARBITRUM = "ARBITRUM",
}

export type appNetworkType = Extract<
  APP_NETWORKS_NAME,
  | APP_NETWORKS_NAME.METAMASK
  | APP_NETWORKS_NAME.BSC
  | APP_NETWORKS_NAME.BASE
  | APP_NETWORKS_NAME.POLYGON
  | APP_NETWORKS_NAME.AVALANCHE
  | APP_NETWORKS_NAME.ARBITRUM
>;

export const APP_NETWORKS: { [key in APP_NETWORKS_NAME]: NetworkInfo } = {
  [APP_NETWORKS_NAME.BASE]: {
    name: env?.REACT_APP_NODE_ENV?.includes("pro") ? "Base" : "Base Testnet",
    id: BASE_CHAIN_ID,
    icon: "/images/base.svg",
    disableIcon: "/images/base-disabled.png",
  },
  [APP_NETWORKS_NAME.METAMASK]: {
    name: env?.REACT_APP_NODE_ENV?.includes("pro") ? "Ethereum" : "Sepolia",
    id: ETH_CHAIN_ID,
    icon: "/images/ethereum.svg",
    disableIcon: "/images/ethereum-disabled.png",
  },
  [APP_NETWORKS_NAME.BSC]: {
    name: env?.REACT_APP_NODE_ENV?.includes("pro") ? "BSC" : "BSC Testnet",
    id: BSC_CHAIN_ID,
    icon: "/images/bsc.svg",
    disableIcon: "/images/binance-disabled.png",
  },
  [APP_NETWORKS_NAME.POLYGON]: {
    name: env?.REACT_APP_NODE_ENV?.includes("pro") ? "Polygon" : "Mumbai",
    id: POLYGON_CHAIN_ID,
    icon: "/images/polygon-matic.svg",
    disableIcon: "/images/polygon-matic-disabled.svg",
  },
  [APP_NETWORKS_NAME.AVALANCHE]: {
    name: env?.REACT_APP_NODE_ENV?.includes("pro")
      ? "Avalanche"
      : "Avalanche Testnet",
    id: AVALANCHE_CHAIN_ID,
    icon: "/images/avalanche.svg",
    disableIcon: "/images/avalanche-disabled.svg",
  },
  [APP_NETWORKS_NAME.ARBITRUM]: {
    name: env?.REACT_APP_NODE_ENV?.includes("pro")
      ? "Arbitrum"
      : "Arbitrum Testnet",
    id: ARBITRUM_CHAIN_ID,
    icon: "/images/arbitrum.svg",
    disableIcon: "/images/arbitrum-disabled.svg",
  },
};

export const APP_NETWORKS_ID: (string | undefined)[] = [
  ETH_CHAIN_ID,
  BASE_CHAIN_ID,
  BSC_CHAIN_ID,
  POLYGON_CHAIN_ID,
  AVALANCHE_CHAIN_ID,
  ARBITRUM_CHAIN_ID,
];

export const ETH_NETWORK_RPC_URL = env.REACT_APP_ETH_RPC_URL || "";
export const BSC_NETWORK_RPC_URL = env.REACT_APP_BSC_RPC_URL || "";
export const BASE_NETWORK_RPC_URL = env.REACT_APP_BASE_RPC_URL || "";
export const POLYGON_NETWORK_RPC_URL = env.REACT_APP_POLYGON_RPC_URL || "";
export const AVALANCHE_NETWORK_RPC_URL = env.REACT_APP_AVALANCHE_RPC_URL || "";
export const ARBITRUM_NETWORK_RPC_URL = env.REACT_APP_ARBITRUM_RPC_URL || "";

export const NETWORK_ETH_NAME = env.REACT_APP_NETWORK_ETH_NAME;
export const NETWORK_BSC_NAME = env.REACT_APP_NETWORK_BSC_NAME;
export const NETWORK_BASE_NAME = env.REACT_APP_NETWORK_BASE_NAME;
export const NETWORK_POLYGON_NAME = env.REACT_APP_NETWORK_POLYGON_NAME;
export const NETWORK_ARBITRUM_NAME = env.REACT_APP_NETWORK_ARBITRUM_NAME;
export const NETWORK_AVALANCHE_NAME = env.REACT_APP_NETWORK_AVALANCHE_NAME;

export const appNetwork: { [key: string]: string } = {
  [ETH_CHAIN_ID]: NETWORK_ETH_NAME as string,
  [BSC_CHAIN_ID]: NETWORK_BSC_NAME as string,
  [BASE_CHAIN_ID]: NETWORK_BASE_NAME as string,
  [POLYGON_CHAIN_ID]: NETWORK_POLYGON_NAME as string,
  [ARBITRUM_CHAIN_ID]: NETWORK_ARBITRUM_NAME as string,
  [AVALANCHE_CHAIN_ID]: NETWORK_AVALANCHE_NAME as string,
};

export const APP_NETWORKS_SUPPORT: { [key: number]: NetworkInfo } = {
  [ETH_CHAIN_ID]: {
    name: env?.REACT_APP_NODE_ENV?.includes("pro") ? "Ethereum" : "Sepolia",
    id: ETH_CHAIN_ID,
    icon: "/images/ethereum.svg",
    disableIcon: "/images/ethereum-disabled.png",
    currency: "ETH",
    networkName: NETWORK_NAME_MAPPINGS[ETH_CHAIN_ID],
    details: {
      chainId: `0x${(+ETH_CHAIN_ID).toString(16)}`,
      chainName: NETWORK_NAME_MAPPINGS[ETH_CHAIN_ID],
      nativeCurrency: {
        name: "ETH",
        symbol: "ETH",
        decimals: 18,
      },
      rpcUrls: [ETH_NETWORK_RPC_URL],
      blockExplorerUrls: [ETHERSCAN_URL],
    },
  },
  [BSC_CHAIN_ID]: {
    name: env?.REACT_APP_NODE_ENV?.includes("pro")
      ? "BSC Mainnet"
      : "BSC Testnet",
    id: BSC_CHAIN_ID,
    icon: "/images/bsc.svg",
    disableIcon: "/images/binance-disabled.png",
    currency: "BNB",
    networkName: NETWORK_NAME_MAPPINGS[BSC_CHAIN_ID],
    details: {
      chainId: `0x${(+BSC_CHAIN_ID).toString(16)}`,
      chainName: NETWORK_NAME_MAPPINGS[BSC_CHAIN_ID],
      nativeCurrency: {
        name: "BNB",
        symbol: "BNB",
        decimals: 18,
      },
      rpcUrls: [BSC_NETWORK_RPC_URL],
      blockExplorerUrls: [BCSSCAN_URL],
    },
  },
  [BASE_CHAIN_ID]: {
    name: env?.REACT_APP_NODE_ENV?.includes("pro")
      ? "Base Mainnet"
      : "Base Testnet",
    id: BASE_CHAIN_ID,
    icon: "/images/base.svg",
    disableIcon: "/images/base-disabled.png",
    currency: "ETH",
    networkName: NETWORK_NAME_MAPPINGS[BASE_CHAIN_ID],
    details: {
      chainId: `0x${(+BASE_CHAIN_ID).toString(16)}`,
      chainName: NETWORK_NAME_MAPPINGS[BASE_CHAIN_ID],
      nativeCurrency: {
        name: "ETH",
        symbol: "ETH",
        decimals: 18,
      },
      rpcUrls: [BASE_NETWORK_RPC_URL],
      blockExplorerUrls: [BASESCAN_URL],
    },
  },
  [POLYGON_CHAIN_ID]: {
    name: env?.REACT_APP_NODE_ENV?.includes("pro") ? "Polygon" : "Mumbai",
    id: POLYGON_CHAIN_ID,
    icon: "/images/polygon-matic.svg",
    disableIcon: "/images/polygon-matic-disabled.svg",
    currency: "MATIC",
    networkName: NETWORK_NAME_MAPPINGS[POLYGON_CHAIN_ID],
    details: {
      chainId: `0x${(+POLYGON_CHAIN_ID).toString(16)}`,
      chainName: NETWORK_NAME_MAPPINGS[POLYGON_CHAIN_ID],
      nativeCurrency: {
        name: "MATIC",
        symbol: "MATIC",
        decimals: 18,
      },
      rpcUrls: [POLYGON_NETWORK_RPC_URL],
      blockExplorerUrls: [POLYGONSCAN_URL],
    },
  },
  [AVALANCHE_CHAIN_ID]: {
    name: env?.REACT_APP_NODE_ENV?.includes("pro")
      ? "Avalanche"
      : "Avalanche Testnet",
    id: AVALANCHE_CHAIN_ID,
    icon: "/images/avalanche.svg",
    disableIcon: "/images/avalanche-disabled.svg",
    currency: "AVAX",
    networkName: NETWORK_NAME_MAPPINGS[AVALANCHE_CHAIN_ID],
    details: {
      chainId: `0x${(+AVALANCHE_CHAIN_ID).toString(16)}`,
      chainName: NETWORK_NAME_MAPPINGS[AVALANCHE_CHAIN_ID],
      nativeCurrency: {
        name: "AVAX",
        symbol: "AVAX",
        decimals: 18,
      },
      rpcUrls: [AVALANCHE_NETWORK_RPC_URL],
      blockExplorerUrls: [AVALANCHESCAN_URL],
    },
  },
  [ARBITRUM_CHAIN_ID]: {
    name: env?.REACT_APP_NODE_ENV?.includes("pro")
      ? "Arbitrum"
      : "Arbitrum Testnet",
    id: ARBITRUM_CHAIN_ID,
    icon: "/images/arbitrum.svg",
    disableIcon: "/images/arbitrum-disabled.svg",
    currency: "ETH",
    networkName: NETWORK_NAME_MAPPINGS[ARBITRUM_CHAIN_ID],
    details: {
      chainId: `0x${(+ARBITRUM_CHAIN_ID).toString(16)}`,
      chainName: NETWORK_NAME_MAPPINGS[ARBITRUM_CHAIN_ID],
      nativeCurrency: {
        name: "ETH",
        symbol: "ETH",
        decimals: 18,
      },
      rpcUrls: [ARBITRUM_NETWORK_RPC_URL],
      blockExplorerUrls: [ARBITRUMSCAN_URL],
    },
  },
};

interface BasicChainInformation {
  urls: string[];
  name: string;
}

interface ExtendedChainInformation extends BasicChainInformation {
  nativeCurrency: AddEthereumChainParameter["nativeCurrency"];
  blockExplorerUrls: AddEthereumChainParameter["blockExplorerUrls"];
}

type ChainConfig = {
  [chainId: string]: BasicChainInformation | ExtendedChainInformation;
};

export const MAINNET_CHAINS: ChainConfig = {
  [ETH_CHAIN_ID]: {
    name: "Ethereum",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    urls: [ETH_NETWORK_RPC_URL],
    blockExplorerUrls: [ETHERSCAN_URL],
  },
  [BSC_CHAIN_ID]: {
    name: "BSC Mainnet",
    nativeCurrency: {
      name: "BNB",
      symbol: "BNB",
      decimals: 18,
    },
    urls: [BSC_NETWORK_RPC_URL],
    blockExplorerUrls: [BCSSCAN_URL],
  },
  [BASE_CHAIN_ID]: {
    name: env?.REACT_APP_NODE_ENV?.includes("pro")
      ? "Base Mainnet"
      : "Base Testnet",
    nativeCurrency: {
      name: "BASE",
      symbol: "ETH",
      decimals: 18,
    },
    urls: [BASE_NETWORK_RPC_URL],
    blockExplorerUrls: [BCSSCAN_URL],
  },
  [POLYGON_CHAIN_ID]: {
    name: "Polygon",
    nativeCurrency: {
      name: "MATIC",
      symbol: "MATIC",
      decimals: 18,
    },
    urls: [POLYGON_NETWORK_RPC_URL],
    blockExplorerUrls: [POLYGONSCAN_URL],
  },
  [AVALANCHE_CHAIN_ID]: {
    name: "Avalanche",
    nativeCurrency: {
      name: "AVAX",
      symbol: "AVAX",
      decimals: 18,
    },
    urls: [AVALANCHE_NETWORK_RPC_URL],
    blockExplorerUrls: [AVALANCHESCAN_URL],
  },
  [ARBITRUM_CHAIN_ID]: {
    name: "Arbitrum",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    urls: [ARBITRUM_NETWORK_RPC_URL],
    blockExplorerUrls: [ARBITRUMSCAN_URL],
  },
};

export const currencyAddress: any = {
  eth: {
    usdt: env.REACT_APP_USDT_SMART_CONTRACT,
    usdc: env.REACT_APP_USDC_SMART_CONTRACT,
  },
  bsc: {
    usdt: env.REACT_APP_USDT_BSC_SMART_CONTRACT,
    busd: env.REACT_APP_BUSD_BSC_SMART_CONTRACT,
    usdc: env.REACT_APP_USDC_BSC_SMART_CONTRACT,
  },
  base: {
    usdc: env.REACT_APP_USDC_BASE_SMART_CONTRACT,
  },
  polygon: {
    usdt: env.REACT_APP_USDT_POLYGON_SMART_CONTRACT,
    usdc: env.REACT_APP_USDC_POLYGON_SMART_CONTRACT,
  },
  avalanche: {
    usdt: env.REACT_APP_USDT_AVALANCHE_SMART_CONTRACT,
  },
  arbitrum: {
    usdt: env.REACT_APP_USDT_ARBITRUM_SMART_CONTRACT,
  },
};
