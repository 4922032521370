import { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import useStyles from "./style";
import useRefundToken from "../../hooks/useRefundToken";
import TransactionSubmitModal from "../../../../components/Base/TransactionSubmitModal";
const refundIcon = "/images/refund_token.svg";

const closeIcon = "/images/icons/close.svg";

const RefundTokenModal = (props: any) => {
  const styles = useStyles();
  const [openRefundModal, setOpenTransactionSubmitModal] =
    useState<boolean>(false);

  const {
    handleClose,
    opened,
    poolAddress,
    poolId,
    poolDetails,
    handleRefundSuccess,
    setLoadingRefund,
    setRefundTokenSuccess,
  } = props;

  const {
    refundToken,
    setRefundTokenLoading,
    transactionHashRefundToken,
    refundTokenSuccess,
    loadingRefund,
    refundError,
  } = useRefundToken(poolAddress, poolId);

  const handleRefundToken = async () => {
    handleClose();
    setOpenTransactionSubmitModal(true);
    await refundToken();
  };

  const handleCloseRefundModal = () => {
    handleClose();
    setLoadingRefund(false);
  };

  useEffect(() => {
    if (refundError) {
      setOpenTransactionSubmitModal(false);
      handleCloseRefundModal();
    }
    if (refundTokenSuccess) {
      setLoadingRefund(false);
      setRefundTokenSuccess(true);
    }
  }, [refundError, loadingRefund, refundTokenSuccess]);

  return (
    <>
      <Dialog open={opened} className={styles.refundDialog} maxWidth="xs">
        <img
          src={closeIcon}
          className={styles.btnClose}
          onClick={handleCloseRefundModal}
          alt="close"
        />
        <img src={refundIcon} className={styles.iconRefund} alt="" />
        <div className={styles.titleRefund}>
          <span>Refund Request</span>
        </div>
        <div className={styles.contentRefund}>
          <span>
            Once you confirm the refund request, you will not be able to
            repurchase your allocation.
          </span>
          <br />
          <b>Are you sure you want to refund?</b>
        </div>
        <div className={styles.groupButton}>
          <button
            className={styles.btnConfirm + " btn"}
            onClick={handleRefundToken}
          >
            Yes, sure
          </button>
          <button
            className={styles.btnCancel + " btn"}
            onClick={handleCloseRefundModal}
          >
            Cancel
          </button>
        </div>
      </Dialog>
      <TransactionSubmitModal
        opened={openRefundModal}
        handleClose={() => {
          setOpenTransactionSubmitModal(false);
        }}
        transactionHash={transactionHashRefundToken}
      />
    </>
  );
};

export default RefundTokenModal;
