import withWidth from "@material-ui/core/withWidth";
import useStyles from "./style";
import { Link } from "react-router-dom";
import { useState } from "react";
import { listQA } from "../../../../store/constants/listQA";

const LearnMore = listQA.list;

export const LearnMoreTier = () => {
  const styles = useStyles();
  const [listQuestions] = useState(LearnMore);
  return (
    <div className={styles.boxQuestions}>
      <div className={styles.subTitle}>
      Learn more about RWA Tiers and earning Tier Points here:{" "}
      </div>
      <ul className={styles.listQuestions}>
        {listQuestions.map((child, i) => {
          return (
            <li key={i} className={styles.itemQuestions}>
              <a href={child.url} target="_blank" rel="noreferrer">
                <span data-role="dot">&bull;</span>
                <span data-role="title">{child.title}</span>
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const NoStakeUser = (props: any) => {
  const styles = useStyles();

  return (
    <div className={styles.noStakeComponent}>
      {props.isOverview ? (
        <div className={styles.title}>
          You currently have
          <span> 0 </span>
          RWA Points.
        </div>
      ) : (
        <div className={styles.title}>
          You currently have
          <span> 0 </span>
          points earned. You must stake to earn Reputation points.
        </div>
      )}
      <Link to={`/staking-pools`} className={styles.buttonStakeNow}>
        Stake Now
      </Link>
      {/* <LearnMoreTier /> */}
    </div>
  );
};

export default withWidth()(NoStakeUser);
