import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
  return {
    tableRow: {
      cursor: "pointer",
      "& .pool-name": {
        display: "flex",
        alignItems: "center",
      },
      "& .token-symbol": {
        fontSize: "14px",
        lineHeight: "16px",
        fontWeight: 400,
        maxWidth: "120px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
      "&:hover": {
        background: "#373737",
      },
      "& img": {
        width: 32,
        height: 32,
        marginRight: 6,
      },
      "& .mm-icon": {
        width: 16,
        height: 16,
        marginBottom: -4,
        marginRight: 5,
        [theme.breakpoints.down("sm")]: {
          display: "none",
        },
      },
    },
    title: {
      fontSize: "18px",
      lineHeight: "21px",
      fontWeight: 600,
      color: "#0058FF",
      maxWidth: "120px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    poolStatus: {
      textTransform: "uppercase",
      "& .TBA": {
        color: "#727272",
      },
      "& .Upcoming": {
        color: "#B073FF",
      },
      "& .Swap": {
        color: "#6788FF",
      },
      "& .Filled": {
        color: "#3eb35f",
      },
      "& .Ended": {
        color: "#D01F36",
      },
      "& .Claimable": {
        color: "#FFD058",
      },
      "& .none": {
        color: "#fff",
      },
    },
    poolType: {
      textTransform: "capitalize",
      minWidth: "150px",
      width: "15%",
      color: "#fff",
    },
    relationshipType: {
      maxWidth: "263px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    chain: {
      display: "flex",
      gap: 6,
      textTransform: "capitalize",
      alignItems: "center",
      justifyContent: "end",
      "& img": {
        width: "14px",
        minWidth: "14px",
        height: "14px",
        margin: 0,
      },
      "& p": {
        paddingBottom: "4px",
      },
    },
    progress: {
      minWidth: "400px",
      width: "40%",
      color: "#fff",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      transition: ".2s all ease-in",

      "& > span": {
        marginRight: "10px",
        minWidth: "60px",
      },
      "& .progress": {
        width: "280px",
        height: "5px",
        borderRadius: "10px",
        backgroundColor: "rgba(255, 255, 255, 0.1)",
        position: "relative",
        transition: ".2s all ease-in",
      },
      "& .current-progress": {
        width: "280px",
        height: "5px",
        borderRadius: "10px",
        backgroundColor: "#232394",
        position: "absolute",
        left: 0,
        top: 0,
        transition: ".2s all ease-in",
      },
    },
    [theme.breakpoints.down("xs")]: {
      row: {
        padding: "0 15px",
        minHeight: "46px",
        height: "auto",
      },
      ratio: {
        display: "none",
      },
      poolType: {
        display: "none",
      },
      progress: {
        width: "30%",
        minWidth: "90px",
        "& .progress": {
          display: "none",
        },
      },
      name: {
        width: "50%",
        minWidth: "150px",
      },
      status: {
        width: "20%",
        minWidth: "60px",

        "& div": {
          padding: "0 10px",
          minWidth: "70px",
          width: "70px",
          "& span": {
            font: "normal normal bold 10px/12px ",
          },
        },
      },
    },
  };
});

export default useStyles;
