import { createContext, Dispatch, SetStateAction } from 'react';
import { AbstractConnector } from '@web3-react/abstract-connector'
import {MetaMask} from "@web3-react/metamask";
import {WalletConnect} from "@web3-react/walletconnect-v2";
import {BscWallet} from "./connectors/BinanceWallet";

export type AppContextType = {
  binanceAvailable: boolean,
  handleProviderChosen?: (name: string, connector: MetaMask | WalletConnect | BscWallet) => void,
  connectWalletLoading?: boolean,
  currentConnector?: MetaMask | WalletConnect | BscWallet | undefined,
  walletName?: (string | undefined)[],
  setWalletName?: Dispatch<SetStateAction<(string | undefined)[]>>,
  loginError?: string,
  appNetworkLoading?: boolean,
  handleConnectorDisconnect?: () => void,
  logout?: () => void,
  setCurrentConnectedWallet?: Dispatch<SetStateAction<any>>,
  currentConnectedWallet?: any,
  openConnectWallet?: boolean
  setOpenConnectWallet?: Dispatch<SetStateAction<boolean>>,
  openEnterRefCode?: boolean
  setOpenEnterRefCode?: Dispatch<SetStateAction<boolean>>,
}

export const AppContext = createContext<AppContextType>({
  binanceAvailable: false,
})
