import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
  const loaderSize = "35px";
  return {
    loadingContainer: {
      color: "white",
      margin: "0 auto",
      "& img": {
        width: 60,
        height: 60,
      },
    },
    loadingTable: {
      height: loaderSize,
      width: loaderSize,
      position: "relative",
      transform: "rotate(45deg)",
      "& .rhombus": {
        height: `calc(${loaderSize} / 7.5)`,
        width: `calc(${loaderSize} / 7.5)`,
        animationDuration: "2000ms",
        top: `calc(${loaderSize} / 2.3077)`,
        left: `calc(${loaderSize} / 2.3077)`,
        backgroundColor: "#ff1d5e",
        position: "absolute",
        animationIterationCount: "infinite",

        "&:nth-child(2n+0)": {
          marginRight: 0,
        },
        "&.child-1": {
          animationName: "$breeding-rhombus-spinner-animation-child-1",
          animationDelay: "calc(100ms * 1)",
        },
        "&.child-2": {
          animationName: "$breeding-rhombus-spinner-animation-child-2",
          animationDelay: "calc(100ms * 2)",
        },
        "&.child-3": {
          animationName: "$breeding-rhombus-spinner-animation-child-3",
          animationDelay: "calc(100ms * 3)",
        },
        "&.child-4": {
          animationName: "$breeding-rhombus-spinner-animation-child-4",
          animationDelay: "calc(100ms * 4)",
        },
        "&.child-5": {
          animationName: "$breeding-rhombus-spinner-animation-child-5",
          animationDelay: "calc(100ms * 5)",
        },
        "&.child-6": {
          animationName: "$breeding-rhombus-spinner-animation-child-6",
          animationDelay: "calc(100ms * 6)",
        },
        "&.child-7": {
          animationName: "$breeding-rhombus-spinner-animation-child-7",
          animationDelay: "calc(100ms * 7)",
        },
        "&.child-8": {
          animationName: "$breeding-rhombus-spinner-animation-child-8",
          animationDelay: "calc(100ms * 8)",
        },
        "&.big": {
          height: `calc(${loaderSize} / 3)`,
          width: `calc(${loaderSize} / 3)`,
          animationDuration: "2000ms",
          top: `calc(${loaderSize} / 3)`,
          left: `calc(${loaderSize} / 3)`,
          backgroundColor: "#ff1d5e",
          animation:
            "$breeding-rhombus-spinner-animation-child-big 2s infinite",
          animationDelay: "0.5s",
        },
      },
    },

    "@keyframes breeding-rhombus-spinner-animation-child-1": {
      "50%": {
        transform: "translate(-325%, -325%)",
      },
    },

    "@keyframes breeding-rhombus-spinner-animation-child-2": {
      "50%": {
        transform: "translate(0, -325%)",
      },
    },

    "@keyframes breeding-rhombus-spinner-animation-child-3": {
      "50%": {
        transform: "translate(325%, -325%)",
      },
    },

    "@keyframes breeding-rhombus-spinner-animation-child-4": {
      "50%": {
        transform: "translate(325%, 0)",
      },
    },

    "@keyframes breeding-rhombus-spinner-animation-child-5": {
      "50%": {
        transform: "translate(325%, 325%)",
      },
    },

    "@keyframes breeding-rhombus-spinner-animation-child-6": {
      "50%": {
        transform: "translate(0, 325%)",
      },
    },

    "@keyframes breeding-rhombus-spinner-animation-child-7": {
      "50%": {
        transform: "translate(-325%, 325%)",
      },
    },

    "@keyframes breeding-rhombus-spinner-animation-child-8": {
      "50%": {
        transform: "translate(-325%, 0)",
      },
    },

    "@keyframes breeding-rhombus-spinner-animation-child-big": {
      "50%": {
        transform: "scale(0.5)",
      },
    },
  };
});

export default useStyles;
