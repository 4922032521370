import { TRANSACTION_ERROR_MESSAGE } from "../constants/alert";
export const getErrorMessage = (err: any) => {
  const REGEX_GET_MESSAGE = /execution reverted:([^"]*)/gm;
  if (err.message?.includes("execution reverted:")) {
    let match = REGEX_GET_MESSAGE.exec(err?.message);
    return match ? match[1] : err?.message || TRANSACTION_ERROR_MESSAGE;
    // TRANSACTION_ERROR_MESSAGE;
  }
  if (err?.message?.includes("User denied")) {
    return err?.message;
  }
  return err?.message || TRANSACTION_ERROR_MESSAGE;
  // return TRANSACTION_ERROR_MESSAGE;
};
