import { NETWORK, NETWORK_TEXT } from '../../../constants';
import { showTotalRaisePrice } from '../../../utils/campaign';
import useStyles from './style';
import { Link } from 'react-router-dom';
import usePoolDetails from '../../../hooks/usePoolDetails';
import useTokenSoldProgress from '../../BuyToken/hooks/useTokenSoldProgress';
import BigNumber from 'bignumber.js';

const Card = (props: any): JSX.Element => {
    const styles = useStyles();
    const { pool, isUpcoming } = props;
    const { poolDetails } = usePoolDetails(pool?.id);
    const { soldProgress } = useTokenSoldProgress(poolDetails?.poolAddress, poolDetails?.amount, poolDetails?.networkAvailable, poolDetails);

    return (
        <div className={styles.card}>
            <div className={styles.cardContentTop}>
                {!isUpcoming && <div className={`${styles.poolStatusWarning}`}>Live</div>}
                <img src={isUpcoming ? '/images/dashboard/upcoming.svg' : pool?.banner} className={styles.imgProject} alt='' />
                {!isUpcoming && (
                    <>
                        <div className={styles.runTimeTitle}>
                            <div>
                                ({new BigNumber(soldProgress).toFixed(0)}
                                %)&nbsp;
                            </div>
                        </div>
                        <div className={styles.runTime}>
                            <div
                                style={{
                                    width: `${Math.round(parseFloat(soldProgress))}%`,
                                }}
                            />
                        </div>
                    </>
                )}
                <div className={styles.projectInfo}>
                    <div className={styles.projectName}>
                        <img src={pool?.token_images} width={51} height={52} alt='' />
                        <div>
                            <h6 className='limit-text-1-line'>{pool?.title}</h6>
                            <p>{`$${pool?.symbol}`}</p>
                        </div>
                    </div>
                    <div className={styles.projectData}>
                        <div>
                            <p>Network</p>
                            <h6>{NETWORK_TEXT?.[pool?.network_available ?? NETWORK.ETHEREUM]}</h6>
                        </div>
                        <div>
                            <p>Total Raise</p>
                            <h6>{showTotalRaisePrice(pool)}</h6>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.cardContentBot}>
                <div className={styles.cardBotData}>
                    <div className={styles.cardBotLeft}>
                        <div />
                        <p>{isUpcoming ? '' : `${pool?.participant_number || 0}+ Participants`}</p>
                    </div>
                    <div className={styles.cardBotRight}>
                        <Link to={`/buy-token/${pool?.id}`}>
                            <button>{isUpcoming ? 'View Now' : 'Swap Now'}</button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Card;
