import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
  return {
    main: {
      maxWidth: "1320px",
      margin: "0 auto",
      padding: "120px 20px 0",
      "& h2": {
        fontSize: "48px",
        lineHeight: "48px",
        fontWeight: 500,
        color: "#0055D6",
        textAlign: "center",
      },
      [theme.breakpoints.down("md")]: {
        "& h2": {
          fontSize: "40px",
          lineHeight: "40px",
        },
      },
      [theme.breakpoints.down("sm")]: {
        padding: "50px 20px 0",
        "& h2": {
          fontSize: "30px",
          lineHeight: "30px",
        },
      },
    },
    streamLine: {
      display: "flex",
      gap: 32,
      maxWidth: "1320px",
      padding: "44px 20px 0",
      flexWrap: "wrap",
      justifyContent: "center",
      [theme.breakpoints.down("sm")]: {
        gap: 16,
      },
      [theme.breakpoints.down("xs")]: {
        gap: 40,
        padding: "30px 0 0",
      },
    },
    streamLineItem: {
      width: "30%",
      textAlign: "center",
      "& img": {
        height: 80,
      },
      "& h4": {
        marginTop: "12px",
        fontSize: "24px",
        lineHeight: "24px",
        fontWeight: 600,
        color: "#0055D6",
      },
      "& p": {
        padding: "8px 20px 0",
        fontSize: "16px",
        lineHeight: "19px",
        color: "#000B60",
      },
      "& a": {
        display: "flex",
        gap: 10,
        width: "107px",
        height: "36px",
        margin: "12px auto 0",
        background: "#0058FF",
        border: "none",
        borderRadius: 60,
        color: "#FFFFFF",
        fontSize: "12px",
        lineHeight: "14px",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: 700,
        "& div": {
          paddingBottom: 2,
        },

        "& img": {
          width: 18,
          height: 8,
        },
        "&:hover": {
          opacity: 0.85,
          boxShadow: "0px 0px 0.5px 2px #0058FF",
        },
      },
      [theme.breakpoints.down("sm")]: {
        width: "45%",
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        "& p": {
          padding: "8px 0 0",
        },
        "& button": {
          width: "100%",
          maxWidth: "256px",
          height: "44px",
          fontSize: "12px",
          lineHeight: "14px",
        },
      },
    },
  };
});

export default useStyles;
