import { getConnectedAddress } from "./getConnectedAddress";

export const getConfigHeader = (walletAddress: string | null = null) => {
  if(!walletAddress) walletAddress = getConnectedAddress();

  return {
    headers: {
      Authorization:
        "Bearer " + localStorage.getItem(`access_token:${walletAddress}`),
    },
  };
};
