import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
  return {
    container: {
      width: "100%",
      display: "grid",
      gridTemplateColumns: "repeat(4, minmax(0, 1fr))",
      gap: "20px",
      marginTop: 32,
      [theme.breakpoints.down("sm")]: {
        flexWrap: "wrap",
        gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
      },
      [theme.breakpoints.only("xs")]: {
        marginTop: 20,
        gridTemplateColumns: "1fr",
        gap: 12,
      },
    },
    skeletonStaking: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      marginBottom: 6,
    },
    loadingContainer: {
      display: "flex",
      height: "250px",
      padding: "20px 0",
      justifyContent: "center",
      alignItems: "center",
    },
    cardContainer: {
      width: "100%",
      background: "linear-gradient(91.5deg, #ECF5FF 1.28%, #FFFFFF 135.26%)",
      border: "1px solid #A5CCFF",
      borderRadius: 20,
      display: "flex",
      padding: 10,
      flexDirection: "column",
      paddingBottom: 23,
      transition: ".2s all ease-in",
      "&:last-child": {
        marginRight: 0,
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
  };
});

export default useStyles;
