const SUCCESS_MESSAGE = 'SUCCESS_MESSAGE';
const WARNING_MESSAGE = 'WARNING_MESSAGE';
const ERROR_MESSAGE = 'ERROR_MESSAGE';
const CLEAR_MESSAGE = 'CLEAR_MESSAGE';

export const alertActions = {
  SUCCESS_MESSAGE,
  WARNING_MESSAGE,
  ERROR_MESSAGE,
  CLEAR_MESSAGE
}
