import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  return {
    header: {
      width: "100%",
      height: "119px",
      borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
      background: "#ffffff",
      color: "black",
      zIndex: 100,
    },
    container: {
      position: "fixed",
      width: "100%",
      top: 0,
      left: 0,
      zIndex: 99,
      background: "#ffffff",
    },
    navBar: {
      gridArea: "header",
      width: "1320px",
      margin: "0 auto",
      padding: "20px",
      maxWidth: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      position: "relative",
      "& a": {
        height: "100%",
        padding: "5px 0",
      },
    },
    navbarLink: {
      textAlign: "center",
      outLine: "none",
      "& img": {
        height: "40px",
      },
    },
    navbarLogo: {
      padding: "0 !important",
      marginRight: 40,
    },
    learnMenu: {
      "& .Component-root-331 .MuiPaper-root": {
        borderRadius: "12px !important",
        overflow: "hidden",
        boxShadow: "0px 0px 20px 0px #0055D60A !important",
      },
      "& .MuiMenu-paper": {
        background: "transparent !important",
        border: "none !important",
        boxShadow: "0px 0px 20px 0px #0055D60A !important",
        borderRadius: "12px !important",
      },
      "& ul": {
        backgroundColor: "#FFFFFF",
        borderRadius: "12px",
        overflow: "hidden",
        "& li": {
          padding: "7px 16px !important",
          borderBottom: "none !important",
          color: "#000000",
        },
        "& li:hover": {
          background: "#0055D6 !important",
          color: "#ffffff",
        },
      },
    },
    navbarBrand: {
      color: "white",
      fontSize: 15,
      textAlign: "center",
      fontWeight: 300,
      marginTop: 5,
    },
    navbarBrandBold: {
      color: "#D01F36",
    },
    rightBar: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    currentAccount: {
      display: "flex",
      alignItems: "center",
      "& img": {
        marginLeft: 4,
        width: 20,
      },
    },
    learnBtn: {
      marginRight: 60,
      cursor: "pointer",
      fontWeight: 600,
      fontSize: "16px",
      "& a": {
        display: "flex",
      },
      "& svg": {
        margin: "auto 0",
      },
      "& p": {
        paddingRight: "0 !important",
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    btnHeader: {
      marginRight: 60,
      fontWeight: 600,
      "&:last-child": {
        marginRight: 0,
      },
      "&>p": {
        color: "#000000",
        display: "flex",
        alignItems: "center",
        padding: "0",
        letterSpacing: "1px",
        mixBlendMode: "normal",
        backgroundColor: "transparent",
        border: "none",
        cursor: "pointer",
      },
      "& span": {
        marginLeft: 0,
        fontSize: "16px",
        lineHeight: "19px",
        fontWeight: 600,
        color: "#000B60",
      },
      "& img": {
        marginRight: 5,
      },
      "&:focus": {
        outline: "none",
      },
      [theme.breakpoints.down("xs")]: {
        height: "fit-content !important",
        marginRight: 0,
        borderBottom: "1px solid #000B60",
        "&>p": {
          paddingRight: 0,
        },
      },
    },
    skeleton: {
      background: "#515151",
      borderRadius: 4,
    },
    btnNetwork: {
      display: "flex",
      alignItems: "center",
      height: "36px",
      padding: "8px 20px",
      marginRight: 10,
      borderRadius: 4,
      color: "#FFFFFF",
      font: "normal normal 500 14px/20px ",
      background: "linear-gradient(180deg, #252525 0%, #191919 100%)",
      border: "1px solid #44454B",
      cursor: "pointer",
      "& img": {
        width: 16,
        height: 16,
        marginRight: 6,
      },
      "&:hover": {
        opacity: "0.8",
      },
      "&:focus": {
        outline: "none",
      },
    },
    btnConnected: {
      display: "flex",
      height: 38,
      gap: "2px",
      border: "none",
      background: "#1F5CD0",
      borderRadius: "60px",
      fontSize: "14px",
      lineHeight: "16px",
      color: "#FFFFFF",
    },
    btnGradient: {
      height: 38,
      padding: "0 !important",
      width: "fit-content",
      minWidth: 130,
      color: "white",
      cursor: "pointer",
      background: "#020664",
      position: "relative",
      borderRadius: "20px",
      fontSize: "14px",
      border: "none",
      "& img": {
        width: 16,
        height: 16,
        marginRight: 6,
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    btnPrimary: {
      marginRight: 10,
      height: 36,
      width: "fit-content",
      minWidth: 150,
      color: "white",
      padding: 10,
      position: "relative",
      background: "transparent",
      border: "none",
      cursor: "pointer",
      display: "inline-block",
      "& img": {
        width: 16,
        height: 16,
        marginRight: 6,
      },
      "&:before, &:after": {
        position: "absolute",
        width: 10,
        height: "0.095rem",
        content: "''",
      },
      "&:before": {
        top: 0,
        left: -3,
        transform: "rotate(-45deg)",
        transformOrigin: "top right",
        background: "linear-gradient(to right, #764CE2 0%, #7E4CD2 100%)",
      },
      "&:after": {
        bottom: 0,
        right: -3,
        transform: "rotate(-45deg)",
        transformOrigin: "bottom left",
        background: "linear-gradient(to right, #D9B4C7 0%, #BEA5C5 100%)",
      },
    },
    btnPrimaryLine1: {
      display: "flex",
      "&:before, &:after": {
        position: "absolute",
        width: "calc(100% - 8px)",
        height: "0.18rem",
        content: "''",
      },
      "&:before": {
        top: 0,
        left: 7,
        height: "0.13rem",
        background: "linear-gradient(to right, #7E4CD2 0%, #E59396 100%)",
      },
      "&:after": {
        bottom: 0,
        right: 7,
        height: "0.095rem",
        background: "linear-gradient(to right, #6154DA 0%, #BBA4C5 100%)",
      },
    },
    btnPrimaryLine2: {
      display: "flex",
      margin: "auto",
      "& span": {
        lineHeight: 1,
      },
      "&:before, &:after": {
        position: "absolute",
        width: "0.13rem",
        content: "''",
      },
      "&:before": {
        bottom: 0,
        left: 0,
        height: "calc(100% - 7px)",
        background: "linear-gradient(to bottom, #754CE3 0%, #6154DA 100%)",
      },
      "&:after": {
        top: 0,
        right: 0,
        height: "calc(100% - 7px)",
        background: "linear-gradient(to bottom, #E79799 0%, #DCB6C7 100%)",
      },
    },
    grayBorder: {
      color: "#EFEFE5",
      "&:before, &:after": {
        background: "#353539",
      },
    },
    grayBtn: {
      border: "1px solid #0055D6",
      height: 38,
      padding: "0 12px !important",
      width: "fit-content",
      color: "#0055D6",
      position: "relative",
      background: "transparent",
      display: "flex",
      cursor: "pointer",
      marginRight: 15,
      fontWeight: 600,
      borderRadius: "20px",
      "& div": {
        display: "flex",
        margin: "auto",
        width: "fit-content",
        fontSize: "14px",
        lineHeight: "14px !important",
      },
      "& span": {
        paddingBottom: "3px",
      },
      "& img": {
        width: 14,
        marginRight: 10,
      },
      [theme.breakpoints.down(990)]: {
        width: "100%",
      },
    },
    btnConnect: {
      display: "flex",
      alignItems: "center",
      height: "36px",
      color: "#fff",
      background: "transparent",
      border: 0,
      borderRadius: 4,
      padding: "8px 20px",
      transition: ".2s all ease-out",
      font: "normal normal 500 14px/20px ",
      cursor: "pointer",
      "&:hover": {
        opacity: "0.8",
      },
      "&:focus": {
        outline: "none",
      },
    },
    btnMyAccount: {
      display: "flex",
      gap: 8,
      width: 38,
      height: 38,
      marginLeft: 12,
      backgroundColor: "#FFFFFF",
      justifyContent: "center",
      alignItems: "center",
      padding: "6px 10px",
      margin: "auto",
      borderRadius: "50%",
      fontWeight: 600,
      font: "14px/20px",
      color: "#0055D6",
      border: "1px solid #0055D6",
      "& div": {
        display: "none",
        paddingBottom: "2px",
      },
      [theme.breakpoints.down(990)]: {
        width: "100%",
        borderRadius: 60,
        marginLeft: 0,
        "& div": {
          display: "block",
        },
      },
    },
    reportBug: {
      position: "fixed",
      borderRadius: 30,
      padding: "12px 12px",
      cursor: "pointer",
      gap: 6,
      justifyContent: "center",
      left: "16px",
      bottom: "46px",
      display: "flex",
      alignItems: "center",
      fontSize: "16px",
      lineHeight: "16px",
      fontWeight: 600,
      color: "#ffffff",
      backgroundColor: "#0058FF",
      textAlign: "center",
      zIndex: 20,
      "& p": {
        paddingBottom: 2,
      },
      "& img": {
        margin: "0 auto",
      },
      "&:hover": {
        outline: "1px solid #0058FF"
      },
    },
    btnConnectText: {
      display: "flex",
      width: "100%",
      height: "14px",
      paddingBottom: "3px",
      alignItems: "center",
      fontWeight: 600,
      justifyContent: "center",
      font: "normal normal 500 14px/20px ",
    },
    btnLogout: {
      background: "#3232DC",
      borderRadius: "8px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      border: "none",
      outline: "none",
      padding: "0 15px",
      height: 42,
    },
    btnBalance: {
      color: "#FFFFFF",
      margin: "auto",
      padding: "0 10px 4px",
      fontSize: "14px",
      fontWeight: 600,
    },
    btnAccount: {
      backgroundColor: "#FFFFFF",
      justifyContent: "center",
      alignItems: "center",
      padding: "6px 10px",
      margin: "auto",
      borderRadius: 60,
      marginRight: 3,
      fontWeight: 600,
      font: "14px/20px",
      color: "#000B60",
      "& div": {
        paddingBottom: "2px",
      },
    },
    btnChangeAppNetwork: {
      padding: "5px 11px 7px",
      border: "1px solid #FFFFFF",
      boxSizing: "border-box",
      borderRadius: 60,
      background: "transparent",
      fontWeight: 600,
      color: "white",
      cursor: "pointer",
      transition: ".2s all ease-in",
      font: "normal normal 700 12px/14px ",

      "&:focus": {
        outline: "none",
      },

      "&:hover": {
        backgroundColor: "white",
        color: "#D01F36",
        boxShadow: "none",
      },
    },
    breadcrumbs: {
      width: "100%",
      maxWidth: "1320px",
      margin: "0 auto",
      padding: "0 20px",
      "& a": {
        color: "#000B60",
        fontWeight: 600,

        "&:hover": {
          textDecoration: "underline",
        },
      },
      "& a, & p": {
        textTransform: "capitalize",
      },
    },
    loginErrorBanner: {
      width: "fit-content",
      maxWidth: "calc(100vw - 80px)",
      margin: "8px auto",
      borderRadius: 8,
      backgroundColor: "rgba(220, 48, 48, 0.9)",
      fontSize: 14,
      color: "white",
      padding: "8px 16px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontWeight: 600,
      minHeight: 42,
      lineHeight: "20px",
    },

    iconWarning: {
      marginRight: 8,
    },

    loginErrorBannerText: {
      display: "flex",
      gap: "8px",
      flexWrap: "wrap",
      fontSize: "14px",
      lineHeight: "16px",
      color: "white",
      fontWeight: 600,
      alignItems: "center",
    },
    loginErrorGuide: {
      color: "white",
      textDecoration: "underline",

      "&:hover": {
        color: "white",
      },
    },
    spacer: {
      flex: "1 0 0",
    },

    rightHeadMobile: {
      display: "grid",
      gap: "4px",
      "& div": {
        width: "20px",
        height: "2px",
        background: "#000B60",
        borderRadius: "20px",
      },
    },
    logoMobile: {
      display: "flex",
      justifyContent: "space-between",
      "& img:first-child": {
        height: 40,
      },
    },
    rightBarMobile: {
      display: "none",
      padding: "20px",
      "&.active": {
        position: "fixed",
        display: "block",
        width: "100%",
        height: "100vh",
        top: 0,
        left: 0,
        background: "#FFFFFF",
        zIndex: "100",
      },
    },
    navbarMobile: {
      display: "grid",
      gap: "16px",
      padding: "16px 0",
    },
    linkActived: {
      "& span": {
        color: "#0058FF",
      },
      [theme.breakpoints.down("xs")]: {
        borderBottom: "1px solid #000B60",
      },
    },
    menuAccountMobile: {
      "& a": {
        color: "#fff",
        font: "16px/24px ",
        marginTop: 16,
        display: "block",
        height: "fit-content !important" as any,
      },
    },
    menuLearnMobile: {
      borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
      paddingBottom: 10,
      "& a": {
        color: "#000B60",
        font: "16px/19px ",
        marginTop: 16,
        display: "block",
        height: "fit-content !important" as any,
        "&:hover": {
          "& span": {
            color: "#000B60",
          },
        },
        "& div": {
          display: "flex",
          gap: 8,
          alignItems: "center",
        },
      },
    },
  };
});

export default useStyles;
