import createRoutes from "./routes";
import { PersistGate } from "redux-persist/integration/react";
import { Web3ReactHooks, Web3ReactProvider } from "@web3-react/core";
import { Provider } from "react-redux";
import configureStore from "./store/configureStore";
import { ThemeProvider } from "@material-ui/core/styles";
import defaultTheme from "./themes/DefaultTheme/DefaultTheme";
import { MetaMask } from "@web3-react/metamask";
import { WalletConnect } from "@web3-react/walletconnect-v2";
import {
  metaMask,
  metaMaskHooks,
  walletConnect,
  walletConnectHooks,
} from "./constants/connectors";
import { useEffect } from "react";

const connectors: [MetaMask | WalletConnect, Web3ReactHooks][] = [
  [metaMask, metaMaskHooks],
  [walletConnect, walletConnectHooks],
];

const App = () => {
  const { store, persistor } = configureStore();
  const { connector } = store.getState();
  useEffect(() => {
    switch (connector.data) {
      case "WalletConnect":
        walletConnect.connectEagerly().catch((error) => {
          console.debug("Failed to connect eagerly to bsc wallet", error);
        });
        break;
      case "MetaMask":
        metaMask.connectEagerly().catch((error) => {
          console.debug("Failed to connect eagerly to bsc wallet", error);
        });
        break;
    }
  }, []);

  return (
    <Provider store={store}>
      <Web3ReactProvider connectors={connectors}>
        <ThemeProvider theme={defaultTheme}>
          <PersistGate loading={null} persistor={persistor}>
            {createRoutes()}
          </PersistGate>
        </ThemeProvider>
      </Web3ReactProvider>
    </Provider>
  );
};

export default App;
