import React from "react";
import { getChainOptions, WalletProvider } from "@terra-money/wallet-provider";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { getEnvironment } from "./types/getEnv";

getChainOptions().then((chainOptions) => {
  const envLocal = localStorage?.getItem("env");
  ReactDOM.render(
    <div className="default-loading-bg">
      <img
        className="loading-animation"
        src="/images/loading-v3.svg"
        alt="loading"
      />
    </div>,
    document.getElementById("root")
  );
  getEnvironment()
    ?.then((env) => env?.json())
    ?.then((data) => {
      localStorage?.setItem("env", JSON.stringify(data));
      if (!envLocal) {
        window?.location.reload();
        return;
      }
      ReactDOM.render(
        <WalletProvider {...chainOptions}>
          <React.StrictMode>
            <App />
          </React.StrictMode>
        </WalletProvider>,
        document.getElementById("root")
      );
    })
    ?.catch((e) => {
      ReactDOM.render(
        <div>Can not load environment</div>,
        document.getElementById("root")
      );
    });
});
reportWebVitals();
