import { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useWeb3React } from "@web3-react/core";

import useUserRefundSignature from "./useUserRefundSignature";
import useWalletSignature from "../../../hooks/useWalletSignature";
import PreSale_ABI from "../../../abi/PreSalePool.json";
import { getContract } from "../../../utils/contract";
import { alertSuccess, alertFailure } from "../../../store/actions/alert";
import { REFUND_TOKEN_TYPE } from "../../../constants";
import { Web3Provider } from "@ethersproject/providers";

const useRefundToken = (
  poolAddress: string | undefined,
  poolId: number | undefined
) => {
  const { provider: library, account } = useWeb3React();
  const dispatch = useDispatch();

  const [refundTokenSuccess, setRefundTokenSuccess] = useState<boolean>(false);
  const [refundTransactionHash, setRefundTransactionHash] = useState("");
  const [refundTokenLoading, setRefundTokenLoading] = useState<boolean>(false);
  const [refundError, setRefundError] = useState<string>("");

  const {
    error,
    signMessage,
    signature: authSignature,
    setSignature,
  } = useWalletSignature();
  const {
    signature,
    currency,
    deadline,
    error: refundSignError,
    setSignature: setUserRefundSignature,
    loadingRefund,
  } = useUserRefundSignature(
    account,
    poolId,
    authSignature,
    REFUND_TOKEN_TYPE.REFUND
  );

  useEffect(() => {
    poolAddress &&
      signature &&
      currency &&
      deadline &&
      !refundError &&
      !loadingRefund &&
      refundTokenWithSignature(signature, currency, deadline);
  }, [signature, poolAddress, refundError, loadingRefund]);

  useEffect(() => {
    if (error || refundSignError) {
      const errorMessage = error || refundSignError;
      setRefundError(errorMessage as string);
      setRefundTokenLoading(false);
      setSignature("");
      setUserRefundSignature("");
    }
  }, [error, refundSignError]);

  const refundTokenWithSignature = useCallback(
    async (signature: string, currency: string, deadline: string) => {
      if (poolAddress && signature && account && deadline) {
        try {
          const contract = getContract(
            poolAddress,
            PreSale_ABI,
            library as Web3Provider,
            account as string
          );
          if (contract) {
            const transaction = await contract.refundTokens(
              account,
              currency,
              deadline,
              signature
            );

            setSignature("");
            setUserRefundSignature("");
            setRefundTransactionHash(transaction.hash);

            await transaction.wait(1);

            setRefundTokenSuccess(true);
            setRefundTokenLoading(false);
            dispatch(alertSuccess("Token Request Refund Successful"));
          }
        } catch (err: any) {
          dispatch(alertFailure(err.message));
          setRefundTokenLoading(false);
          setRefundError(err.message);
          setSignature("");
          setUserRefundSignature("");
        }
      }
    },
    [poolAddress, library, account, signature]
  );

  const refundToken = useCallback(async () => {
    if (poolAddress) {
      try {
        setRefundTransactionHash("");
        setRefundError("");
        setRefundTokenLoading(true);
        setRefundTokenSuccess(false);

        await signMessage();
      } catch (err: any) {
        dispatch(alertFailure(err.message));
        setRefundTokenLoading(false);
        setRefundError(err.message);
        setSignature("");
      }
    }
  }, [poolAddress, library, account]);

  return {
    refundToken,
    transactionHashRefundToken: refundTransactionHash,
    loadingRefund: refundTokenLoading,
    setRefundTokenLoading,
    setRefundTransactionHash,
    refundTokenSuccess,
    refundError: refundError,
  };
};

export default useRefundToken;
