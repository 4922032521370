const AFFILIATE_BY_CAMPAIGN_REQUEST = 'AFFILIATE_BY_CAMPAIGN_REQUEST';
const AFFILIATE_BY_CAMPAIGN_SUCCESS = 'AFFILIATE_BY_CAMPAIGN_SUCCESS';
const AFFILIATE_BY_CAMPAIGN_FAIL = 'AFFILIATE_BY_CAMPAIGN_FAIL';

const AFFILIATE_LINK_GENERATE_REQUEST = 'AFFILIATE_LINK_GENERATE_REQUEST';
const AFFILIATE_LINK_GENERATE_SUCCESS = 'AFFILIATE_LINK_GENERATE_SUCCESS';
const AFFILIATE_LINK_GENERATE_FAIL = 'AFFILIATE_LINK_GENERATE_FAIL';

export const affiliateActions = {
  AFFILIATE_LINK_GENERATE_REQUEST,
  AFFILIATE_LINK_GENERATE_SUCCESS,
  AFFILIATE_LINK_GENERATE_FAIL,
  AFFILIATE_BY_CAMPAIGN_REQUEST,
  AFFILIATE_BY_CAMPAIGN_SUCCESS,
  AFFILIATE_BY_CAMPAIGN_FAIL
}
