import { Dialog, DialogActions, DialogTitle } from "@material-ui/core";
import useCommonStyle from "../../../styles/CommonStyle";
import useStyles from "./style";
import { ETHERSCAN_BASE_URL } from "../../../constants";
import { BASE_CHAIN_ID, BSC_CHAIN_ID } from "../../../constants/network";

const closeIcon = "/images/icons/close.svg";

const ModalTransaction = (props: any) => {
  const styles = useStyles();
  const commonStyles = useCommonStyle();

  const { transactionHashes, setTransactionHashes, open } = props;

  const handleClose = () => {
    let array = [...transactionHashes];
    array.shift();
    setTransactionHashes(array);
  };

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      className={commonStyles.modal + " " + styles.modalTransaction}
    >
      <div className="modal-content">
        <img
          src="/images/icons/checked.svg"
          alt=""
          className={styles.iconSuccess}
        />
        <DialogTitle
          id="alert-dialog-slide-title"
          className="modal-content__head"
        >
          <img src={closeIcon} className="btn-close" onClick={handleClose} />
          <span className="title">Transaction Submitted</span>
        </DialogTitle>
        <DialogActions className="modal-content__foot">
          <a
            href={`${ETHERSCAN_BASE_URL?.[BASE_CHAIN_ID]}/tx/${transactionHashes?.[0]?.tnx}`}
            target="_blank"
            className={styles.dialogButton}
            rel="noreferrer"
          >
            <div>View on Basescan</div>
            <img src="/images/icons/open_eth.svg" alt="" />
          </a>
        </DialogActions>
        {transactionHashes?.[0]?.isApprove && (
          <p className={styles.notice}>
            * Please be patient and no need to approve again, you can check the
            transaction status on Etherscan
          </p>
        )}
      </div>
    </Dialog>
  );
};

export default ModalTransaction;
