import React, { useEffect, useState } from "react";
import { Tooltip } from "@material-ui/core";
import useStyles from "./styles";
import { Link } from "react-router-dom";
import { PoolStatus } from "../../../utils/getPoolStatus";
import {
  getAccessPoolText,
  getProgressWithPools,
  getTokenSold,
  showTotalRaisePrice,
} from "../../../utils/campaign";
import { numberWithCommas } from "../../../utils/formatNumber";
import { ACCEPT_CURRENCY } from "../../../constants";
import { getIconCurrencyUsdt } from "../../../utils/usdt";

const CardCompletedSales = (props: any): JSX.Element => {
  const styles = useStyles();
  const { pool, loading, autoFetch } = props;

  const [progress, setProgress] = useState("0");
  const [tokenSold, setTokenSold] = useState("0");
  const [totalSoldCoin, setTotalSoldCoin] = useState("0");
  useEffect(() => {
    const getTokenProgressInfoByPool = async () => {
      if (autoFetch) {
        pool.tokenSold = await getTokenSold(pool);
      }
      let {
        progress: progressPercent,
        tokenSold: totalTokenSold,
        totalSoldCoin: totalToken,
      } = getProgressWithPools(pool);

      setProgress(progressPercent);
      setTokenSold(totalTokenSold);
      setTotalSoldCoin(totalToken);
    };

    getTokenProgressInfoByPool();
    if (autoFetch) {
      const intervalProgress = setInterval(() => {
        getTokenProgressInfoByPool();
      }, 10000);

      return () => {
        intervalProgress && clearInterval(intervalProgress);
      };
    }
  }, [autoFetch, pool]);

  const { currencyName } = getIconCurrencyUsdt({
    purchasableCurrency: pool?.accept_currency,
    networkAvailable: pool?.network_available,
  });

  const renderStatusColumn = () => {
    switch (pool?.status) {
      case PoolStatus.TBA:
        return <span className={`${PoolStatus.TBA}`}>{PoolStatus.TBA}</span>;
      case PoolStatus.Upcoming:
        return (
          <span className={`${PoolStatus.Upcoming}`}>
            {PoolStatus.Upcoming}
          </span>
        );
      case PoolStatus.Progress:
        return (
          <span className={`${PoolStatus.Progress}`}>
            {PoolStatus.Progress}
          </span>
        );
      case PoolStatus.Filled:
        return (
          <span className={`${PoolStatus.Filled}`}>{PoolStatus.Filled}</span>
        );
      case PoolStatus.Closed:
        return (
          <span className={`${PoolStatus.Closed}`}>{PoolStatus.Closed}</span>
        );
      case PoolStatus.Claimable:
        return (
          <span className={`${PoolStatus.Claimable}`}>
            {PoolStatus.Claimable}
          </span>
        );
      default:
        return <span className={`none`}> {pool?.status}</span>;
    }
  };

  const getTextPoolStatus = (status: PoolStatus) => {
    let className = "",
      text = "";
    switch (status) {
      case PoolStatus.Closed:
        className = "ended";
        text = PoolStatus.Closed;
        break;
      case PoolStatus.TBA:
        className = "tba";
        text = PoolStatus.TBA;
        break;
      case PoolStatus.Filled:
        className = "time";
        text = PoolStatus.Filled;
        break;
      case PoolStatus.Progress:
        className = "in-progress";
        text = PoolStatus.Progress;
        break;
      case PoolStatus.Upcoming:
        className = "joining";
        text = "Registering";
        break;
      case PoolStatus.Claimable:
        className = "claimable";
        text = PoolStatus.Claimable;
        break;
      default:
        break;
    }
    return (
      <div className={`${styles.poolStatus} ${className}`}>
        <span>{text}</span>
      </div>
    );
  };

  return (
    <Link to={`/buy-token/${pool.id}`}>
      <div className={styles.borderCardSale}>
        <div className={styles.cardCompletedSales}>
          <div className={styles.leftCard}>
            <img src={pool.token_images} className={styles.icon} alt="" />
            <div className={styles.introCard}>
              <div className={styles.listStatus}>
                <span className={`${styles.poolStatusWarning}`}>
                  {getAccessPoolText(pool)}
                </span>
                <span className={`${loading && "placeholder-ske"}`}>
                  {getTextPoolStatus(pool?.status)}
                </span>
              </div>
              <Tooltip
                classes={{ tooltip: styles.tooltip }}
                title={pool?.title || ""}
                arrow
                placement="top"
              >
                <span
                  className={`${styles.title} ${loading && "placeholder-ske"}`}
                >
                  {pool?.title || ""}
                </span>
              </Tooltip>
            </div>
          </div>

          <div className={styles.midCard}>
            <ul className={styles.listInfo}>
              <li className={styles.itemInfo}>
                <span className={styles.nameInfo}>Network</span>

                <div className={`${styles.chain} ${loading && "placeholder-ske"}`}>
                  <img
                    src={`/images/chain/${pool?.network_available?.toLowerCase()}.png`}
                    width={14}
                    height={14}
                    alt="logo"
                  />
                  <p>{pool?.network_available}</p>
                </div>
              </li>
              <li className={`${styles.itemInfo} ${styles.alignLeftMobile}`}>
                <span className={styles.nameInfo}>IDO Price</span>
                <span className={`${loading && "placeholder-ske"}`}>
                  {pool?.accept_currency === ACCEPT_CURRENCY.ETH && (
                    <>{`${numberWithCommas(pool?.price_usdt, 4)} USD`}</>
                  )}
                  {pool?.accept_currency !== ACCEPT_CURRENCY.ETH && (
                    <>
                      {numberWithCommas(pool?.token_conversion_rate, 4)} {currencyName}
                    </>
                  )}
                </span>
              </li>
              <li className={styles.itemInfo}>
                <span className={styles.nameInfo}>Current Price</span>
                <span
                  className={`${styles.valueInfo} ${loading && "placeholder-ske"
                    }`}
                >
                  {pool?.current_price
                    ? pool?.current_price.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })
                    : "Updating..."}
                </span>
              </li>
              <li className={`${styles.itemInfo} ${styles.alignLeftMobile}`}>
                <span className={styles.nameInfo}>ATH IDO ROI USD</span>
                <span
                  className={`${styles.valueInfo} ${loading && "placeholder-ske"
                    }`}
                >
                  {pool?.ath_roi
                    ? `${pool?.ath_roi.toFixed(2)}x`
                    : "Updating..."}
                </span>
              </li>
              <li className={styles.itemInfo}>
                <span className={styles.nameInfo}>Type</span>
                <span
                  className={`${styles.valueInfo} ${loading && "placeholder-ske"
                    }`}
                >
                  {pool?.relationship_type ? pool?.relationship_type : "None"}
                </span>
              </li>
              <li className={`${styles.itemInfo} ${styles.alignLeftMobile}`}>
                <span className={styles.nameInfo}>Market Maker</span>
                <span className={styles.valueInfo}>
                  {pool?.market_maker ? (
                    <span className={`${loading && "placeholder-ske"}`}>
                      <img
                        width={16}
                        height={16}
                        style={{ objectFit: "contain" }}
                        src={pool?.market_maker_icon}
                      />
                      {pool?.market_maker}
                    </span>
                  ) : (
                    "None"
                  )}
                </span>
              </li>
              <li className={`${styles.itemInfo}`}>
                <span className={styles.nameInfo}>Status</span>
                <span>
                  {renderStatusColumn()}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default CardCompletedSales;
