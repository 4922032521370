import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => {
  return {
    main: {
      background: "#ffffff",
      paddingTop: 80,
    },
    backedBy: {
      position: "relative",
      display: "flex",
      maxWidth: "1280px",
      height: "68px",
      margin: "0px auto 120px",
      paddingBottom: "10px",
      alignItems: "end",
      "& div": {
        width: "100%",
        height: 1,
        background: "#7FB6FF",
      },
      "& p": {
        position: "absolute",
        bottom: "0",
        left: "50%",
        padding: "0 40px",
        transform: "translate(-50%, 0)",
        fontSize: "68px",
        lineHeight: "68px",
        color: "#0055D6",
        whiteSpace: "nowrap",
        fontWeight: 600,
        background: "#FFFFFF",
      },
      [theme.breakpoints.down("md")]: {
        height: "50px",
        padding: "0 20px 10px",
        "& p": {
          fontSize: "50px",
          lineHeight: "50px",
        },
      },
      [theme.breakpoints.down("sm")]: {
        height: "40px",
        margin: "0px auto 50px",
        padding: "0 20px 5px",
        "& p": {
          padding: "0 10px",
          fontSize: "40px",
          lineHeight: "40px",
        },
      },
    },
    slide: {
      position: "relative",
      maxWidth: 1376,
      height: "100%",
      margin: "0 auto",
      overflow: "hidden",
      "& .home-slide": {
        gap: 40,
      },
      "& div": {
        display: "flex",
        width: "fit-content",
        height: "100%",
        flexWrap: "nowrap",
        alignItems: "center",
        "& img": {
          width: "111px",
          height: "28px",
          objectFit: "contain",
        },
      },
    },
  };
});

export default useStyles;
