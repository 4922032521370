import { withWidth } from "@material-ui/core";
import { useState } from "react";
import { Link } from "react-router-dom";
import useStyles from "./style";

const guideFAQs = [
  {
    name: "Guide",
    childs: [
      {
        title: "Before Joining IDOs",
        href: "https://docs.rwa.inc/launchpad/why-choose-rwa-inc.",
      },
      {
        title: "How to claim tokens?",
        href: "https://docs.rwa.inc/launchpad/investor-protection-refund-policy",
      },
      {
        title: "How to swap tokens?",
        href: "https://docs.rwa.inc/launchpad/how-is-usdrwa-token-involved",
      },
    ],
  },
  {
    name: "FAQ",
    childs: [
      {
        title: "Get started with RWA",
        href: "https://docs.rwa.inc/launchpad/get-started-with-rwa-inc.",
      },
      {
        title: "Allocation Result & Buying",
        href: "https://docs.rwa.inc/launchpad/get-started-with-rwa-inc.#participate-in-crowdfunding-and-token-sales",
      },
      {
        title: "KYC",
        href: "https://docs.rwa.inc/launchpad-and-staking/get-started-with-rwa-inc.#complete-kyc",
      },
      {
        title: "Claim",
        href: "https://docs.rwa.inc/refund-policy/user-refund-policy#refund-policy-frequently-asked-questions",
      },
      {
        title: "Staking",
        href: "https://docs.rwa.inc/staking-overview/rwa-staking-policies",
      },
      {
        title: "Others",
        href: "https://docs.rwa.inc/faq/user-faq",
      },
      {
        title: "Whitelist",
        href: "https://docs.rwa.inc/launchpad/how-to-participate",
      },
    ],
  },
];

const ButtonMailto = (props: any) => {
  return (
    <Link
      to="#"
      onClick={(e) => {
        window.location = props.mailto;
        e.preventDefault();
      }}
    >
      {props.label}
    </Link>
  );
};

const NeedHelp = (props: any) => {
  const styles = useStyles();
  const [listQuestions] = useState(guideFAQs);

  return (
    <div className={styles.pageNeedHelp}>
      <h1 className={styles.title}>Need some help?</h1>
      <div className={styles.sectionBody}>
        <div>
          <div className={styles.subTitle}>Support Email</div>
          <div className={styles.des}>
            If you have any questions, please contact us at any moment via{" "}
            <ButtonMailto
              label="support@rwa.inc"
              mailto="mailto:support@rwa.inc"
            />
          </div>
        </div>
      </div>
      <div className={`${styles.sectionBody} ${styles.sectionBodyQuestions}`}>
        <div style={{ width: "100%" }}>
          {/* <div className={styles.subTitle}>
            Guide & FAQs
          </div>
          <div className={styles.groupSearch}>
            <input placeholder="Ask a question..."/>
            <Button>
              <img src="/images/icons/search_white.svg" alt="" />
            </Button>
          </div> */}

          {listQuestions?.map((item, index) => {
            return (
              <div className={styles.boxQuestions} key={index}>
                <div className={styles.subTitle}>{item.name}</div>
                <ul className={styles.listQuestions}>
                  {item.childs?.map((child, i) => {
                    return (
                      <li key={i} className={styles.itemQuestions}>
                        <a href={child.href} target="_blank" rel="noreferrer">
                          <span data-role="dot">&bull;</span>
                          <span data-role="title">{child.title}</span>
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default withWidth()(NeedHelp);
