import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
  return {
    container: {
      maxWidth: 1320,
      minHeight: "calc(100vh - 400px)",
      margin: "0 auto",
      padding: "20px",
      "& .MuiPaper-elevation1": {
        boxShadow: "none",
      },
      [theme.breakpoints.down("sm")]: {
        padding: "20px",
      },
      "& h2": {
        paddingBottom: "32px",
        fontSize: "40px",
        lineHeight: "40px",
        fontWeight: 600,
        color: "#000B60",
        textAlign: "center",
      },
      [theme.breakpoints.down("md")]: {
        "& h2": {
          fontSize: "30px",
          lineHeight: "30px",
        },
      },
      [theme.breakpoints.down("sm")]: {
        "& h2": {
          fontSize: "30px",
          lineHeight: "30px",
          textAlign: "center",
        },
      },

      "& .wordBreak": {
        // minWidth: 180,
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
      },
    },
    table: {
      border: "1px solid rgba(224, 224, 224, 1)",
      boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
    },
    global: {
      marginBottom: 24,
      "& p": {
        padding: "3px 12px 5px",
        fontSize: "20px",
        marginBottom: 4,
        background: "#0055D6",
        color: "white",
        borderRadius: 8,
        "& span": {
          fontWeight: 600,
        },
        [theme.breakpoints.down("sm")]: {
          fontSize: "18px",
        },
      },
    },
    chartHead: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      [theme.breakpoints.down("sm")]: {
        display: "block"
      },
    },
    chart: {
      display: "flex",
      gap: 32,
      alignItems: "center",
      justifyContent: "space-between",
      [theme.breakpoints.down("sm")]: {
        display: "block",
        textAlign: "center",
      },
    },
    chartContent: {
      display: "flex",
      gap: 8,
      alignItems: "center",
      "& div": {
        width: 40,
        height: 10,
      },
      "& p": {
        paddingBottom: "3px",
        fontSize: "18px",
      },
    },
  };
});

export default useStyles;
